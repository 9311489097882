import React, { createContext, useState, useEffect, ReactNode } from "react";

interface NotificationContextProps {
  subscription: PushSubscription | null;
  setSubscription: React.Dispatch<React.SetStateAction<PushSubscription | null>>;
}

export const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

interface NotificationContextProviderProps {
  children: ReactNode;
}

export const NotificationContextProvider: React.FC<NotificationContextProviderProps> = ({ children }) => {
  const [subscription, setSubscription] = useState<PushSubscription | null>(null);

  useEffect(() => {
    const subscribeUser = async () => {
      try {
        const registration = await navigator.serviceWorker.register("/worker.js");
        const permission = await Notification.requestPermission();
        if (permission !== "granted") {
          console.log("Permission not granted for notifications.");
          return;
        }

        let subscription = await registration.pushManager.getSubscription();
        if (!subscription) {
          subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: process.env.REACT_APP_PUBLIC_VAPID_KEY
              ? urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY)
              : new Uint8Array(),
          });
        }

        setSubscription(subscription);
      } catch (error) {
        console.error("Error during subscription:", error);
      }
    };

    subscribeUser();
  }, []);

  return (
    <NotificationContext.Provider value={{ subscription, setSubscription }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Helper function to convert VAPID key
const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
