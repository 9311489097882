import React, { useContext, useState, useEffect } from "react";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import WorkOrderDetails from "./WorkOrderDetails";
import { ArrowBack, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { AuthContext } from "../../context/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TaskNotes from "../../updatedcomponents/modals/TaskNotes";
import axios from "axios";
import { useSwipeable } from "react-swipeable";
import { Tooltip } from "react-tooltip";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import { WorkOrder } from "../../types/WorkOrder";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import { useFacility } from "../../hooks/useFacility";
import { useAuth } from "../../hooks/useAuth";

const WorkOrderInstance = () => {
  const { id } = useParams();
  const { currentUser } = useAuth();
  const { selectedFacility } = useFacility();
  const {
    selectedWorkOrder,
    setSelectedWorkOrder,
    fetchAdjacentWorkOrder,
    users,
    images,
  } = useWorkOrder();

  const handleAdjacentWorkOrder = (direction: string) => {
    fetchAdjacentWorkOrder(direction, selectedFacility);
  };

  useEffect(() => {
    const fetchWorkOrder = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`/api/work-order/instance/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSelectedWorkOrder(response.data);
      } catch (err) {
        console.error("Error fetching work order:", err);
      }
    };

    if (id) {
      fetchWorkOrder();
    }
  }, [id, setSelectedWorkOrder]);

  const navigateBack = () => {
    window.history.back();
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      currentUser.access !== "worker" && handleAdjacentWorkOrder("next");
    },
    onSwipedRight: () => {
      currentUser.access !== "worker" && handleAdjacentWorkOrder("prev");
    },
  });

  const [selectedNotes, setSelectedNotes] = useState(null);

  const onCloseNotes = () => {
    setSelectedNotes(null);
  };

  return (
    <>
      <PageHeader
        title={
          <button
            type="button"
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
            onClick={navigateBack}
          >
            <ArrowBack style={{ fontSize: "1rem" }} /> Back to Work Orders
          </button>
        }
        subtitle={""}
        buttons={
          currentUser.access !== "worker" && (
            <div className="gap-2  hidden sm:flex">
              <button
                className="text-secondary-300 hover:text-secondary-100 hover:bg-secondary-1100 rounded-full px-0.5 py-0.5"
                onClick={() => handleAdjacentWorkOrder("prev")}
                data-tooltip-id="adjacent_work_order"
                data-tooltip-content="Previous Work Order"
                data-tooltip-place="bottom"
              >
                <ChevronLeft />
              </button>
              <button
                className="text-secondary-300 hover:text-secondary-100 hover:bg-secondary-1100 rounded-full px-0.5 py-0.5"
                onClick={() => handleAdjacentWorkOrder("next")}
                data-tooltip-id="adjacent_work_order"
                data-tooltip-content="Next Work Order"
                data-tooltip-place="bottom"
              >
                <ChevronRight />
              </button>
              <Tooltip id="adjacent_work_order" place="bottom" />
            </div>
          )
        }
      />
      <div {...swipeHandlers}>
        {selectedWorkOrder && images && users && (
          <WorkOrderDetails
            key={selectedWorkOrder._id}
            workOrder={selectedWorkOrder}
            setWorkOrder={setSelectedWorkOrder}
            permissions={currentUser.access}
            setSelectedNotes={setSelectedNotes}
            images={images}
            users={users}
          />
        )}
      </div>

      {selectedNotes && (
        <TaskNotes
          selectedTask={selectedNotes}
          showNotes={selectedNotes !== null}
          setShowNotes={onCloseNotes}
        />
      )}
     
    </>
  );
};

export default WorkOrderInstance;
