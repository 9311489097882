import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import classNames from "classnames";
import React, { Fragment } from "react";

interface Column<T> {
  key: string;
  label: string;
  render: (item: T) => React.ReactNode;
  className?: string;
  sortable?: boolean;
  responsiveClass?: string;
}

interface TableProps<T> {
  data: T[];
  columns: Column<T>[];
  noDataMessage?: string;
  onRowClick?: (item: T) => void;
  handleSort?: (sortKey: string) => void;
  sortColumn?: string;
  sortDirection?: string;
  onSort?: (sortKey: string) => void;
  isClickable?: boolean;
  height?: string;
}

const Table = <T,>({
  data,
  columns,
  noDataMessage = "No data available",
  onRowClick,
  handleSort,
  sortColumn,
  sortDirection,
  isClickable = true,
  height
}: TableProps<T>) => {

  const tableContainerClasses = classNames(
    `flex thin-scrollbar overflow-y-auto p-px border-b border-secondary-1100 ${height}`
  );

  return (
    <div
      className={tableContainerClasses}
    >
      <table className="min-w-full ">
        <thead>
          <tr className="sticky top-0 z-20  outline outline-1 outline-secondary-1000 rounded-t-sm shadow-sm cursor-pointer bg-secondary-1200">
            {columns.map((column) => (
              <th
                key={column.key}
                onClick={() =>
                  column.sortable && handleSort && handleSort(column.key)
                }
                scope="col"
                className={`py-3.5 inline-flex whitespace-nowrap group px-4 text-left text-sm font-medium text-secondary-100 cursor-pointer ${
                  column.className || ""
                }`}
              >
                <span >{column.label}</span>
                {column.sortable && (
                  <>
                    {sortColumn === column.key ? (
                      sortDirection === "1" ? (
                        <ArrowDropDown className="text-secondary-500" />
                      ) : (
                        <ArrowDropUp className="text-secondary-500" />
                      )
                    ) : (
                      <ArrowDropDown className="text-transparent group-hover:text-secondary-400" />
                    )}
                  </>
                )}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.length === 0 ? (
            <>
              <tr className="h-2"></tr>
              <tr className="mt-2">
                <td
                  colSpan={columns.length}
                  className="h-96 bg-secondary-1200 text-center outline outline-1 outline-secondary-1000 rounded-sm cursor-pointer hover:bg-secondary-1200"
                >
                  {noDataMessage}
                </td>
              </tr>
            </>
          ) : (
            data.map((row, rowIndex) => (
              <Fragment key={rowIndex}>
                <tr className="h-2" key={`${row}-gap`} />
                <tr
                  key={rowIndex}
                  className={`outline outline-1 outline-secondary-1000 rounded-sm cursor-pointer ${
                    isClickable ? "hover:bg-secondary-1200" : ""
                  }`}
                  onClick={() => isClickable && onRowClick && onRowClick(row)}
                >
                  {columns.map((column, colIdx) => (
                    <td
                      key={colIdx}
                      className={`py-1 px-4 text-sm text-secondary-100 ${
                        column.className ?? ""
                      }`}
                    >
                      {column.render(row)}
                    </td>
                  ))}
                </tr>
              </Fragment>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
