import React from "react";
import * as MuiIcons from "@mui/icons-material";
import classNames from "classnames";

export type ButtonTypes =
  | "primary"
  | "secondary"
  | "danger"
  | "minimal"
  | "bold";

type MuiIconNames = keyof typeof MuiIcons;

type ButtonProps = {
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: (e: any) => void;
  styleString?: ButtonTypes;
  children: React.ReactNode;
  overrideStyles?: string;
  icon?: MuiIconNames;
  reverse?: boolean; // New prop to control the order
};

const Button: React.FC<ButtonProps> = ({
  type = "button",
  disabled,
  onClick,
  styleString = "primary",
  children,
  overrideStyles,
  icon,
  reverse = false,
}) => {
  
  const buttonStyle = classNames(
    "justify-center items-center gap-3 inline-flex  h-10 px-6 py-2 rounded-sm",
    {
      "bg-secondary-100 text-primary": styleString === "primary",
      "bg-secondary-1100 text-secondary-100": styleString === "secondary",
      "bg-accent-500 text-primary": styleString === "bold",
      "bg-reds-1100 text-reds-300": styleString === "danger",
      "bg-blue-500 text-white": ![
        "primary",
        "bold",
        "secondary",
        "danger",
        "minimal",
      ].includes(styleString),
      "cursor-not-allowed bg-secondary-1200 hover:text-secondary-300 text-secondary-200 hover:cursor-disabled":
        disabled,
      "hover:cursor-pointer": !disabled,
    },
    overrideStyles
  );


  const IconComponent = icon ? MuiIcons[icon as MuiIconNames] : null;

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={buttonStyle}
    >
      <div
        className={classNames(
          "flex gap-2 items-center",
          reverse ? "flex-row-reverse" : "flex-row"
        )}
      >
        {IconComponent && <IconComponent style={{ fontSize: "1rem", color:"currentcolor" }} />}
        {children}
      </div>
    </button>
  );
};

export default Button;