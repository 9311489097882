import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowBackIos, ArrowForwardIos, Close } from "@mui/icons-material";

interface LightBoxProps {
  images: string[];
  selectedImage: number;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const Lightbox: React.FC<LightBoxProps> = ({
  images,
  selectedImage,
  show,
  setShow,
}) => {
  const [currentImage, setCurrentImage] = useState<number | null>(
    selectedImage
  );

  const goPrevious = () => {
    if (currentImage !== null) {
      setCurrentImage((currentImage - 1 + images.length) % images.length);
    }
  };

  const goNext = () => {
    if (currentImage !== null) {
      setCurrentImage((currentImage + 1) % images.length);
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={setShow}
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-75" />
          <div className="relative flex flex-col items-center w-full h-full my-8 mx-4 text-left align-middle transition-all transform bg-white shadow-xl rounded-md max-w-4xl">
            <button
              onClick={() => {
                setShow(false);
                setCurrentImage(null);
              }}
              className="absolute top-0 right-0  z-20 text-gray-600 p-2 rounded-full"
            >
              <Close />
            </button>
            <div className="relative w-full mt-4 min-h-36 pt-8">
              {currentImage !== null && (
                <img
                  src={images[currentImage]}
                  alt="work order image"
                  className="w-full max-w-2xl h-auto m-auto rounded-sm"
                />
              )}
              <button
                onClick={goPrevious}
                className="absolute top-1/2 left-0 transform -translate-y-1/2 text-gray-600 p-2 rounded-full"
              >
                <ArrowBackIos />
              </button>
              <button
                onClick={goNext}
                className="absolute top-1/2 right-0 transform -translate-y-1/2 text-gray-600 p-2 rounded-full"
              >
                <ArrowForwardIos />
              </button>
            </div>
            <div className="flex overflow-x-auto mt-4 mb-2">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt="work order image"
                  className={`sm:w-24 sm:h-24 h-12 w-12 object-contain cursor-pointer ${
                    index === currentImage
                      ? "border-2 border-accent-100 rounded-sm"
                      : ""
                  }`}
                  onClick={() => setCurrentImage(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Lightbox;
