import { FC } from "react";
import {
  BusinessOutlined,
  EmailOutlined,
  Person2Outlined,
  PhoneAndroidOutlined,
} from "@mui/icons-material";
import {
  formatPhoneNumber,
  capitalizeString,
} from "../../utils/FormatFunctions";
import { useVendor } from "../../hooks/useVendor";
import { Vendor } from "../../types/Vendor";
import Table from "../general/Table";

interface VendorsTableProps {
  vendors: Vendor[];
}

const VendorsTable: FC<VendorsTableProps> = ({ vendors }) => {
  const { handleViewVendor } = useVendor();

  const columns = [
    {
      label: "Company",
      key: "name",
      sortable: false,
      render: (vendor: Vendor) => (
        <>
          <div className="whitespace-nowrap text-sm">
            <div className="flex ">
              <div className="flex-shrink-0 text-accent-300 bg-accent-1000 h-8 rounded-full w-8 flex items-center justify-center">
                <BusinessOutlined className="" style={{ fontSize: "1.2rem" }} />
              </div>
              <div className="ml-4">
                <div className="font-medium text-secondary">{vendor.name}</div>
                <div className="mt-1 text-secondary-400 gap-2 flex items-center">
                  <EmailOutlined style={{ fontSize: "1rem" }} />
                  {vendor.email}
                </div>
                <div className="mt-1 text-secondary-400 gap-2 flex items-center">
                  <PhoneAndroidOutlined style={{ fontSize: "1rem" }} />
                  {formatPhoneNumber(vendor.phone)}
                </div>
                <div className="sm:hidden mt-1 text-secondary-400 gap-2 flex items-center">
                  <Person2Outlined style={{ fontSize: "1rem" }} />
                  {capitalizeString(vendor.contact)}
                </div>
              </div>
            </div>
          </div>
        </>
      ),
      className: "w-full max-w-0 py-4 pr-3 sm:w-auto sm:max-w-none ",
    },
    {
      label: "Type",
      key: "type",
      sortable: false,
      render: (vendor: Vendor) => (
        <>
          <span className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400">
            {capitalizeString(vendor.type)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Address",
      key: "address",
      sortable: false,
      render: (vendor: Vendor) => (
        <>
          <span className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400">
            {capitalizeString(vendor.address)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Contact Person",
      key: "contact",
      sortable: false,
      render: (vendor: Vendor) => (
        <>
          <span className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400">
            {vendor.contact}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
  ];
  return (
    <Table
      columns={columns}
      data={vendors}
      onRowClick={handleViewVendor}
      height="max-h-[calc(100vh-280px)] sm:max-h-[calc(100vh-250px)]"
    />
  );
};

export default VendorsTable;
