import React, { Fragment, useContext, useState } from "react";
import axios from "axios";
import { DeleteOutline, SearchOffOutlined } from "@mui/icons-material";
import GenericModal from "../modals/GenericModal";
import CreateRecurring from "../forms/CreateRecurring";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import { UserType } from "../../types/User";
import { RecurringTask } from "../../types/RecurringTask";
import { TaskInstance } from "../../types/TaskInstance";

type PageType = "life-safety" | "prev-maint" | "administrative";
interface RecurringTasksProps {
  permissions: UserType;
  recurringTasks: RecurringTask[];
  type: PageType;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setResponse: React.Dispatch<React.SetStateAction<any>>;
}

const RecurringTasks: React.FC<RecurringTasksProps> = ({
  permissions,
  recurringTasks,
  type,
  refresh,
  setRefresh,
  setResponse,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showExistingTask, setshowExistingTask] = useState(false);
  const [selectedLog, setSelectedLog] = useState<RecurringTask|null>(null);

  const handleDeleteClick = (task: RecurringTask) => {
    setSelectedLog(task);
    setShowPopup(true);
  };

  const handlePopOut = (task: RecurringTask) => {
    setSelectedLog(task);
    setshowExistingTask(true);
  };

  const handleDelete = async (task: RecurringTask) => {
    try {
      await axios.delete(`/api/log/delete/${task._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setRefresh(!refresh);
      setShowPopup(false);
    } catch (error) {
      console.log("Error deleting log:", error);
    }
  };

  const returnTitle = (type: PageType) => {
    switch (type) {
      case "life-safety":
        return "Life Safety";
      case "prev-maint":
        return "Preventative Maintenance";
      case "administrative":
        return "Administrative";
      default:
        return "Recurring Tasks";
    }
  };

  return (
    <div className="px-4 overflow-auto border border-gray-200 rounded-sm mx-2 sm:mx-4 md:mx-8 ">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <div className="flex gap-2 items-center my-2">
            <h1 className="text-base  font-semibold leading-6 text-gray-900 pr-2 border-r-2 border-gray-200 ">
              {returnTitle(type)}
            </h1>
            <p className="text-sm text-gray-700">
              List of all recurring tasks.
            </p>
          </div>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
      </div>
      <div className="-mx-4 my-4 sm:-mx-0">
        {recurringTasks && recurringTasks.length > 0 ? (
          <table className="min-w-full ">
            <thead>
              <tr className="bg-gray-50 border border-gray-200 border-spacing-2">
                <th
                  scope="col"
                  className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Frequency
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Start Date
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Source
                </th>
                <th
                  scope="col"
                  className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Details
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody key={"body"} className="bg-white">
              {recurringTasks.map((log) => (
                <Fragment key={log._id}>
                  <tr key={`${log._id}-gap`} className="h-2"></tr>
                  <tr className="hover:bg-gray-50 cursor-pointer border border-gray-200 rounded-sm">
                    <td className="w-full max-w-0 py-4 pl-2 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none ">
                      {capitalizeString(log.title)}
                      <dl className="font-normal lg:hidden">
                        <dt className="sr-only sm:hidden">Frequency</dt>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          {capitalizeString(log.recurrence.frequency)}
                        </dd>
                        <dt className="sr-only sm:hidden">Type</dt>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          {capitalizeString(log.category)}
                        </dd>
                        <dt className="sr-only sm:hidden">Location</dt>
                        {log.startDate && (
                          <dd className="mt-1 truncate text-gray-500 sm:hidden">
                            {formatDate(log.startDate, "YYYY-MM-DD", "utc")}
                          </dd>
                        )}
                        <dt className="sr-only sm:hidden">Urgency</dt>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          {capitalizeString(log.details)}
                        </dd>
                      </dl>
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {capitalizeString(log.recurrence.frequency)}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500  sm:table-cell">
                      {capitalizeString(log.category)}
                    </td>
                    {log.startDate && (
                      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                        {formatDate(log.startDate, "YYYY-MM-DD", "utc")}
                      </td>
                    )}
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {capitalizeString(log.source)}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500  table-cell">
                      <button
                        onClick={() => handlePopOut(log)}
                        className="text-secondary hover:text-accent-200 underline"
                      >
                        View
                      </button>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500  table-cell">
                      <button
                        onClick={() => handleDeleteClick(log)}
                        className="text-red-500 hover:text-red-400 "
                      >
                        <DeleteOutline style={{ fontSize: "1rem" }} />
                      </button>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="flex flex-col w-full justify-center items-center bg-gray-100 text-gray-500 py-8">
            <SearchOffOutlined style={{ fontSize: "2rem" }} />
            <span className="text-sm">No items present</span>
          </div>
        )}
        {showPopup && (
          <GenericModal
            title={"Delete this recurring task."}
            item={selectedLog}
            handleFunction={handleDelete}
            showModal={showPopup}
            setShowModal={setShowPopup}
          />
        )}

        {selectedLog && (
          <CreateRecurring
            showPopup={showExistingTask}
            setShowPopup={setshowExistingTask}
            refresh={refresh}
            setRefresh={setRefresh}
            existingTask={selectedLog}
            setExistingTask={setSelectedLog}
            setResponse={setResponse}
          />
        )}
      </div>
    </div>
  );
};

export default RecurringTasks;
