import moment from "moment";

export const formatDate = (
  date: string | Date | undefined,
  format = "YYYY-MM-DD",
  timezone = `${Intl.DateTimeFormat().resolvedOptions().timeZone}`
): string => {
  if (!date) return "N/A";
  return moment(date).tz(timezone).format(format);
};

export const formatPhoneNumber = (
  phoneNumber: string | undefined | null
): string => {
  if (!phoneNumber) {
    return "N/A";
  }
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

  if (numericPhoneNumber.length !== 10) {
    return "Invalid phone number";
  }

  const formattedPhoneNumber = `(${numericPhoneNumber.slice(
    0,
    3
  )}) ${numericPhoneNumber.slice(3, 6)}-${numericPhoneNumber.slice(6, 10)}`;

  return formattedPhoneNumber;
};

export const capitalizeString = (str: string | undefined | null): string => {
  if (typeof str !== "string" || !str) {
    return ""; // or you could return 'N/A', null, undefined, or any default value
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const extractFilenameFromUrl = (input: string) => {
  if (input.includes("http://") || input.includes("https://")) {
    const parts = input.split("/");
    return parts[parts.length - 1].split("-").slice(0, -1).join("-");
  } else {
    return input;
  }
};

export const retrieveFileName = (url: string) => {
  if (!url) return "";
  const splitUrl = url.split("/");
  return splitUrl[splitUrl.length - 1];
};

export const decodedString = (str: string) => {
  return decodeURIComponent(str);
};

export function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}

export const calculateDays = (date: Date | string) => {
  const startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0);
  const today = new Date();

  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  let workDays = 0;

  const isWeekend = (date: Date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  while (startDate <= today) {
    if (!isWeekend(startDate)) {
      workDays++;
    }
    startDate.setTime(startDate.getTime() + millisecondsPerDay);
  }

  return workDays;
};
