import { useState, useContext } from "react";
import VendorsTable from "../../updatedcomponents/tables/VendorsList";
import NewVendorForm from "../../updatedcomponents/forms/NewVendorForm";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";
import { useVendor } from "../../hooks/useVendor";
import FilterButton from "../../updatedcomponents/table-components/FilterButton";
import useFetchData from "../../hooks/useFetchData";
import Button from "../../updatedcomponents/general/Button";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import { useAuth } from "../../hooks/useAuth";
import { useFacility } from "../../hooks/useFacility";

const Vendors = () => {
  const { selectedFacility } = useFacility();
  const { currentUser } = useAuth();
  const {
    selectedVendor,
    filters,
    handleViewVendor,
    showVendor,
    setShowVendor,
    applyFilters,
    resetFilters,
    handlePageChange,
    handlePageSizeChange,
    page,
    pageSize,
    handleDelete,
    showDelete,
    setShowDelete,
  } = useVendor();

  const [refresh, setRefresh] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const permissions = currentUser.access;

  const filterOptions = [
    {
      label: "Company Name",
      type: "text",
      name: "name",
    },
    {
      label: "Company Type",
      type: "selector",
      name: "type",
      options: [
        { label: "All", value: "" },
        { label: "Electrician", value: "electrician" },
        { label: "HVAC", value: "HVAC" },
        { label: "Plumber", value: "plumber" },
        { label: "Sprinkler", value: "sprinkler" },
        { label: "Other", value: "other" },
      ],
    },
  ];

  const { data: vendorData } = useFetchData(
    selectedFacility ? `/api/vendor/list/${selectedFacility}` : null,
    { page, limit: pageSize, filter: JSON.stringify(filters) },
    [selectedFacility, refresh, page, pageSize, filters],
    { vendors: [], totalPages: 1 }
  );


  return (
    <div>
      <PageHeader
        title="Vendors"
        subtitle="All vendors servicing your facility"
        buttons={
          (permissions === "regional" ||
            permissions === "organization-admin") && (
            <Button
              icon="Add"
              styleString="primary"
              children="Add Vendor"
              onClick={() => handleViewVendor(null)}
              overrideStyles="w-full"
            />
          )
        }
      />
      <div className=" px-4 sm:px-6 sm:overflow-y-auto sm:pb-2 thin-scrollbar ">
        <FilterButton setShowFilterModal={setShowFilter} />
        <FilterModal
          show={showFilter}
          setShow={setShowFilter}
          filters={filters}
          filterOptions={filterOptions}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
        />
        <VendorsTable vendors={vendorData?.vendors || []} />
        <Pagination
          itemsToShow={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          currentPage={page}
          setCurrentPage={handlePageChange}
          totalPages={vendorData?.totalPages || 1}
        />
      </div>

      {showVendor && (
        <NewVendorForm
          showPopup={showVendor}
          setShowPopup={setShowVendor}
          refresh={refresh}
          setRefresh={setRefresh}
          selectedVendor={selectedVendor}
        />
      )}
      {showDelete && (
        <GenericModal
          title={"Delete Vendor"}
          item={selectedVendor}
          handleFunction={handleDelete}
          showModal={showDelete}
          setShowModal={setShowDelete}
          color="reds-500"
          secondaryColor={"reds-1000"}
          buttonText="Delete"
        />
      )}
    </div>
  );
};

export default Vendors;
