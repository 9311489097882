import React, { createContext, useCallback, useState, ReactNode } from "react";
import axios from "axios";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Vendor } from "../types/Vendor";
import useFetchData from "../hooks/useFetchData";

interface PageSizeChangeProps {
  pageSize: string;
  page: number;
}
// Define the type for the context values
interface VendorContextType {
  selectedVendor: Vendor | null;
  setSelectedVendor: (vendor: Vendor | null) => void;
  handleViewVendor: (vendor: Vendor | null) => void;
  showVendor: boolean;
  setShowVendor: React.Dispatch<React.SetStateAction<boolean>>;
  totalPages: number;
  isLoading: boolean;
  updateSearchParams: (params: Record<string, string | number>) => void;
  pageSize: string;
  page: string;
  handlePageSizeChange: ({ pageSize, page }: PageSizeChangeProps) => void;
  handlePageChange: (newPage: number) => void;
  filters: string;
  applyFilters: (filters: Record<string, string>) => void;
  resetFilters: () => void;
  handleDelete: (vendor: Vendor) => void;
  showDelete: boolean;
  setShowDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

// Define the type for the provider props
interface VendorProviderProps {
  children: ReactNode;
}

// Create the context
export const VendorContext = createContext<VendorContextType | undefined>(
  undefined
);

// Create the provider component
export const VendorProvider = ({ children }: VendorProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = searchParams.get("filters") || "";
  const page = searchParams.get("page") || "1";
  const pageSize = searchParams.get("pageSize") || "10";
  const sortColumn = searchParams.get("sortColumn") || "dateReported";
  const sortOrder = searchParams.get("sortOrder") || "1";

  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const updateSearchParams = (newParams: Record<string, string | number>) => {
    setSearchParams(
      new URLSearchParams({
        ...Object.fromEntries(searchParams.entries()), // Preserve existing params
        ...Object.entries(newParams).reduce((acc, [key, value]) => {
          acc[key] = value.toString();
          return acc;
        }, {} as Record<string, string>),
      }),
      { replace: true }
    );
  };

  const handlePageSizeChange = ({ pageSize, page }: PageSizeChangeProps) => {
    updateSearchParams({ pageSize, page: page.toString() });
  };

  const handlePageChange = (newPage: number) => {
    updateSearchParams({ page: newPage.toString() });
  };

  const handleViewVendor = (vendor: Vendor | null) => {
    setSelectedVendor(vendor);
    setShowVendor(true);
  };

  const applyFilters = (filters: Record<string, string>) => {
    updateSearchParams({
      filters: JSON.stringify(filters),
      page: "1",
    });
  };

  const resetFilters = () => {
    updateSearchParams({
      filters: "",
      page: "1",
    });
  };

  const filterObject = searchParams.get("filters")
    ? JSON.parse(searchParams.get("filters")!)
    : {};

  const [showVendor, setShowVendor] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const handleDelete = async (vendor: Vendor) => {
    try {
      await axios.delete(`/api/vendor/delete/${vendor._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setShowDelete(false);
    } catch (error) {
      console.log("Error deleting vendor:", error);
    }
  };

  return (
    <VendorContext.Provider
      value={{
        selectedVendor,
        setSelectedVendor,
        handleViewVendor,
        showVendor,
        setShowVendor,
        totalPages,
        isLoading,
        updateSearchParams,
        pageSize,
        page,
        handlePageSizeChange,
        handlePageChange,
        filters: filterObject,
        applyFilters,
        resetFilters,
        handleDelete,
        showDelete,
        setShowDelete,
      }}
    >
      {children}
    </VendorContext.Provider>
  );
};
