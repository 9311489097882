import React, { useState } from "react";
import axios, { AxiosError } from "axios";
import {
  ComputerOutlined,
  CopyAll,
  DeleteOutline,
  EditOutlined,
} from "@mui/icons-material";
import CreateKioskForm from "../forms/CreateKioskForm";
import GenericModal from "../modals/GenericModal";
import { capitalizeString } from "../../utils/FormatFunctions";
import { Kiosk } from "../../types/Kiosk";
import { Facility } from "../../types/Facility";

interface KioskListProps {
  kiosks: Kiosk[];
  facility: Facility;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const KioskList: React.FC<KioskListProps> = ({
  kiosks,
  facility,
  refresh,
  setRefresh,
}) => {
  const [showConfirmDeactivate, setShowConfirmDeactivate] =
    useState<boolean>(false);
  const [selectedKiosk, setSelectedKiosk] = useState<Kiosk | null>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState("");

  const handleDeactivate = (kiosk: Kiosk) => {
    setSelectedKiosk(kiosk);
    setShowConfirmDeactivate(true);
  };
  const handleEdit = (kiosk: Kiosk) => {
    setSelectedKiosk(kiosk);
    setShowEdit(true);
  };

  const handleUpdate = async (kiosk: Kiosk, status: string) => {
    const kioskId = kiosk._id;
    try {
      const response = await axios.put(
        `/api/building/update/kiosk/${kioskId}`,
        { status: status },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Kiosk status updated:", response.data);
      setRefresh(!refresh);
      setShowConfirmDeactivate(false);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error("Server Error:", error.response.data);
        } else if (error.request) {
          console.error("Request Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      } else {
        console.error("Unexpected Error:", error);
      }
    }
  };

  const handleCopyLink = (kioskId: string) => {
    try {
      const kioskURI = "https://www.sequra.ai/kiosk/" + kioskId;

      navigator.clipboard.writeText(kioskURI);
      setShowCopyTooltip(kioskId);
      setTimeout(() => {
        setShowCopyTooltip("");
      }, 2000);
    } catch (err) {
      console.error("Unable to copy to clipboard:", err);
    }
  };

  return (
    <div className="w-full">
      <div className="mt-4 flex gap-3">
        <div className="flex flex-row gap-4">
          <ComputerOutlined
            style={{ fontSize: "3.5rem" }}
            className="text-secondary-100 bg-secondary-1100 rounded-full p-2"
          />
          <div className="flex flex-col">
            <h2 className="text-xl font-semibold text-secondary-100">Kiosks</h2>
            <p className="text-sm text-secondary-500 font-light">
              View and manage your building's kiosks
            </p>
          </div>
        </div>
      </div>
      <ul
        role="list"
        className="divide-y divide-secondary-1000 pt-4 pb-2 px-2 border-b border-secondary-1000"
      >
        {kiosks.length > 0 ? (
          kiosks.map((kiosk) => (
            <li
              key={kiosk._id}
              className="flex items-center justify-between py-5"
            >
              {/* Info section */}
              <div className="min-w-0">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-semibold  text-secondary-100">
                    {capitalizeString(kiosk.name)}
                  </p>
                  <button
                    onClick={() => handleCopyLink(kiosk._id)}
                    className="flex items-center bg-secondary-1100 hover:bg-gray-100 shadow-sm"
                  >
                    <CopyAll style={{ fontSize: ".9rem" }} />
                  </button>
                  {showCopyTooltip === kiosk._id && (
                    <div className=" z-50 bg-accent-1000 text-xs text-accent-300 border border-accent-300 text-nowrap w-fit px-2 py-0.5 rounded-md shadow-sm">
                      Link copied to clipboard!
                    </div>
                  )}
                </div>
                <div className="mt-1 flex flex-col sm:flex-row sm:items-center gap-x-2 text-xs leading-5 text-secondary-500">
                  <p className="whitespace-nowrap">
                    {capitalizeString(kiosk.location)}
                  </p>
                  <div className="flex items-center gap-1">
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p className="truncate">Pin {kiosk.pin}</p>
                  </div>
                </div>
              </div>
              {/* Action Section */}
              <div className="flex flex-none items-center gap-x-4">
                <span
                  className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium  ring-1 ring-inset  ${
                    kiosk.status === "inactive"
                      ? "text-reds-500  bg-reds-1000 ring-reds-500/10"
                      : "text-accent-500  bg-accent-1000 ring-accent-500/10"
                  }`}
                >
                  {capitalizeString(kiosk.status)}
                </span>

                <button
                  className="bg-secondary-1000 px-2.5 py-0.5 rounded-sm flex justify-center items-center gap-x-1 text-sm font-normal text-secondary-100 shadow-sm hover:bg-secondary-1100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500"
                  onClick={() => handleEdit(kiosk)}
                >
                  <EditOutlined style={{ fontSize: ".9rem" }} />
                  Edit
                </button>
                <button
                  className="bg-secondary-1100 text-reds-500 px-0.5 py-0.5 rounded-sm flex justify-center items-center gap-x-1 text-sm font-semibold border border-secondary-1100  shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-reds-500"
                  onClick={() => handleDeactivate(kiosk)}
                >
                  <DeleteOutline style={{ fontSize: "1rem" }} />
                </button>
              </div>
            </li>
          ))
        ) : (
          <div className="p-4">
            <h2 className="text-md text-secondary-500 italic">
              No kiosks present
            </h2>
          </div>
        )}
      </ul>
      {showConfirmDeactivate && (
        <GenericModal
          showModal={showConfirmDeactivate}
          setShowModal={setShowConfirmDeactivate}
          item={selectedKiosk}
          buttonText={`Delete ${selectedKiosk?.name}`}
          title="Delete Kiosk"
          handleFunction={() => selectedKiosk && handleUpdate(selectedKiosk, "inactive")}
        />
      )}
      {selectedKiosk && (
        <CreateKioskForm
          show={showEdit}
          setShow={setShowEdit}
          refresh={refresh}
          setRefresh={setRefresh}
          kiosk={selectedKiosk}
        />
      )}
    </div>
  );
};

export default KioskList;
