import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Close } from "@mui/icons-material";

interface FormInputModalProps {
  show: boolean;
  handleClose: () => void;
  title?: string;
  content: React.ReactNode;
}

const FormInputModal: React.FC<FormInputModalProps> = ({
  show,
  handleClose,
  title,
  content,
}) => {
  return (
    <div>
      {" "}
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => handleClose()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-secondary-100 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen ">
            <div className="flex h-screen justify-center text-center items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-full my-auto transform rounded-sm bg-primary text-left shadow-xl transition-all sm:w-full mx-4 sm:max-w-2xl max-h-[90vh] overflow-hidden">
                  <div className="flex flex-1 flex-col bg-primary h-full">
                    <div className="px-4 py-4 border-b border-secondary-1000">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-medium leading-6 text-secondary-100">
                          {title ?? ""}
                        </Dialog.Title>
                        <div>
                          <button
                            type="button"
                            className="justify-center items-center relative rounded-sm bg-primary text-secondary-700 hover:text-secondary-500 focus:outline-none"
                            onClick={() => handleClose()}
                          >
                            <span className="sr-only">Close panel</span>
                            <Close
                              style={{ fontSize: "1rem" }}
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-2 sm:px-6 overflow-y-auto">
                      {content}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default FormInputModal;
