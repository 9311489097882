// ResetPassword.js
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Check } from "@mui/icons-material";

const Registration: React.FC = () => {
  const { token } = useParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetError, setResetError] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleResetPassword = async (e : React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setResetError("Passwords do not match.");
      return;
    }

    try {
      // Send the password reset request to the backend API using the token and new password
      await axios.post(`/api/authenticate/reset-password?token=${token}`, {
        password,
      });

      // If the API call is successful, set resetSuccess to true
      setResetSuccess(true);
    } catch (error) {
      console.error("Error resetting password:", error);
      setResetError("Failed to reset password. Please try again.");
    }
  };

  const inputs = [
    {
      label: "New Password",
      placeholder: "New password",
      type: "password",
      value: password,
      onChange: handlePasswordChange,
      required: true,
    },
    {
      label: "Confirm New Password",
      placeholder: "Confirm new password",
      type: "password",
      value: confirmPassword,
      onChange: handleConfirmPasswordChange,
      required: true,
    },
  ];

  const navigate = useNavigate();

  return (
    <div className="flex min-h-screen flex-1 flex-col items-center justify-center px-6 py-12 lg:px-8 bg-secondary-1100">
      <div
        className="absolute left-5 top-5 cursor-pointer"
        onClick={() => navigate("/login")}
      >
        <img
          className="mx-auto h-8 w-auto"
          src="\sequra_logo__primary_black.png"
          alt="SNFSafe logo"
        />
      </div>
      {resetSuccess ? (
        <div className="bg-primary p-4 rounded-md">
          <div className="text-center">
            <Check
              className="rounded-full bg-accent-1000 text-accent-500 p-2"
              style={{ fontSize: "3rem" }}
            />
            <h2 className=" font-header text-center text-secondary-500 my-6">
              Password Reset Successful!
            </h2>
            <p className="text-secondary text-sm">
              Your password has been reset successfully.
            </p>
            <Link to="/login">
              <p className="text-accent-500 py-2 hover:text-accent-600">
                Navigate to Login
              </p>
            </Link>
          </div>
        </div>
      ) : (
        <div className="bg-primary p-8 rounded shadow sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="font-header text-center text-2xl  leading-9 tracking-tight text-text">
              Reset Password
            </h2>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleResetPassword}>
              {inputs.map((input, index) => (
                <div key={index}>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    {input.label}
                  </label>
                  <input
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                    placeholder={input.placeholder}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    required={input.required}
                  />
                </div>
              ))}
              {resetError && <p style={{ color: "red" }}>{resetError}</p>}
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-accent-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-accent-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500"
              >
                Reset Password
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Registration;
