import React, { useEffect, useState } from "react";
import { FilterAltOutlined } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

interface FilterButtonProps {
  setShowFilterModal: (show: boolean) => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({ setShowFilterModal }) => {
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    updateActiveFilterCount();
  }, [searchParams]);

  const updateActiveFilterCount = () => {
    const currentFilters = JSON.parse(searchParams.get("filters") || "{}");
    const count = Object.values(currentFilters).filter(
      (value) => value !== ""
    ).length;
    setActiveFilterCount(count);
  };

  return (
    <button
      className="my-4 flex gap-1 bg-secondary-1100 text-secondary-100 items-center px-2 py-1.5 rounded-sm"
      onClick={() => setShowFilterModal(true)}
    >
      <FilterAltOutlined style={{ fontSize: "1rem" }} />
      <span className="text-sm font-semibold">Advanced Filters</span>
      {activeFilterCount > 0 && (
        <span className="ml-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-black rounded">
          {activeFilterCount}
        </span>
      )}
    </button>
  );
};

export default FilterButton;
