import React, { useState, useEffect, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Close, DescriptionOutlined, NoteOutlined } from "@mui/icons-material";
import { formatDate } from "../../utils/FormatFunctions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import { Notes } from "../../types/WorkOrder";

interface TaskNotesProps {
  selectedTask: any;
  showNotes: boolean;
  setShowNotes: React.Dispatch<React.SetStateAction<boolean>>;
}
interface FieldValues {
  newNote: string;
}

const TaskNotes: React.FC<TaskNotesProps> = ({
  selectedTask,
  showNotes,
  setShowNotes,
}) => {
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);
  const { currentUser } = useAuth();

  const [notes, setNotes] = useState<Notes[]>([]);

  const schema = yup.object().shape({
    newNote: yup
      .string()
      .required("Note is required")
      .max(150, "Note must be less than 150 characters"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (selectedTask) {
      setNotes(selectedTask.notes || []);
    }
  }, [selectedTask]);

  const handleAddNotes = async (data: FieldValues) => {
    const { newNote } = data;
    try {
      const response = await axios.post(
        `/api/employee-tasks/add-note/${selectedTask._id}`,
        {
          text: newNote,
          user: currentUser._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Note added:", response.data.note);

      setNotes([...notes, response.data.note]);
    } catch (error) {
      console.error("Error adding note:", error);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  return (
    <Transition.Root show={showNotes} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setShowNotes}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center p-4 sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-sm bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
                <div className="text-left">
                  <Dialog.Title
                    as="h3"
                    className="flex justify-between text-gray-700 border-b border-gray-2300 pb-2"
                  >
                    <div className="flex items-center">
                      <div className="bg-gray-200 px-1 py-1.5 rounded-sm items-center flex ml-4">
                        <DescriptionOutlined style={{ fontSize: "1rem" }} />
                      </div>
                      <span className="ml-2 font-semibold">Notes</span>
                    </div>
                    <button
                      className="mr-4 text-gray-700 cursor-pointer"
                      onClick={() => setShowNotes(false)}
                    >
                      <Close style={{ fontSize: "1rem" }} />
                    </button>
                  </Dialog.Title>
                </div>
                <div className="mt-2 mx-4">
                  <div className="flex flex-col">
                    <h3 className="text-gray-700 text-md font-semibold">
                      {selectedTask.title}
                    </h3>
                    <span className="text-xs text-gray-500">
                      {formatDate(
                        selectedTask.dateReported,
                        "YYYY-MM-DD h:mm:ss a"
                      )}
                    </span>
                  </div>
                  <div className="mt-2 max-h-60 overflow-y-auto text-left bg-gray-50 border-gray-100 border p-2">
                    {notes.length !== 0 ? (
                      <ul>
                        {notes.map((note, index) => (
                          <li key={index}>
                            <span className="text-sm text-gray-900">
                              {note.userId
                                ? `${note.userId.firstname.charAt(0)}. ${
                                    note.userId.lastname
                                  }`
                                : "Unknown user"}
                            </span>
                            {": "}
                            {
                              <span className="text-sm text-secondary">
                                {note.text}
                              </span>
                            }{" "}
                            {
                              <div
                                className=" text-gray-600"
                                style={{ fontSize: "10px" }}
                              >
                                {formatDate(
                                  note.timestamp,
                                  "YYYY-MM-DD h:mm:ss a"
                                )}
                              </div>
                            }
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="flex flex-col text-gray-500  justify-center items-center">
                        <NoteOutlined />
                        <span className="text-xs  text-center">
                          No previous notes
                        </span>
                      </div>
                    )}
                  </div>
                  <form onSubmit={handleSubmit(handleAddNotes)}>
                    <div className="mt-2 text-left  flex flex-col">
                      <textarea
                        {...register("newNote")}
                        rows={3}
                        className={`resize-none block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 ${
                          errors.newNote
                            ? "ring-2 ring-inset ring-reds-500"
                            : "ring-1 ring-gray-300"
                        }`}
                        placeholder="Place note here..."
                      />
                      {errors.newNote && (
                        <p className="text-xs text-reds-500">
                          {errors.newNote.message}
                        </p>
                      )}
                    </div>

                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-sm bg-secondary-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500 sm:col-start-2"
                      >
                        Save Note
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-sm bg-white px-3 py-2 text-sm font-semibold text-secondary-100 shadow-sm ring-1 ring-inset ring-secondary-1000 hover:bg-secondary-1200 sm:col-start-1 sm:mt-0"
                        onClick={() => setShowNotes(false)}
                        ref={cancelButtonRef}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default TaskNotes;
