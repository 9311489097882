import React, { useEffect, useState, useContext, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Close, Remove } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { select } from "d3";
import { FacilityContext } from "../../context/FacilityContext";

type InputType = {
  id: string;
  label: string;
  placeholder: string;
};

const inputs: InputType[] = [
  {
    id: "location",
    label: "Request Location",
    placeholder: "Location",
  },
  {
    id: "requestedBy",
    label: "Requested By",
    placeholder: "Requested By",
  },
  {
    id: "title",
    label: "Request Title",
    placeholder: "Request Title",
  },
];

type FormValues = {
  location: string;
  requestedBy: string;
  requestOrigin: string;
  title: string;
  requestType: string;
  details: string;
  urgency: string;
  status?: string;
  photos?: any[] | null;
};

interface NewWorkOrderProps {
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewWorkOrder: React.FC<NewWorkOrderProps> = ({
  showPopup,
  setShowPopup,
  refresh,
  setRefresh,
}) => {
  const { selectedFacility } = useContext(FacilityContext);

  const schema = yup.object().shape({
    location: yup.string().required("This field is required."),
    requestedBy: yup.string().required("This field is required."),
    requestOrigin: yup.string().required("This field is required."),
    title: yup.string().required("This field is required."),
    requestType: yup.string().required("This field is required."),
    details: yup.string().required("This field is required."),
    urgency: yup.string().required("This field is required."),
    status: yup.string(),
    photos: yup.array().nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      location: "",
      requestedBy: "",
      requestOrigin: "user-login",
      title: "",
      requestType: "not-specified",
      details: "",
      urgency: "standard",
      status: "incomplete",
      photos: [],
    },
  });

  const [photos, setPhotos] = useState<File[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      const newPhotos = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setPhotos((prevPhotos) => {
        const updatedPhotos = [...prevPhotos, ...newPhotos];
        setValue("photos", updatedPhotos);
        return updatedPhotos;
      });
    },
  });

  const removeFile = (index: number) => {
    const updatedPhotos = photos.filter((photo, i) => i !== index);
    setPhotos(updatedPhotos);
    setValue("photos", updatedPhotos);
  };

  const onSubmit = async (data: FormValues) => {
    try {
      const formData = new FormData();
      formData.append("facility", selectedFacility);
      formData.append("dateReported", new Date().toISOString());
      Object.keys(data).forEach((key) => {
        if (key === "photos") {
          data[key]?.forEach((file) => {
            formData.append("photos", file);
          });
        } else {
          formData.append(key, data[key as keyof FormValues] as string | Blob);
        }
      });

      await axios.post("/api/work-order/create", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setPhotos([]);
      setShowPopup(false);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  return (
    <Transition.Root show={showPopup} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setShowPopup}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex h-full flex-col overflow-y-scroll thin-scrollbar bg-primary shadow-xl"
                    noValidate
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-secondary-100 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-primary">
                              New work order
                            </Dialog.Title>
                            <p className="text-sm text-secondary-1200">
                              Get started by filling in the information below to
                              create the new work order.
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-secondary-1200 hover:text-gray-500"
                              onClick={() => setShowPopup(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <Close className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-secondary-1000 sm:py-0">
                        {/* Project name */}
                        {inputs.map((input) => (
                          <div
                            key={input.id}
                            className={`space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 ${
                              errors[input.id as keyof FormValues] ? "text-reds-600" : ""
                            }`}
                          >
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
                            >
                              {input.label}
                            </label>

                            <div className="sm:col-span-2">
                              <input
                                {...register(input.id as keyof FormValues)}
                                placeholder={input.placeholder}
                                className={`block w-full rounded-sm border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-secondary-600 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 ${
                                  errors[input.id as keyof FormValues]
                                    ? "ring-reds-600 ring-2"
                                    : "text-secondary-100"
                                }`}
                              />
                              <p className="text-xs">
                                {errors[input.id as keyof FormValues]?.message}
                              </p>
                            </div>
                          </div>
                        ))}
                        {/* Request Type */}
                        <div
                          className={
                            "space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                          }
                        >
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
                          >
                            Request Type
                          </label>

                          <div className="sm:col-span-2">
                            <select
                              {...register("requestType")}
                              className={
                                "block w-full rounded-sm border-0 py-1.5 text-secondary-600 shadow-sm  placeholder:text-secondary-600 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300"
                              }
                            >
                              <option value={"not-specified"}>
                                Please select a value
                              </option>
                              <option value={"Electrical"}>Electrical</option>
                              <option value={"Plumbing"}>Plumbing</option>
                              <option value={"HVAC"}>HVAC</option>
                              <option value={"Other"}>Other</option>
                            </select>
                          </div>
                        </div>

                        {/* Details */}
                        <div
                          className={`space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 ${
                            errors.details ? "error" : ""
                          }`}
                        >
                          <div>
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
                            >
                              Description
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <textarea
                              {...register("details")}
                              rows={3}
                              className={`block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-secondary-600 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 ${
                                errors.details && "ring-reds-600 ring-2"
                              }`}
                            />
                            <p className="text-xs text-reds-600">
                              {errors.details?.message}
                            </p>
                          </div>
                        </div>
                        {/* Urgency */}
                        <div
                          className={
                            "space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                          }
                        >
                          <label
                            htmlFor="urgency"
                            className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
                          >
                            Urgency
                          </label>

                          <div className="sm:col-span-2">
                            <select
                              {...register("urgency")}
                              className={
                                "block w-full rounded-sm border-0 py-1.5 text-secondary-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-secondary-600 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                              }
                            >
                              <option value={"standard"}>Standard</option>
                              <option value={"urgent"}>Urgent</option>
                            </select>
                          </div>
                        </div>
                        {/* Attachments */}
                        <div
                          className={
                            "space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                          }
                        >
                          <label
                            htmlFor="attachments"
                            className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
                          >
                            Images
                          </label>
                          <div className="col-span-2">
                            <ul className="flex flex-wrap mb-2 ">
                              {photos &&
                                photos.map((file, index) => (
                                  <li
                                    key={file.name}
                                    className="relative flex w-fit border-gray-300 border rounded-sm mr-2 mb-2"
                                  >
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                      className="w-24 h-24 object-contain rounded-sm"
                                    />
                                    <button
                                      className="absolute top-0 right-0  text-gray-300 rounded-full bg-secondary-1100 bg-opacity-35"
                                      type="button"
                                      onClick={() => removeFile(index)}
                                    >
                                      <Close />
                                    </button>
                                  </li>
                                ))}
                            </ul>
                            <div
                              {...getRootProps()}
                              className=" flex items-center justify-center rounded-sm border-secondary-900 border-dashed border sm:p-6 p-4 cursor-pointer hover:bg-gray-50 text-secondary-600 hover:text-gray-600"
                            >
                              <input {...getInputProps()} />
                              <p className=" text-sm">
                                Drag 'n' drop here, or click to select images
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-secondary-1000 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-sm bg-white px-3 py-2 text-sm font-semibold text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setShowPopup(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-sm bg-accent-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-100 disabled:opacity-50"
                          disabled={isSubmitting}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewWorkOrder;
