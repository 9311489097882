import {
  createContext,
  useCallback,
  useState,
  ReactNode,
  useEffect,
} from "react";
import axios from "axios";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { WorkOrder } from "../types/WorkOrder";
import { User } from "../types/User";
import { useFacility } from "../hooks/useFacility";

interface PageSizeChangeProps {
  pageSize: string;
  page: number;
}
// Define the type for the context values
interface WorkOrderContextType {
  workOrders: WorkOrder[];
  setWorkOrders: React.Dispatch<React.SetStateAction<WorkOrder[]>>;
  totalPages: number;
  isLoading: boolean;
  searchParams: URLSearchParams;
  updateSearchParams: (params: Record<string, string | number>) => void;
  fetchWorkOrders: (selectedFacility: string) => Promise<void>;
  selectedWorkOrder: WorkOrder | null;
  setSelectedWorkOrder: React.Dispatch<React.SetStateAction<WorkOrder | null>>;
  updateWorkOrderInContext: (updatedWorkOrder: WorkOrder) => void;
  fetchAdjacentWorkOrder: (
    direction: string,
    selectedFacility: string
  ) => Promise<void>;
  pageSize: string;
  page: string;
  handlePageSizeChange: ({ pageSize, page }: PageSizeChangeProps) => void;
  handlePageChange: (newPage: number) => void;
  handleView: (workOrderId: string) => void;
  filters: string;
  applyFilters: (filters: Record<string, string>) => void;
  resetFilters: () => void;
  users: User[];
  images: string[];
}

// Define the type for the provider props
interface WorkOrderProviderProps {
  children: ReactNode;
}

// Create the context
export const WorkOrderContext = createContext<WorkOrderContextType | undefined>(
  undefined
);

// Create the provider component
export const WorkOrderProvider = ({ children }: WorkOrderProviderProps) => {
  const { selectedFacility } = useFacility();
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = searchParams.get("filters") || "";
  const page = searchParams.get("page") || "1";
  const pageSize = searchParams.get("pageSize") || "10";
  const sortColumn = searchParams.get("sortColumn") || "dateReported";
  const sortOrder = searchParams.get("sortOrder") || "1";

  const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<WorkOrder | null>(
    null
  );
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const updateSearchParams = (newParams: Record<string, string | number>) => {
    setSearchParams(
      new URLSearchParams({
        ...Object.fromEntries(searchParams.entries()), // Preserve existing params
        ...Object.entries(newParams).reduce((acc, [key, value]) => {
          acc[key] = value.toString();
          return acc;
        }, {} as Record<string, string>),
      }),
      { replace: true }
    );
  };

  const fetchWorkOrders = useCallback(
    async (selectedFacility: string) => {
      try {
        setIsLoading(true);
        const filterObject = filters ? JSON.parse(filters) : {};

        if (!selectedFacility) {
          return;
        }

        const token = localStorage.getItem("token");
        const queryString = new URLSearchParams({
          ...filterObject,
          page,
          pageSize,
          sortColumn,
          sortOrder,
        }).toString();
        const response = await axios.get(
          `/api/work-order/list/${selectedFacility}?${queryString}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.workOrders === undefined) {
          return;
        }
        setWorkOrders(response.data.workOrders || []);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching work orders:", error);
        setIsLoading(false);
      }
    },
    [isLoading, searchParams]
  );

  const fetchAdjacentWorkOrder = useCallback(
    async (direction: string, selectedFacility: string) => {
      if (!selectedWorkOrder) return;
      const workOrderId = selectedWorkOrder._id;
      try {
        const token = localStorage.getItem("token");
        const filterObject = filters ? JSON.parse(filters) : {};
        const queryString = new URLSearchParams({
          workOrderId,
          direction,
          sortColumn,
          sortOrder,
          ...filterObject,
        }).toString();
        const response = await axios.get(
          `/api/work-order/next/${selectedFacility}?${queryString}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data) {
          setSelectedWorkOrder(response.data);
        } else {
          console.log("No more work orders in that direction");
        }
      } catch (error: any) {
        console.error("Error loading work order", error.response);
      }
    },
    [selectedWorkOrder]
  );

  const updateWorkOrderInContext = (updatedWorkOrder: WorkOrder) => {
    setWorkOrders((prevWorkOrders) =>
      prevWorkOrders.map((wo) =>
        wo._id === updatedWorkOrder._id ? updatedWorkOrder : wo
      )
    );
    setSelectedWorkOrder(updatedWorkOrder);
  };

  const handlePageSizeChange = ({ pageSize, page }: PageSizeChangeProps) => {
    updateSearchParams({ pageSize, page: page.toString() });
  };

  const handlePageChange = (newPage: number) => {
    updateSearchParams({ page: newPage.toString() });
  };

  const navigate = useNavigate();

  const handleView = (workOrderId: string) => {
    if (workOrderId) {
      navigate(`/work-orders/instance/${workOrderId}?${searchParams}`);
    } else {
      console.error("Error viewing work order: work order not, ", workOrderId);
    }
  };

  const applyFilters = (filters: Record<string, string>) => {
    updateSearchParams({
      filters: JSON.stringify(filters),
      page: "1",
    });
  };

  const resetFilters = () => {
    updateSearchParams({
      filters: "",
      page: "1",
    });
  };

  const filterObject = searchParams.get("filters")
    ? JSON.parse(searchParams.get("filters")!)
    : {};

  const [images, setImages] = useState([]);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const keys = selectedWorkOrder?.attachments?.map((url) =>
      new URL(url).pathname.substring(1)
    );
    async function fetchImages(keys: string[]) {
      try {
        const queryString = new URLSearchParams({
          keys: keys.join(","),
        }).toString();

        const response = await axios.get(
          `/api/work-order/images?${queryString}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setImages(response.data.map((image: any) => image.url));
      } catch (error) {
        console.error("Error fetching image URLs:", error);
      }
    }
    async function fetchUsers() {
      if (!selectedFacility || !selectedWorkOrder) return;

      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `/api/work-order/users/${selectedFacility}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }

    if (keys && keys.length > 0) {
      fetchImages(keys);
    }

    fetchUsers();
  }, [selectedWorkOrder, selectedFacility]);

  return (
    <WorkOrderContext.Provider
      value={{
        workOrders,
        setWorkOrders,
        totalPages,
        isLoading,
        searchParams,
        updateSearchParams,
        fetchWorkOrders,
        selectedWorkOrder,
        setSelectedWorkOrder,
        updateWorkOrderInContext,
        fetchAdjacentWorkOrder,
        pageSize,
        page,
        handlePageSizeChange,
        handlePageChange,
        handleView,
        filters: filterObject,
        applyFilters,
        resetFilters,
        images,
        users,
      }}
    >
      {children}
    </WorkOrderContext.Provider>
  );
};
