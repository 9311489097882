import React, { useEffect, useState } from "react";
import axios from "axios";
import FacilitiesList from "../../updatedcomponents/tables/FacilitiesList";
import { Add } from "@mui/icons-material";
import NewFacilityForm from "../../updatedcomponents/forms/NewFacilityForm";
import { Facility } from "../../types/Facility";

const Facilities = () => {
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [selectedFacility, setSelectedFacility] = useState<Facility | null>(
    null
  );
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/admin/facilities", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFacilities(response.data);
      } catch (error) {
        console.error("Error loading logs", error);
      }
    };
    fetchData();
  }, []);

  const handleAddFacility = () => {
    setShowAdd(true);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 rounded-lg  shadow">
      <div className="fixed bottom-8 right-8 z-50">
        <button
          type="button"
          className="rounded-full bg-accent-100 p-2 text-white shadow-sm hover:bg-accent-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-100"
          onClick={handleAddFacility}
        >
          <Add className="h-10 w-10" aria-hidden="true" />
        </button>
      </div>
      <FacilitiesList facilities={facilities} setFacilities={setFacilities} />
      <NewFacilityForm
        showPopup={showAdd}
        setShowPopup={setShowAdd}
        facility={selectedFacility}
        facilities={facilities}
        setFacilities={setFacilities}
        type={"new"}
      />
    </div>
  );
};

export default Facilities;
