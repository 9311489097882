import React, { createContext, useCallback, useState, ReactNode } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { RecurringTask } from "../types/RecurringTask";
import { TaskInstance } from "../types/TaskInstance";

interface RecurringTaskContextProps {
    recurringTasks: TaskInstance[];
    setRecurringTasks: React.Dispatch<React.SetStateAction<TaskInstance[]>>;
    totalPages: number;
    isLoading: boolean;
    searchParams: URLSearchParams;
    updateSearchParams: (newParams: Record<string, string>) => void;
    fetchRecurringTasks: (selectedFacility: string) => Promise<void>;
}

export const RecurringTaskContext = createContext<RecurringTaskContextProps | undefined>(undefined);

interface RecurringTaskProviderProps {
    children: ReactNode;
}

export const RecurringTaskProvider: React.FC<RecurringTaskProviderProps> = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [recurringTasks, setRecurringTasks] = useState<TaskInstance[]>([]);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const updateSearchParams = (newParams: Record<string, string>) => {
        setSearchParams(
            {
                ...Object.fromEntries(searchParams.entries()),
                ...newParams,
            },
            { replace: true }
        );
    };

    const fetchRecurringTasks = useCallback(async (selectedFacility: string) => {
        try {
            setIsLoading(true);
            const filtersString = searchParams.get("filters");
            const filters = filtersString ? JSON.parse(filtersString) : {};
            const page = searchParams.get("page") || "1";
            const pageSize = searchParams.get("pageSize") || "10";
            const readyToComplete = searchParams.get("readyToComplete") || "true";

            if (!selectedFacility) {
                return;
            }

            const token = localStorage.getItem("token");
            const queryParams = new URLSearchParams({
                ...filters,
                page,
                pageSize,
                readyToComplete,
            });

            const response = await axios.get(
                `/api/log/task/list/${selectedFacility}?${queryParams.toString()}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.tasks === undefined) {
                console.error("No tasks in response");
                return;
            }
            setRecurringTasks(response.data.tasks || []);
            setTotalPages(response.data.pages);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching recurring tasks:", error);
            setIsLoading(false);
        }
    }, [searchParams]);

    return (
        <RecurringTaskContext.Provider
            value={{
                recurringTasks,
                setRecurringTasks,
                totalPages,
                isLoading,
                searchParams,
                updateSearchParams,
                fetchRecurringTasks,
            }}
        >
            {children}
        </RecurringTaskContext.Provider>
    );
}