import { Check, Close } from "@mui/icons-material";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [resetRequested, setResetRequested] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleResetRequest = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/authenticate/new-password", {
        email: email.toLowerCase(),
      });
      if (response.status === 200) {
        setResetRequested(true);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data.message);
      } else {
        setError("An unexpected error occurred.");
      }
    }
  };
  const navigate = useNavigate();

  return (
    <div className="flex min-h-screen flex-1 flex-col items-center justify-center px-6 py-12 lg:px-8 bg-secondary-1000">
      <div
        className="absolute left-5 top-5 cursor-pointer"
        onClick={() => navigate("/login")}
      >
        <img
          className="mx-auto h-8 w-auto"
          src="\sequra_logo__primary_black.png"
          alt="SNFSafe logo"
        />
      </div>
      {resetRequested ? (
        <div className="flex flex-col justify-center items-center gap-4 bg-white p-8 rounded shadow sm:mx-auto sm:w-full sm:max-w-sm">
          <p className="text-center">
            {`An email with instructions to reset your password has been sent to
            ${email}.`}
          </p>
          <p className="p-2 bg-accent-500 rounded-full w-fit ">
            <Check className="   text-primary " />
          </p>
        </div>
      ) : (
        <div className="bg-white p-8 rounded shadow sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            {/* */}
            <h2 className="mt-10 text-center font-semibold text-2xl leading-9  text-secondary-100">
              Reset Password
            </h2>
            <p className="mt-2 text-xs text-center text-secondary-400">
              Enter your email address and we will send you a link to reset your
              password.
            </p>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" onSubmit={handleResetRequest}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                {error && (
                  <div className="rounded-md bg-red-50 py-2">
                    <div className="flex">
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">
                          {error}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-4">
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-accent-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-accent-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100"
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
