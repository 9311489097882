import React from "react";

interface PageHeaderProps {
  title: React.ReactNode | string;
  subtitle?: string;
  buttons?: React.ReactNode;
  wrap?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle = "",
  buttons,
  wrap = true,
}) => {
  return (
    <div className="sticky top-0 bg-primary z-30 flex flex-col justify-between sm:flex-row items-center px-4 sm:pl-6 border-b border-secondary-1000 ">
      <div className=" flex flex-col sm:flex-row justify-between sm:items-center w-full sm:w-auto ">
        <span className="text-xl text-secondary-100 py-3 ">{title}</span>
        {subtitle.trim() !== "" && (
          <span className="flex text-sm text-secondary-500 pb-2 sm:ml-4 sm:border-secondary-1000 sm:border-l-2 sm:pl-3">
            {subtitle}
          </span>
        )}
      </div>
      {buttons && (
        <div
          className={`py-2 h-full sm:py-0 sm:justify-end justify-between items-center gap-2 flex w-full sm:w-fit ${
            wrap ? "flex-wrap" : ""
          } `}
        >
          {buttons}
        </div>
      )}
    </div>
  );
};

export default PageHeader;
