import React, { createContext, useEffect, useState, ReactNode } from "react";
import axios from "axios";

interface FacilityContextProps {
  selectedFacility: string;
  handleFacilityChange: (facility: string, userId: string) => void;
}

interface FacilityProviderProps {
  children: ReactNode;
}

export const FacilityContext = createContext<FacilityContextProps>({
  selectedFacility: "",
  handleFacilityChange: (facility: string, userId: string) => {},
});

export const FacilityProvider: React.FC<FacilityProviderProps> = ({ children }) => {
  const [selectedFacility, setSelectedFacility] = useState("");
  const [FacilityLocation, setFacilityLocation] = useState("");

  useEffect(() => {
    const storedFacility = localStorage.getItem("selectedFacility");
    if (storedFacility) {
      setSelectedFacility(storedFacility);
    }
  }, []);

  const updateLastLoggedFacility = async (userId: string, facilityId: string) => {
    try {
      await axios.post(
        `/api/user/update/facility-change/${facilityId}`,
        { userId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error updating facility:", error);
    }
  };

  const handleFacilityChange = (facility: string, userId: string) => {
    updateLastLoggedFacility(userId, facility);
    setSelectedFacility(facility);
    localStorage.setItem("selectedFacility", facility);
  };

  return (
    <FacilityContext.Provider value={{ selectedFacility, handleFacilityChange }}>
      {children}
    </FacilityContext.Provider>
  );
};