import React, { useState } from "react";
import axios from "axios";
import {
  DeleteOutline,
  PolicyOutlined,
  SourceOutlined,
} from "@mui/icons-material";
import GenericModal from "../modals/GenericModal";
import { File } from "../../types/File";
import { capitalizeString } from "../../utils/FormatFunctions";

interface FilesListProps {
  files: File[];
  type: string;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const FilesList: React.FC<FilesListProps> = ({
  files,
  type,
  refresh,
  setRefresh,
}) => {
  const [openingFile, setOpeningFile] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [fileToDelete, setFileToDelete] = useState<File | null>(null);

  const handleFileView = async (file: File) => {
    setOpeningFile(true);
    try {
      const response = await axios.get(
        `/api/building/files/fetch/${file._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        window.open(response.data.url, "_blank");
      } else {
        console.error("Error fetching file:", response);
      }
    } catch (error) {
      console.error("Error fetching file:", error);
    } finally {
      setOpeningFile(false);
    }
  };

  const handleDeleteClick = (file: File) => {
    setFileToDelete(file);
    setShowDeleteModal(true);
  };

  const handleFileDelete = async (file: File) => {
    try {
      await axios
        .delete(`/api/building/files/delete/${file._id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(() => {
          setRefresh(!refresh);
          setShowDeleteModal(false);
        });
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  function formatBytes(bytes:number, decimals: number = 2 ) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <div className="w-full">
      <GenericModal
        title={`Delete ${fileToDelete?.name}`}
        item={fileToDelete?.name}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        handleFunction={() => fileToDelete && handleFileDelete(fileToDelete)}
      />

      <div className="mt-4 flex gap-3">
        <div className="flex flex-row gap-4">
          {type === "policies" ? (
            <PolicyOutlined
              style={{ fontSize: "3.5rem" }}
              className="text-secondary-100 bg-secondary-1100 rounded-full p-2"
            />
          ) : (
            <SourceOutlined
              style={{ fontSize: "3.5rem" }}
              className="text-secondary-100 bg-secondary-1100 rounded-full p-2"
            />
          )}
          <div className="flex flex-col">
            <h2 className="text-xl font-semibold text-secondary-100">
              {capitalizeString(type)}
            </h2>
            <p className="text-sm text-secondary-500 font-light">
              View and manage your building's kiosks
            </p>
          </div>
        </div>
      </div>
      <ul
        role="list"
        className="divide-y divide-secondary-1000 pt-4 pb-2 px-2 border-b border-secondary-1000"
      >
        {files.length > 0 ? (
          files.map((file) => (
            <li
              key={file._id}
              className="flex items-center justify-between py-5"
            >
              <div className="min-w-0">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-semibold text-secondary-100">
                    {file.name}
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-secondary-500">
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <p className="whitespace-nowrap">
                    File Size <span>{formatBytes(file.size)}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-none items-center gap-x-4">
                <button
                  onClick={() => handleFileView(file)}
                  disabled={openingFile}
                  className={` rounded-sm px-2.5 py-0.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-secondary-700 sm:block ${
                    openingFile
                      ? "bg-secondary-1100 text-secondary-700 cursor-not-allowed"
                      : "bg-secondary-1100 text-secondary-100 hover:bg-gray-50"
                  }`}
                >
                  View<span className="sr-only">, {file.name}</span>
                </button>
                <button
                  className="bg-secondary-1100 text-reds-500 px-0.5 py-0.5 rounded-sm flex justify-center items-center gap-x-1 text-sm font-semibold border border-secondary-1100  shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-reds-500"
                  onClick={() => handleDeleteClick(file)}
                >
                  <DeleteOutline style={{ fontSize: "1rem" }} />
                </button>
              </div>
            </li>
          ))
        ) : (
          <div className="p-4">
            <h2 className="text-md text-secondary-500 italic">
              No {type} present
            </h2>
          </div>
        )}
      </ul>
    </div>
  );
};

export default FilesList;
