import { useEffect, useState } from "react";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import UserInfo from "./UserView";
import { ArrowBack } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { User } from "../../types/User";

const UserPage = () => {
  const userId = useParams().id;
  const [userData, setUserData] = useState<User | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`/api/user/get`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            id: userId,
          },
        });
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchUser();
  }, [userId]);

  return (
    <>
      <PageHeader
        title={
          <button
            type="button"
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
            onClick={() => window.history.back()}
          >
            <ArrowBack style={{ fontSize: "1rem" }} /> Back to Team
          </button>
        }
        subtitle={""}
        buttons={<></>}
      />
      {userData && <UserInfo userData={userData} />}
    </>
  );
};

export default UserPage;
