// AuthContext.tsx
import { createContext, useReducer, ReactNode, Dispatch } from "react";
import AuthReducer, { AuthState, AuthAction, defaultUser } from "./AuthReducer";



// Define initial state type
const INITIAL_STATE: AuthState = {
  currentUser: JSON.parse(
    localStorage.getItem("user") || JSON.stringify(defaultUser)
  ),
  token: localStorage.getItem("token"),
};

// Create the context with proper typing
interface AuthContextType {
  currentUser: NonNullable<AuthState["currentUser"]>;
  token: AuthState["token"];
  dispatch: Dispatch<AuthAction>;
}

// Provide the initial value for the context (can be null initially)
export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

// Define the props type for the provider
interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContextProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  return (
    <AuthContext.Provider
      value={{ currentUser: state.currentUser, token: state.token, dispatch }}
    >
      {children}
    </AuthContext.Provider>
  );
};
