import React, { useState, Fragment, useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Close, Error, Warning } from "@mui/icons-material";
import { AuthContext } from "../../context/AuthContext";
import { FacilityContext } from "../../context/FacilityContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import { User } from "../../types/User";
import { Facility } from "../../types/Facility";

type InputType = {
  id: string;
  label: string;
  type: string;
  placeholder: string;
};

const inputs: InputType[] = [
  {
    id: "firstname",
    label: "First Name",
    type: "text",
    placeholder: "First Name",
  },
  {
    id: "lastname",
    label: "Last Name",
    type: "text",
    placeholder: "Last Name",
  },
  {
    id: "email",
    label: "Email",
    type: "text",
    placeholder: "email@example.com",
  },
  {
    id: "phone",
    label: "Phone",
    type: "tel",
    placeholder: "(123) 456-7890",
  },
];

interface NewUserFormProps {
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  user: User | null;
  type: string;
  facilities: Facility[];
}

interface FormValues {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  access: string;
  isVerified?: boolean;
  joinDate?: Date;
  facility?: string;
}

const NewUserForm: React.FC<NewUserFormProps> = ({
  showPopup,
  setShowPopup,
  refresh,
  setRefresh,
  user,
  type,
  facilities,
}) => {
  const { currentUser } = useAuth();
  const { selectedFacility } = useContext(FacilityContext);
  const isCreatingUser = type === "new";

  const validationSchema = yup.object().shape({
    firstname: yup.string().required("*Required field"),
    lastname: yup.string().required("*Required field"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("*Required field"),
    phone: yup.string().required("*Required field"),
    access: yup.string().required("*Required field"),
    isVerified: yup.boolean(),
    joinDate: yup.date(),
    facility: yup.string(),
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitted, isSubmitting },
    reset,
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstname: user?.firstname ?? "",
      lastname: user?.lastname ?? "",
      email: user?.email ?? "",
      phone: user?.phone ?? "",
      access: user?.access ?? "worker",
      isVerified: user?.isVerified ?? false,
      joinDate: user?.joinDate ?? new Date(),
      facility: selectedFacility,
    },
  });

  const handleAdd = async (data: FormValues) => {
    try {
      if (isCreatingUser) {
        // Creating a new user
        await axios
          .post("/api/user/new", data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            console.log("response", response.data);
            setShowPopup(false);
            reset();
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.log("error", error.response.data.message);
              setError("email", {
                type: "manual",
                message: error.response.data.message,
              });
            }
          });
      } else {
        if (!user) return;
        // Updating an existing user
        await axios
          .put(`/api/user/update/${user._id}`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            console.log("Successfully updated user", response.data);
            setShowPopup(false);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }

      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };

  const data = getValues();

  const formContent = (
    <form
      onSubmit={handleSubmit(handleAdd)}
      className="flex h-full flex-col overflow-y-scroll hide-scrollbar-arrows px-4 sm:px-6"
      noValidate
    >
      <div className="grid grid-cols-2 gap-4">
        {inputs.map((input) => (
          <div key={input.id}>
            <label
              htmlFor={input.id}
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {input.label}
            </label>
            <input
              type={input.type}
              placeholder={input.placeholder}
              className={`block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-300 sm:text-sm sm:leading-6 `}
              {...register(input.id as keyof FormValues)}
            />
            {errors && errors[input.id as keyof FormValues] && (
              <p className="text-reds-500 text-xs">
                {errors[input.id as keyof FormValues]?.message}
              </p>
            )}
          </div>
        ))}
      </div>
      {(currentUser.access === "regional" ||
        currentUser.access === "organization-admin") && (
        <div key={"access"}>
          <label
            htmlFor="project-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Access
          </label>

          <div className="sm:col-span-2">
            <select
              id={"access"}
              className={`block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-300 sm:text-sm sm:leading-6 `}
              {...register("access")}
            >
              <option value={"regional"}>Regional</option>
              <option value={"director"}>Director</option>
              <option value={"worker"}>Worker</option>
              <option value={"administrator"}>Administrator</option>
            </select>
          </div>
        </div>
      )}
      {currentUser.access === "system-admin" && (
        <div key={"access"}>
          <label
            htmlFor="project-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Access
          </label>

          <div className="sm:col-span-2">
            <select
              id={"access"}
              className={`block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-300 sm:text-sm sm:leading-6 `}
              {...register("access")}
            >
              <option value={"worker"}>Worker</option>
              <option value={"director"}>Director</option>
              <option value={"administrator"}>Administrator</option>
              <option value={"regional"}>Regional</option>
              <option value={"organization-admin"}>Organization Admin</option>
              <option value={"system-admin"}>System Admin</option>
            </select>
          </div>
        </div>
      )}
      {currentUser.access === "system-admin" &&
        (data.access === "regional" ||
          data.access === "organization-admin") && (
          <div
            className={`space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5`}
          >
            <label
              htmlFor="project-name"
              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
            >
              Facilities
            </label>
            <div className="sm:col-span-2">
              {facilities.map((facility) => (
                <div
                  key={facility._id}
                  className="relative flex items-start py-1"
                >
                  <div className="min-w-0 flex-1 text-sm leading-6">
                    <label
                      htmlFor={`person-${facility._id}`}
                      className="select-none text-gray-900"
                    >
                      {facility.name}
                    </label>
                  </div>
                  <div className="ml-3 flex h-6 items-center">
                    <input
                      id="facility"
                      value={facility._id}
                      checked={data.facility?.includes(facility._id) ?? false}
                      {...register("facility")}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-secondary-300 focus:ring-secondary-300"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
    </form>
  );

  return (
    <Transition.Root show={showPopup} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setShowPopup}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-secondary-600 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center  ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden text-left shadow-xl transition-all w-full sm:w-1/2 bg-primary rouded-sm">
                <div className="flex h-full flex-col">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="px-4 py-6 sm:px-6 ">
                      <div className="flex items-start justify-between space-x-3 border-b border-secondary-1000">
                        <div className="space-y-1 ">
                          {isCreatingUser ? (
                            <Dialog.Title className="text-base font-semibold leading-6 text-secondary-100">
                              New User
                            </Dialog.Title>
                          ) : (
                            <Dialog.Title className="text-base font-semibold leading-6 text-secondary-100">
                              Update User
                            </Dialog.Title>
                          )}
                          <p className="text-sm text-secondary-400 pb-2">
                            Get started by filling in the information below to
                            create the user.
                          </p>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="relative text-secondary-400 hover:text-secondary-300"
                            onClick={() => setShowPopup(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <Close className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {formContent}
                  </div>

                  {/* Action buttons */}

                  <div className="flex justify-evenly space-x-3 flex-1 p-4">
                    <button
                      type="button"
                      className="w-1/2 rounded-sm bg-secondary-1000 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => setShowPopup(false)}
                    >
                      Cancel
                    </button>
                    {isCreatingUser ? (
                      <button
                        type="submit"
                        className={`w-1/2 inline-flex justify-center rounded-sm ${
                          isSubmitting ? "bg-secondary-700" : "bg-secondary-100"
                        } px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-300`}
                        onClick={handleSubmit(handleAdd)}
                        disabled={isSubmitting}
                      >
                        Create
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className={`w-1/2 inline-flex justify-center rounded-sm ${
                          isSubmitting ? "bg-secondary-700" : "bg-secondary-100"
                        } px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-300`}
                        onClick={handleSubmit(handleAdd)}
                        disabled={isSubmitting}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewUserForm;
