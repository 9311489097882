import React, { Fragment } from "react";
import { DeleteOutline, SearchOffOutlined } from "@mui/icons-material";
import { formatDate, capitalizeString } from "../../utils/FormatFunctions";
import { TaskTemplate } from "../../types/TaskTemplate";

interface TemplateListProps {
  templates: TaskTemplate[];
  handleEditTemplate: (template: TaskTemplate) => void;
}

const TemplateList: React.FC<TemplateListProps> = ({
  templates,
  handleEditTemplate,
}) => {
  return (
    <div className="my-4 sm:my-6 lg:my-8 px-4 sm:px-6 lg:px-8 flex">
      {templates?.length > 0 ? (
        <table className="min-w-full ">
          <thead>
            <tr className="bg-gray-50 border border-gray-200 border-spacing-2">
              <th
                scope="col"
                className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900"
              >
                Title
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Frequency
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Category
              </th>

              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Source
              </th>
              <th
                scope="col"
                className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Details
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody key={"body"} className="bg-white">
            {templates.map((template) => (
              <Fragment key={template._id}>
                <tr key={`${template._id}-gap`} className="h-2"></tr>
                <tr className="hover:bg-gray-50 cursor-pointer border border-gray-200 rounded-sm">
                  <td className="w-full max-w-0 py-4 pl-2 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none ">
                    {capitalizeString(template.title)}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only sm:hidden">Frequency</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        {capitalizeString(template.recurrence.frequency)}
                      </dd>
                      <dt className="sr-only sm:hidden">Type</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        {capitalizeString(template.category)}
                      </dd>
                      <dt className="sr-only sm:hidden">Location</dt>

                      <dt className="sr-only sm:hidden">Urgency</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        {capitalizeString(template.details)}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {capitalizeString(template.recurrence.frequency)}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500  sm:table-cell">
                    {capitalizeString(template.category)}
                  </td>

                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {capitalizeString(template.source)}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500  table-cell">
                    <button
                      onClick={() => handleEditTemplate(template)}
                      className="text-secondary hover:text-accent-200 underline"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="flex flex-col w-full justify-center items-center bg-gray-100 text-gray-500 py-8">
          <SearchOffOutlined style={{ fontSize: "2rem" }} />
          <span className="text-sm">No items present</span>
        </div>
      )}
    </div>
  );
};

export default TemplateList;
