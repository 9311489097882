import React, { Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Close } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FacilityContext } from "../../context/FacilityContext";
import { Kiosk } from "../../types/Kiosk";

interface CreateKioskFormProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  kiosk: Kiosk | null;
}

interface FormData {
  _id?: string | null;
  location: string;
  name: string;
  pin: string;
  confirmPin: string;
  facility: string;
}

const CreateKioskForm: React.FC<CreateKioskFormProps> = ({
  show,
  setShow,
  refresh,
  setRefresh,
  kiosk,
}) => {
  const { selectedFacility } = useContext(FacilityContext);

  const validationSchema = yup.object().shape({
    _id: yup.string().nullable(),
    location: yup.string().required("Location is required"),
    name: yup.string().required("Name is required"),
    pin: yup.string().required("PIN is required"),
    confirmPin: yup
      .string()
      .oneOf([yup.ref("pin"), undefined], "Pins must match")
      .required("Confirm PIN is required"),
    facility: yup.string().required("Facility is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      _id: kiosk?._id ?? null,
      location: kiosk?.location ?? "",
      name: kiosk?.name ?? "",
      pin: kiosk?.pin ?? "",
      confirmPin: "",
      facility: selectedFacility,
    },
  });

  useEffect(() => {
    if (kiosk) {
      setValue("_id", kiosk._id);
      setValue("location", kiosk.location);
      setValue("name", kiosk.name);
      setValue("pin", kiosk.pin);
      setValue("facility", selectedFacility);
    }
  }, [kiosk, setValue, selectedFacility]);

  const onSubmit = async (data: FormData) => {
    try {
      // If there is a kiosk id, update the kiosk
      let routeParam: string = "new";
      if (kiosk?._id) {
        routeParam = "edit";
      }

      const token = localStorage.getItem("token");
      const response = await axios
        .post(`/api/building/${routeParam}/kiosk`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("response", response.data);

          reset();
          setShow(false);
          setRefresh(!refresh);
        })
        .catch((error) => {
          setError("name", { message: error.response.data.message });
        });
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        console.error("Error creating kiosk:", error.response?.data?.message);
      } else {
        console.error("Error creating kiosk:", error);
      }
    }
  };

  const content = (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <div className="w-full px-2 mb-2">
          <div className="mt-1">
            <label className="mt-2 block text-sm font-medium text-secondary-100">
              Kiosk Location
            </label>
            <input
              type="text"
              className="block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-200 sm:text-sm sm:leading-6"
              {...register("location")}
            />
            <p className="text-xs text-reds-500">{errors.location?.message}</p>
          </div>
          <label className="mt-2 block text-sm font-medium text-secondary-100">
            Kiosk Name
          </label>
          <div className="mt-1">
            <input
              type="text"
              className="block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-200 sm:text-sm sm:leading-6"
              {...register("name")}
            />
            <p className="text-xs text-reds-500">{errors.name?.message}</p>
          </div>
          <div className="flex w-full gap-2 mt-2 ">
            <div className="mt-1 w-1/2">
              <label className="block text-sm font-medium text-secondary-100">
                PIN
              </label>
              <input
                type="text"
                className="block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-200 sm:text-sm sm:leading-6"
                {...register("pin")}
              />
              <p className="text-xs text-reds-500">{errors.pin?.message}</p>
            </div>
            <div className="mt-1 w-1/2">
              <label className="block text-sm font-medium text-secondary-100">
                Confirm Pin
              </label>
              <input
                type="text"
                className="block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-200 sm:text-sm sm:leading-6"
                {...register("confirmPin")}
              />
              <p className="text-xs text-reds-500">
                {errors.confirmPin?.message}
              </p>
            </div>
          </div>
        </div>
        {/* Display the form error */}
        <div className="mt-6 flex flex-col sm:flex-row gap-2 ">
          <button
            className="flex flex-1 mt-4 w-full justify-center rounded-sm bg-secondary-1000 px-3 py-2 text-sm font-semibold text-secondary-100 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-200"
            onClick={() => setShow(false)}
          >
            Cancel
          </button>

          <button
            className="flex flex-1 mt-4 w-full justify-center rounded-sm bg-secondary-100 px-3 py-2 text-sm font-semibold text-primary shadow-sm hover:bg-secondary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-200"
            type="submit"
          >
            {kiosk?._id ? "Create" : "Update"} Kiosk
          </button>
        </div>
      </form>
    </div>
  );

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setShow(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-secondary-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-sm bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                <div className="flex h-full flex-col overflow-auto bg-white ">
                  <div className="px-4 sm:px-6 border-b border-gray-200">
                    <div className="flex items-start justify-between ">
                      <Dialog.Title className="text-base font-semibold leading-6 text-secondary mb-4">
                        {kiosk?._id ? "Create" : "Update"} Kiosk
                      </Dialog.Title>
                      <div className="">
                        <button
                          type="button"
                          className="justify-center items-center relative rounded-sm bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                          onClick={() => setShow(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <Close
                            style={{ fontSize: "1rem" }}
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-2">{content}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateKioskForm;
