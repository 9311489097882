import React, { useEffect, useState, Fragment, useContext } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { capitalizeString } from "../../utils/FormatFunctions";
import { Dialog, Transition } from "@headlessui/react";
import { Close } from "@mui/icons-material";
import { TaskTemplate } from "../../types/TaskTemplate";
import { Map } from "../../types/Map";
import { FacilityContext } from "../../context/FacilityContext";

interface OnboardingModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: boolean;
}

type FieldValues = {
  facility: string | null;
  selectedTemplates?: {
    title: string;
    selectedTemplate: string;
    startDate: string;
    map?: string;
  }[];
};

const OnboardingModal: React.FC<OnboardingModalProps> = ({
  show,
  setShow,
  setRefresh,
  refresh,
}) => {
  const { selectedFacility } = useContext(FacilityContext);
  const [templates, setTemplates] = useState<TaskTemplate[]>([]);
  const [maps, setMaps] = useState<Map[]>([]);

  const schema = yup.object().shape({
    facility: yup.string().nullable().defined(),
    selectedTemplates: yup
      .array()
      .of(
        yup.object().shape({
          title: yup.string().required(),
          selectedTemplate: yup.string().required("Template ID is required"),
          startDate: yup
            .string()
            .required("Start date is required")
            .matches(
              /^\d{4}-\d{2}-\d{2}$/,
              "Start date must be in the format YYYY-MM-DD"
            ),
          map: yup.string(),
        })
      )
      .min(1, "At least one task must be selected"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      facility: selectedFacility ?? null,
      selectedTemplates: [],
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchTemplates = async () => {
      try {
        const response = await axios.get("/api/task-scheduler/templates/list", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTemplates(response.data);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };

    const fetchMaps = async () => {
      try {
        const response = await axios.get(
          `/api/task-scheduler/maps/list?facility=${selectedFacility}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setMaps(response.data);
      } catch (error) {
        console.error("Error fetching maps:", error);
      }
    };
    if (selectedFacility) {
      fetchTemplates();
      fetchMaps();
    }

    setValue("facility", selectedFacility);
  }, [selectedFacility]);

  const selectedTemplates = watch("selectedTemplates");

  const handleCheckboxChange = (
    templateId: string,
    index: number,
    isChecked: boolean
  ) => {
    const newSelectedTemplates = [...(getValues("selectedTemplates") || [])];

    if (isChecked) {
      newSelectedTemplates[index] = {
        title: "",
        selectedTemplate: templateId,
        startDate: "",
        map: "",
      };
    } else {
      newSelectedTemplates.splice(index, 1);
    }

    setValue("selectedTemplates", newSelectedTemplates);
  };

  const onSubmit = async (data: FieldValues) => {
    const token = localStorage.getItem("token");
    try {
      await axios.post("/api/task-scheduler/onboard", data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setShow(false);
      setRefresh(!refresh);
    } catch (error) {
      console.error("Error creating tasks:", error);
    }
  };

  const form = (
    <form
      className="max-h-screen overflow-y-auto mt-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <table className="min-w-full divide-y divide-secondary-1000 border-b border-secondary-1000">
        <thead className="bg-secondary-1100 sticky z-10 top-0">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-secondary-500 uppercase tracking-wider"
            >
              Select
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-secondary-500 uppercase tracking-wider"
            >
              Task Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-secondary-500 uppercase tracking-wider"
            >
              Task Description
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-secondary-500 uppercase tracking-wider"
            >
              Start Date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-secondary-500 uppercase tracking-wider"
            >
              Maps
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-secondary-1000 ">
          {templates.map((template, index) => {
            // If the template._id is in the selectedTemplates.selctedTemplate array, it is selected
            const isRowSelected = (selectedTemplates ?? []).some(
              (selectedTemplate) =>
                selectedTemplate?.selectedTemplate === template._id
            );

            return (
              <tr key={template._id + isRowSelected}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="checkbox"
                    className="rounded-sm outline-none text-accent-500 focus:outline-accent-500"
                    checked={isRowSelected}
                    onChange={(e) =>
                      handleCheckboxChange(
                        template._id,
                        index,
                        e.target.checked
                      )
                    }
                  />
                </td>
                <td className="px-6 py-4 text-sm">
                  {!isRowSelected ? (
                    <p className="text-xs px-2 text-secondary-200 ">
                      {capitalizeString(template.title)}
                    </p>
                  ) : (
                    <input
                      type="text"
                      className="rounded-sm text-xs px-2 text-secondary-200 outline-none border-none focus:ring-accent-500"
                      {...register(`selectedTemplates.${index}.title`)}
                      defaultValue={template.title}
                    />
                  )}
                </td>
                <td className="px-6 py-4 flex text-wrap max-w-96">
                  <label className="text-xs text-secondary-400">
                    {template.details}
                  </label>
                </td>
                {isRowSelected && (
                  <>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="date"
                        className="rounded-sm text-xs px-2 text-secondary-200 outline-none border-none focus:ring-accent-500"
                        {...register(`selectedTemplates.${index}.startDate`)} // Adjusted to use array index
                      />
                      {errors?.selectedTemplates?.[index]?.startDate && (
                        <p className="text-xs text-reds-500">
                          {errors.selectedTemplates[index]?.startDate
                            ?.message ?? ""}
                        </p>
                      )}
                    </td>
                    <td>
                      <select
                        {...register(`selectedTemplates.${index}.map`)} // Adjusted to use array index
                        className="rounded-sm text-xs text-secondary-200 outline-none border-none focus:ring-accent-500"
                      >
                        <option value="">No Map</option>
                        {maps.map((map) => (
                          <option key={map._id} value={map._id}>
                            {map.name}
                          </option>
                        ))}
                      </select>
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="flex justify-end w-full gap-2 border-t border-secondary-1000 py-2 mt-4 ">
        <button
          className="bg-secondary-800 rounded-sm px-6 py-1 text-primary"
          type="button"
          onClick={() => setValue("selectedTemplates", [])}
        >
          Reset
        </button>
        <button
          className="bg-secondary-100 rounded-sm px-4 py-1 text-primary"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  );
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setShow(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-secondary-100 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-sm bg-primary pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 max-h-full">
                <div className="flex h-full flex-col bg-primary ">
                  <div className="px-4 sm:px-6 border-b border-secondary-1000">
                    <div className="flex items-start justify-between ">
                      <Dialog.Title className="text-base font-medium leading-6 text-secondary-100 mb-4">
                        Onboarding
                      </Dialog.Title>
                      <div className="">
                        <button
                          type="button"
                          className="justify-center items-center relative rounded-sm bg-primary text-secondary-700 hover:text-secondary-500 focus:outline-none "
                          onClick={() => setShow(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <Close
                            style={{ fontSize: "1rem" }}
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-6">{form}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default OnboardingModal;
