import {
  BuildOutlined,
  CalendarMonthOutlined,
  Diversity1Outlined,
  HandymanTwoTone,
  InsightsTwoTone,
  Menu,
  PlumbingOutlined,
  SecurityTwoTone,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../utils/FormatFunctions";
import { Switch } from "@headlessui/react";
import { FormState, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import Button from "../../updatedcomponents/general/Button";

const LandingPage = () => {
  const [currentSection, setCurrentSection] = useState(1);

  const features = [
    {
      icon: HandymanTwoTone,
      title: "The tools you need",
      description: `Sequra possesses all of the tools you need to ensure your facility is compliant, safe, and efficiently run. With intuitive reporting, expretly curated task templates, and advanced notification systems, Sequra's tools are a must for any comprehensive maintenance program.`,
      buttonText: "Learn More",
    },
    {
      icon: InsightsTwoTone,
      title: "In Depth Analytics",
      description:
        "In depth analytics provide meaningful insights regarding your facility's standing as well as help monitor employees performance. Sequra's analytics are designed to help you make informed decisions regarding your facility's maintenance and budgeting.",
      buttonText: "Learn More",
    },
    {
      icon: SecurityTwoTone,
      title: "Expertly Constructed",
      description:
        "Built by industry experts, Sequra is designed to meet the real needs of your facility to ensure compliance with CMS and NFPA regulations. Sequra has done the research so you don't have to.",
      buttonText: "Learn More",
    },
  ];

  const featuredTestimonial = {
    body: "Using Sequra has saved me countless hours of work. I can now easily track all of my facilities needs and ensure that my facility is always up to code.",
    author: {
      name: "Neftali Anadon",
      title: "Maintenance Director",
      logoUrl:
        "https://throgsneckrehab.com/wp-content/uploads/2020/11/logo.png",
    },
  };

  const testimonials = [
    [
      [
        {
          body: "Sequra has helped me streamline my facility's maintenance program. I can now easily track all of my facilities needs and ensure that my facilities are being effectively run.",
          author: {
            name: "Carrie Hartley",
            title: "Regional Director",
          },
        },
        {
          body: "I now have a clear understand of what my bosses expect from me and can prove the work I am doing.",
          author: {
            name: "Anthony Woods",
            title: "Maintenance Worker",
          },
        },
      ],
      [
        {
          body: "Managing my maintenance team has never been easier. I can now assign tasks, track progress, and track my employees with ease.",
          author: {
            name: "Ray Porter",
            title: "Regional Director",
          },
        },
      ],
    ],
    [
      [
        {
          body: "Sequra's analytics help me make informed decisions regarding my facility's maintenance and budgeting.",
          author: {
            name: "Daniel Sandoval",
            title: "Operator",
          },
        },
      ],
      [
        {
          body: "Because of Sequra, I can now face survey with confidence.",
          author: {
            name: "Neil Richards",
            title: "Regional Director",
          },
        },
        {
          body: "Because of Sequra, I spend less time running the floors and sorting paper work, allowing me to focus on the things that matter.",
          author: {
            name: "James Ferrer",
            title: "Regional Director",
          },
        },
      ],
    ],
  ];

  type RegisterName =
    | "firstName"
    | "lastName"
    | "company"
    | "email"
    | "phoneNumber"
    | "message";

  interface InputFields {
    id: string;
    label: string;
    type: string;
    autoComplete?: string;
    registerName: RegisterName;
    colSpan?: string;
    rows?: number;
  }

  const inputFields: InputFields[] = [
    {
      id: "first-name",
      label: "First name",
      type: "text",
      autoComplete: "given-name",
      registerName: "firstName",
    },
    {
      id: "last-name",
      label: "Last name",
      type: "text",
      autoComplete: "family-name",
      registerName: "lastName",
    },
    {
      id: "company",
      label: "Company",
      type: "text",
      autoComplete: "organization",
      registerName: "company",
      colSpan: "sm:col-span-2",
    },
    {
      id: "email",
      label: "Email",
      type: "email",
      autoComplete: "email",
      registerName: "email",
      colSpan: "sm:col-span-2",
    },
    {
      id: "phone-number",
      label: "Phone number",
      type: "tel",
      autoComplete: "tel",
      registerName: "phoneNumber",
      colSpan: "sm:col-span-2",
    },
    {
      id: "message",
      label: "Message",
      type: "textarea",
      rows: 4,
      registerName: "message",
      colSpan: "sm:col-span-2",
    },
  ];

  const requestSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    company: yup.string().required("Company is required"),
    email: yup.string().email().required("Email is required"),
    phoneNumber: yup.string().optional(),
    message: yup.string().required("Message is required"),
    termsAccepted: yup.boolean().oneOf([true], "You must accept the terms"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(requestSchema),
    defaultValues: {
      termsAccepted: false,
    },
  });

  const handleAcceptTerms = () => {
    setValue("termsAccepted", !termsAccepted);
  };

  const termsAccepted = watch("termsAccepted");

  const onSubmit = async (data: any) => {
    try {
      await axios.post("/api/landingPageHandler/request", data);
    } catch (error) {
      console.error(error);
    }
  };

  const onRequestDoc = async (link: string) => {

    await axios
      .get("/api/landingPageHandler/legaldoc", {
        params: { link },
      })
      .then((response) => {
        console.log(response.data);
        window.open(response.data, "_blank");
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      });
  };

  const footerNavigation = {
    contact: [
      { name: "Email", value: "info@sequra.ai" },
      { name: "Phone", value: "+1 516-232-4760" },
      { name: "Address", value: "961 Broadway, Woodmere, NY 11598" },
    ],
    legal: [
      {
        name: "Claim",
        link: "https://sequraadmindocs.s3.us-east-1.amazonaws.com/Sequra+Claim.pdf",
      },
      {
        name: "Privacy",
        link: "https://sequraadmindocs.s3.us-east-1.amazonaws.com/Sequra+Privacy+Policy.pdf",
      },
      {
        name: "Terms",
        link: "https://sequraadmindocs.s3.us-east-1.amazonaws.com/Sequra+Terms.pdf",
      },
    ],
    social: [
      {
        name: "LinkedIn",
        href: "https://linkedin.com/company/sequra-ai",
        icon: (props: React.SVGProps<SVGSVGElement>) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
    ],
  };

  const navigate = useNavigate();

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="relative h-screen flex flex-col">
      <header className="sticky z-30 top-0 shadow-lg">
        <div className=" bg-secondary-200 flex w-full justify-between px-4 sm:px-10 py-5 border border-b border-secondary-300 shadow">
          <img
            src="/sequra_logo__primary_white.png"
            onClick={() => scrollToSection("hero")}
            className="h-9 w-auto"
          />

          <ul className="flex items-center gap-9 text-primary">
            <li
              className="hidden sm:flex cursor-pointer hover:underline"
              onClick={() => scrollToSection("features")}
            >
              Features
            </li>
            <li
              className="hidden sm:flex cursor-pointer hover:underline"
              onClick={() => scrollToSection("testimonials")}
            >
              Testimonials
            </li>
            <li
              className="hidden sm:flex cursor-pointer hover:underline"
              onClick={() => scrollToSection("contact")}
            >
              Demo Request
            </li>
            <Button
              styleString="bold"
              onClick={() => navigate("/login")}
              children="Login"
            />
          </ul>
        </div>
      </header>

      {/* Hero Section */}
      <section
        id="hero"
        className=" mx-auto w-full bg-secondary-1200  px-10 py-16"
      >
        <div className="flex max-w-7xl mx-auto sm:flex-row flex-col justify-between">
          <div className="sm:w-2/5 w-full flex flex-col sm:gap-10 gap-2">
            <h1 className="font-header text-4xl lg:text-7xl lg:leading-[90px] text-secondary-100 font-medium">
              Facility Management, Modernized
            </h1>
            <span className="font-light">
              Sequra is a cloud-based facility management software that helps
              facilities maintain the highest standards of compliance, safety,
              and efficiency. Our software is designed to streamline
              preventative maintenance, life safety, and work order management
              for any facility. Easily find reports, manage your facilities
              needs, and keep your facility on track with Sequra.
            </span>
            <button
              onClick={() => scrollToSection("contact")}
              className="bg-accent-500 rounded px-4 mt-6 py-2 text-primary text-md leading-6 hover:bg-accent-600 flex w-full justify-center sm:w-fit"
            >
              Request A Demo
            </button>
          </div>
          <div className="hidden sm:flex sm:w-1/2 items-center">
            <img src="/landing/devices.png" className="w-full h-fit" />
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section
        id="features"
        className="relative bg-secondary-1100 sm:py-20 flex flex-col gap-8"
      >
        <img
          src="/landing/work-orders.png"
          className="absolute inset-0 w-full h-full object-cover blur-xl"
          alt="SVG Clipping"
        />
        <div className="z-20 flex flex-col h-full gap-4">
          <h2 className="text-center font-header text-3xl py-4 sm:text-5xl font-medium leading-10">
            Command Your Facility's Maintenance
          </h2>
          <div className="flex flex-col mx-auto px-10 max-w-7xl sm:flex-row  w-full gap-8">
            {features.map((section, index) => (
              <div
                key={index}
                className="flex flex-col rounded bg-white shadow-lg ring-1 ring-secondary-100/5 flex-1 h-full justify-between border border-secondary-1000  p-6 gap-5"
              >
                <div className="h-36 w-36 text-7xl flex items-center justify-center rounded-full bg-secondary-1100 text-secondary-100 mx-auto">
                  <section.icon fontSize="inherit" color="inherit" />
                </div>
                <div className="gap-3">
                  <h3 className="text-center text-secondary-100 hover:secondary-200 font-semibold leading-6 text-lg">
                    {section.title}
                  </h3>
                  <p className="text-justify leading-6">
                    {section.description}
                  </p>
                </div>
                <button
                  onClick={() => scrollToSection("contact")}
                  className="bg-secondary-100 text-primary border-2 rounded px-4 py-2 h-fit font-medium"
                >
                  {section.buttonText}
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Feature example section */}
      <section className="bg-primary w-full  px-10 py-20 flex flex-col gap-8">
        <div className="max-w-7xl mx-auto">
          <div className="max-w-[900px] gap-4 flex flex-col">
            <h1 className="font-header font-medium text-3xl sm:text-5xl">
              A Management Powerwhouse
            </h1>
            <p className="text-lg font-light leading-6">
              Simple, flexible, and powerful. Easily track tasks, staff
              assignments, and maintenance activities to get a clear view of
              who’s doing what and what needs to be done.
            </p>
          </div>
          <div className="flex sm:flex-row flex-col-reverse w-full justify-between gap-8">
            <ul className="flex flex-col gap-5 sm:w-1/3 w-full">
              <li
                className={`cursor-pointer rounded border-2 p-4 gap-2 flex flex-col ${
                  currentSection === 1
                    ? "border-accent-500 bg-secondary-1100"
                    : "border-transparent bg-primary"
                }`}
                onClick={() => setCurrentSection(1)}
              >
                <span className="flex items-center gap-2 font-semibold leading-6">
                  <BuildOutlined style={{ fontSize: "1.2rem" }} />
                  Work Orders
                </span>
                <p>
                  Efficiently prioritize, assign, and track work orders from
                  anywhere.
                </p>
              </li>
              <li
                className={`cursor-pointer rounded border-2 p-4 gap-2 flex flex-col ${
                  currentSection === 2
                    ? "border-accent-500 bg-secondary-1100"
                    : "border-transparent bg-primary"
                }`}
                onClick={() => setCurrentSection(2)}
              >
                <span className="flex items-center gap-2 font-semibold leading-6">
                  <CalendarMonthOutlined style={{ fontSize: "1.2rem" }} />
                  Task Management
                </span>
                <p>
                  Track all of your recurring tasks and ensure that they are
                  being completed on time. No more scrambling for paperwork or
                  missing deadlines.
                </p>
              </li>
              <li
                className={`cursor-pointer rounded border-2 p-4 gap-2 flex flex-col ${
                  currentSection === 3
                    ? "border-accent-500 bg-secondary-1100"
                    : "border-transparent bg-primary"
                }`}
                onClick={() => setCurrentSection(3)}
              >
                <span className="flex items-center gap-2 font-semibold leading-6">
                  <Diversity1Outlined style={{ fontSize: "1.2rem" }} />
                  Advanced Analytics
                </span>
                <p>
                  Track every aspect of your facility from employee performance
                  to regulatory compliance. Sequra's analytics enable you to
                  make better educated decisions.
                </p>
              </li>
            </ul>
            <div className="flex-1 justify-center items-center sm:flex">
              {currentSection === 1 && (
                <div className="bg-secondary-1100 p-2 sm:p-6 rounded border-2 border-secondary-1000">
                  <img src={"/landing/work-orders.png"} alt="Work Order" />
                </div>
              )}
              {currentSection === 2 && (
                <div className="bg-secondary-1100 p-2 sm:p-6 rounded border-2 border-secondary-1000">
                  <img
                    src={"/landing/recurring-tasks.png"}
                    alt="recurring tasks image"
                  />
                </div>
              )}
              {currentSection === 3 && (
                <div className="bg-secondary-1100 p-2 sm:p-6 rounded border-2 border-secondary-1000">
                  <img
                    src={"/landing/facility-analytics.png"}
                    alt="analytics image"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* Testimonials Section */}
      <section
        id="testimonials"
        className="relative -z-20 py-20 bg-secondary-1100 gap-8 flex flex-col "
      >
        <div className="fixed  -bottom-60 right-0 -z-10">
          <img
            src="/sequra_pattern.svg"
            className="scale-[3]"
            alt="SVG Clipping"
          />
        </div>
        <div className=" mx-auto max-w-7xl px-6 lg:px-8 z-20">
          <div className="mx-auto max-w-xl text-center">
            <h2 className="text-xl font-semibold font-header leading-8 tracking-tight text-accent-500">
              Testimonials
            </h2>
            <h2 className="text-center font-header text-3xl sm:text-5xl font-medium leading-10">
              Hear From Our Happy Customers
            </h2>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-secondary-100 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
            <figure className="rounded bg-white shadow-lg ring-1 ring-secondary-100/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
              <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight text-secondary-100 sm:p-12 sm:text-xl sm:leading-8">
                <p>{`“${featuredTestimonial.body}”`}</p>
              </blockquote>
              <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-secondary-100/10 px-6 py-4 sm:flex-nowrap">
                <div className="flex-auto">
                  <div className="font-semibold">
                    {featuredTestimonial.author.name}
                  </div>
                  <div className="text-secondary-500">
                    {featuredTestimonial.author.title}
                  </div>
                </div>
                <img
                  alt=""
                  src={featuredTestimonial.author.logoUrl}
                  className="h-10 w-auto flex-none"
                />
              </figcaption>
            </figure>
            {testimonials.map((columnGroup, columnGroupIdx) => (
              <div
                key={columnGroupIdx}
                className="space-y-8 xl:contents xl:space-y-0"
              >
                {columnGroup.map((column, columnIdx) => (
                  <div
                    key={columnIdx}
                    className={classNames(
                      (columnGroupIdx === 0 && columnIdx === 0) ||
                        (columnGroupIdx === testimonials.length - 1 &&
                          columnIdx === columnGroup.length - 1)
                        ? "xl:row-span-2"
                        : "xl:row-start-1",
                      "space-y-8"
                    )}
                  >
                    {column.map((testimonial) => (
                      <figure
                        key={testimonial.author.name}
                        className="rounded bg-white p-6 shadow-lg ring-1 ring-secondary-200/5"
                      >
                        <blockquote className="text-secondary-100">
                          <p>{`“${testimonial.body}”`}</p>
                        </blockquote>
                        <figcaption className="mt-6 flex items-center gap-x-4">
                          <div>
                            <div className="font-semibold">
                              {testimonial.author.name}
                            </div>
                            <div className="text-secondary-500">
                              {testimonial.author.title}
                            </div>
                          </div>
                        </figcaption>
                      </figure>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Request Section */}
      <section id="contact">
        <div className="isolate px-6 py-20 bg-primary  lg:px-8">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-3xl font-header font-bold tracking-tight text-secondary-100 sm:text-4xl">
              Request a Demo
            </h2>
            <p className="mt-2 text-lg leading-8 text-secondary-500">
              Please enter your information below and we will reach out to you
              to schedule a demo.
            </p>
          </div>

          <form
            className="mx-auto mt-8 max-w-2xl"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              {inputFields.map((field) => (
                <div key={field.id} className={field.colSpan}>
                  <label
                    htmlFor={field.id}
                    className="block text-sm font-semibold text-secondary-500"
                  >
                    {field.label}
                  </label>
                  {field.type === "textarea" ? (
                    <textarea
                      id={field.id}
                      {...register(field.registerName)}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-secondary-100 shadow-sm ring-1 ring-inset ring-secondary-1000 placeholder:text-secondary-700 disabled:text-secondary-600 disabled:cursor-not-allowed focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6"
                      disabled={isSubmitting || isSubmitSuccessful}
                    />
                  ) : (
                    <input
                      id={field.id}
                      type={field.type}
                      {...register(field.registerName)}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-secondary-100 shadow-sm ring-1 ring-inset ring-secondary-1000 placeholder:text-secondary-700 disabled:text-secondary-600 disabled:cursor-not-allowed focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6"
                      disabled={isSubmitting || isSubmitSuccessful}
                    />
                  )}
                  {errors[field.registerName] && (
                    <span className="text-reds-500 text-sm">
                      {errors[field.registerName]?.message}
                    </span>
                  )}
                </div>
              ))}
              <div className="flex gap-x-4 sm:col-span-2">
                <div className="flex h-6 items-center">
                  <Switch
                    checked={termsAccepted}
                    onChange={handleAcceptTerms}
                    className={`group flex w-8 flex-none cursor-pointer ui-checked:bg-accent-500 ui-not-checked:bg-secondary-1000 rounded-full p-px ring-1 ring-inset ring-secondary-100/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500`}
                  >
                    <span className="sr-only">Agree to policies</span>
                    <span
                      aria-hidden="true"
                      className="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-secondary-100/5 transition duration-200 ease-in-out ui-checked:translate-x-3.5"
                    />
                  </Switch>
                </div>
                <label className="text-sm leading-6 text-secondary-500">
                  By selecting this, you agree to our{" "}
                  <button type="button" onClick={() => onRequestDoc("https://sequraadmindocs.s3.us-east-1.amazonaws.com/Sequra+Privacy+Policy.pdf")} className="font-semibold text-accent-500">
                    privacy&nbsp;policy
                  </button>
                  .
                </label>
              </div>
              {errors.termsAccepted && (
                <span className="text-reds-500 text-sm">
                  {errors.termsAccepted?.message}
                </span>
              )}
            </div>
            <div className="mt-10">
              <button
                type="submit"
                disabled={isSubmitting || isSubmitSuccessful}
                className="cursor:pointer disabled:cursor-not-allowed block w-full rounded-md bg-accent-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-accent-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500 disabled:bg-accent-500/50"
              >
                {isSubmitting
                  ? "Submitting..."
                  : isSubmitSuccessful
                  ? "Thank you for your submission!"
                  : "Let's Talk"}
              </button>
            </div>
          </form>
        </div>
      </section>

      {/* Footer */}
      <footer aria-labelledby="footer-heading" className="bg-secondary-100">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto px-6 pb-8 pt-16 sm:pt-24 lg:px-8 xl:px-32 lg:pt-32">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <img
              alt="Sequra"
              src="/sequra_logo__icon_mint.png"
              className="h-14"
            />
            <div className="mt-16 grid grid-cols-3 gap-8 xl:col-span-2 xl:mt-0">
              <div className="col-span-2">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Contact
                </h3>
                <ul role="list" className="grid grid-cols-1 ">
                  {footerNavigation.contact.map((item, index) => (
                    <li
                      key={index}
                      className="grid grid-cols-[150px_1fr] items-start"
                    >
                      <span className="text-sm text-primary mt-4">
                        {item.name}:
                      </span>
                      <span className="mt-4 text-sm text-secondary-900">
                        {item.value}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className=" w-fit ml-auto">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Legal
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <button
                        type="button"
                        onClick={() => onRequestDoc(item.link)}
                        className="text-sm leading-6 text-secondary-1000 hover:text-white"
                      >
                        {item.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 border-t border-primary/40 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {footerNavigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-primary hover:text-secondary-1000"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon aria-hidden="true" className="h-6 w-6" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-xs leading-5 text-secondary-1000 md:order-1 md:mt-0">
              &copy; {new Date().getFullYear()} SEQURA.AI All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
