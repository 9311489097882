import React, { useState, useEffect, useContext } from "react";
import { FacilityContext } from "../../context/FacilityContext";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { Add } from "@mui/icons-material";
import CreateRecurring from "../../updatedcomponents/forms/CreateRecurring";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import RecurringTasks from "../../updatedcomponents/tables/RecurringTaskList";
import ActionResponse from "../../updatedcomponents/modals/ActionResponse";
import { useNavigate } from "react-router-dom";
import OnboardingModal from "../../updatedcomponents/forms/Onboarding";
import { RecurringTask } from "../../types/RecurringTask";
import { useAuth } from "../../hooks/useAuth";
import Button from "../../updatedcomponents/general/Button";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

interface Response {
  show: boolean;
  title: string;
  body: string;
  icon: React.ReactNode | null;
}

const TaskScheduler: React.FC = () => {
  const navigate = useNavigate();
  const { selectedFacility } = useContext(FacilityContext);
  const { currentUser } = useAuth();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [recurringTasks, setRecurringTasks] = useState<RecurringTask[]>([]);
  const [prevMaint, setPrevMaint] = useState<RecurringTask[]>([]);
  const [administrative, setAdministrative] = useState<RecurringTask[]>([]);

  const [showOnboarding, setShowOnboarding] = useState<boolean>(false);
  const [showOnboardingModal, setShowOnboardingModal] =
    useState<boolean>(false);

  const permissions = currentUser.access;

  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    if (selectedFacility) {
      const fetchData = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `/api/log/list/${selectedFacility}`,
            {
              params: {
                searchTerm: searchTerm,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.data.length === 0) {
            setShowOnboarding(true);
          } else {
            setShowOnboarding(false);
          }

          const categorizedData = categorizeDataByType(response.data);
          setRecurringTasks(categorizedData.lifeSafetyData);
          setPrevMaint(categorizedData.prevMaintData);
          setAdministrative(categorizedData.administrativeData);
        } catch (error) {
          console.error("Error fetching log:", error);
        }
      };
      fetchData();
    }
  }, [selectedFacility, refresh, searchTerm]);

  function categorizeDataByType(data: RecurringTask[]) {
    const categorizedData = {
      lifeSafetyData: [] as RecurringTask[],
      prevMaintData: [] as RecurringTask[],
      administrativeData: [] as RecurringTask[],
    };

    data.forEach((item) => {
      if (item.taskType === "life-safety") {
        categorizedData.lifeSafetyData.push(item);
      } else if (item.taskType === "prev-maint") {
        categorizedData.prevMaintData.push(item);
      } else if (item.taskType === "administrative") {
        categorizedData.administrativeData.push(item);
      }
    });

    return categorizedData;
  }

  const handleAddLog = () => {
    setShowPopup(true);
  };

  const [response, setResponse] = useState<Response>({
    show: false,
    title: "",
    body: "",
    icon: null,
  });

  const handleOnboarding = () => {
    setShowOnboardingModal(true);
  };

  /*   <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly mx-6 sm:gap-4 gap-2">
  <button
    type="button"
    className="flex items-center justify-center gap-2 rounded-sm bg-secondary-100 px-4 py-1 text-center text-sm font-semibold text-primary border-transparent border shadow-sm hover:bg-secondary-200"
    onClick={handleAddLog}
  >
    <Add style={{ fontSize: "1rem" }} />
    <span>Schedule Recurring Task</span>
  </button>
</div> */
  const content = (
    <div>
      <PageHeader
        title="Task Scheduler"
        buttons={
          (permissions === "regional" ||
            permissions === "organization-admin") && (
            <Button
              styleString="primary"
              icon="Add"
              onClick={handleAddLog}
              children={"Schedule Recurring Task"}
            />
          )
        }
      />
      <ActionResponse response={response} setResponse={setResponse} />
      {!showOnboarding ? (
        <div className="flex flex-col gap-y-4">
          <div className="relative shadow-sm mx-2 sm:mx-4 md:mx-8 mt-8">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
            </div>
            <input
              id="task-search-input"
              type="text"
              placeholder="Search for a task..."
              className="block w-full rounded-sm border-0 py-1.5 pl-10 text-secondary-100 ring-1 ring-inset ring-secondary-1000 placeholder:text-secondary-800 focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <RecurringTasks
            permissions={permissions}
            recurringTasks={recurringTasks}
            type={"life-safety"}
            refresh={refresh}
            setRefresh={setRefresh}
            setResponse={setResponse}
          />

          <RecurringTasks
            permissions={permissions}
            recurringTasks={prevMaint}
            type={"prev-maint"}
            refresh={refresh}
            setRefresh={setRefresh}
            setResponse={setResponse}
          />
        </div>
      ) : (
        <div className="px-4 overflow-auto border border-gray-200 rounded-sm mx-2 sm:mx-4 md:mx-8 mt-8 flex flex-col justify-center items-center h-48 gap-4">
          <span className="max-w-96 text-center text-sm text-secondary-300">
            <p>
              Look like you dont have any tasks scheduled yet. Click the button
              below to begin creating tasks!
            </p>
          </span>
          <button
            onClick={() => handleOnboarding()}
            className="bg-accent-500 hover:bg-accent-400 hover:shadow text-primary px-3 py-1.5 rounded-sm"
          >
            Begin Onboarding
          </button>
        </div>
      )}
      <CreateRecurring
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        refresh={refresh}
        setRefresh={setRefresh}
        existingTask={null}
        setExistingTask={null}
        setResponse={setResponse}
      />
      <OnboardingModal
        show={showOnboardingModal}
        setShow={setShowOnboardingModal}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </div>
  );
  return <div>{content}</div>;
};

export default TaskScheduler;
