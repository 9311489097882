import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Close } from "@mui/icons-material";
import { Facility } from "../../types/Facility";

type InputType = {
  id: string;
  label: string;
  type: string;
  placeholder: string;
};

const inputs: InputType[] = [
  {
    id: "name",
    label: "Facility Name",
    type: "text",
    placeholder: "Facility Name",
  },
  {
    id: "location",
    label: "Location",
    type: "text",
    placeholder: "Location",
  },
];

interface NewFacilityFormProps {
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  facility: Facility | null;
  facilities: any;
  setFacilities: React.Dispatch<React.SetStateAction<any>>;
  type: string;
}

const NewFacilityForm: React.FC<NewFacilityFormProps> = ({
  showPopup,
  setShowPopup,
  facility,
  facilities,
  setFacilities,
  type,
}) => {
  const isCreatingFacility = type === "new";

  const initialData = {
    name: facility ? facility.name : "",
    location: facility ? facility.location : "",
  };

  const [data, setData] = useState(initialData);
  useEffect(() => {
    if (facility) {
      setData({
        name: facility ? facility.name : "",
        location: facility ? facility.location : "",
      });
    }
  }, [facility]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setData({ ...data, [id]: value });
  };

  const handleAdd = async (e: React.FormEvent<SubmitEvent>) => {
    e.preventDefault();

    try {
      await axios.post("/api/admin/facility/create", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log("Successfully created facility", data);
      setFacilities([...facilities, data]);
      setShowPopup(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Transition.Root show={showPopup} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setShowPopup}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form
                    onSubmit={(e) => handleAdd}
                    className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className=" bg-secondary px-4 py-6 sm:px-6 ">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            {isCreatingFacility ? (
                              <Dialog.Title className="text-base font-semibold leading-6 text-primary">
                                New Facility
                              </Dialog.Title>
                            ) : (
                              <Dialog.Title className="text-base font-semibold leading-6 text-primary">
                                Update Facility
                              </Dialog.Title>
                            )}
                            <p className="text-sm text-gray-300">
                              Get started by filling in the information below to
                              create the facility.
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={() => setShowPopup(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <Close className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        {/* Project name */}
                        {inputs.map((input) => (
                          <div
                            key={input.id}
                            className={`space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5`}
                          >
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              {input.label}
                            </label>

                            <div className="sm:col-span-2">
                              <input
                                type={input.type}
                                name={input.id}
                                id={input.id}
                                required={true}
                                placeholder={input.placeholder}
                                onChange={handleInput}
                                className={`block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 `}
                              />
                            </div>
                          </div>
                        ))}
                        <div
                          key={"region"}
                          className={`space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5`}
                        >
                          <label
                            htmlFor="region"
                            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >
                            Region
                          </label>

                          <div className="sm:col-span-2">
                            <select
                              onChange={(e) => handleInput}
                              className={`block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 `}
                              name="region"
                              id="region"
                              required={true}
                            >
                              <option>NY</option>
                              <option>NJ</option>
                              <option>FL</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setShowPopup(false)}
                        >
                          Cancel
                        </button>
                        {isCreatingFacility ? (
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md bg-accent-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-100"
                          >
                            Create
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md bg-accent-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-100"
                          >
                            Save
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewFacilityForm;
