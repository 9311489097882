import React, { useState, useContext } from "react";
import moment from "moment-timezone";
import UrgentWorkOrderTable from "../../updatedcomponents/tables/UrgentWorkOrderTable";
import HomeChart from "../../updatedcomponents/data-display/HomeChart";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import DateFilter from "../../updatedcomponents/general-ui/DateFilter";
import useFetchData from "../../hooks/useFetchData";
import { WorkOrder } from "../../types/WorkOrder";
import Button from "../../updatedcomponents/general/Button";
import { useFacility } from "../../hooks/useFacility";

interface RecurringTableData {
  complete: number;
  incomplete: number;
  closed: number;
  overdue: number;
}

interface WorkOrderStatusCounts {
  complete: number;
  incomplete: number;
  closed: number;
  unable: number;
}

interface WorkOrders {
  workOrders: WorkOrder[];
  workOrderStatusCounts: WorkOrderStatusCounts;
}

interface DateParams {
  displayText: string;
  startDate: Date | null;
  endDate: Date | null;
  selectedRange: string;
}

const Home: React.FC = () => {
  const defaultRecurringTableData: RecurringTableData = {
    complete: 1,
    incomplete: 1,
    closed: 1,
    overdue: 1,
  };

  const defaultWorkOrders: WorkOrders = {
    workOrders: [],
    workOrderStatusCounts: { complete: 1, incomplete: 1, closed: 1, unable: 1 },
  };

  const { selectedFacility } = useFacility();
  //const [showDateFilter, setShowDateFilter] = useState<boolean>(false);
  const [dateParams, setDateParams] = useState<DateParams>({
    displayText: moment().format("MMMM YYYY"),
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
    selectedRange: "month",
  });

  const { data: recurringTableData, error: recurringTableError } =
    useFetchData<RecurringTableData>(
      selectedFacility ? `/api/home/recurring-table/${selectedFacility}` : null,
      {
        startDate: dateParams.startDate,
        endDate: dateParams.endDate,
      },
      [selectedFacility, dateParams],
      defaultRecurringTableData
    );

  const { data: workOrders, error: workOrdersError } = useFetchData<WorkOrders>(
    selectedFacility ? `/api/home/work-orders/${selectedFacility}` : null,
    {
      startDate: dateParams.startDate,
      endDate: dateParams.endDate,
    },
    [selectedFacility, dateParams],
    defaultWorkOrders
  );

  if (recurringTableError || workOrdersError) {
    console.error(
      "Error fetching Dashboard data:",
      recurringTableError || workOrdersError
    );
  }

  const currentRecurringTableData =
    recurringTableData || defaultRecurringTableData;

  const currentWorkOrders = workOrders || defaultWorkOrders;

  const handleReset = () => {
    setDateParams({
      displayText: moment().format("MMMM YYYY"),
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),
      selectedRange: "month",
    });
  };

  return (
    <div className="relative bg-secondary-1100 sm:bg-primary">
      <PageHeader
        title="Dashboard"
        subtitle=""
        wrap={false}
        buttons={
          <>
            <DateFilter dateParams={dateParams} setDateParams={setDateParams} />
            <Button
              onClick={() => handleReset()}
              styleString="primary"
              children="Reset to Default"
            />
          </>
        }
      />
      <div className="p-4 flex flex-col gap-4">
        <HomeChart
          recurringTable={currentRecurringTableData}
          workOrders={currentWorkOrders?.workOrderStatusCounts}
        />
        <UrgentWorkOrderTable workOrders={currentWorkOrders?.workOrders} />
      </div>
    </div>
  );
};

export default Home;
