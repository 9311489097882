import React from "react";
import KioskFormComponent from "../../updatedcomponents/forms/KioskFormComponent";
import { useParams } from "react-router-dom";

const KioskForm: React.FC = () => {
  const { kioskId } = useParams();

  return (
    <div className="h-screen w-screen flex justify-center items-center bg-secondary-1000 py-4">
      <div className="hidden sm:block absolute left-5 top-5 cursor-pointer">
        <a href="/kiosks/login">
          <img
            className="mx-auto h-8 w-auto"
            src="\sequra_logo__primary_black.png"
            alt="SNFSafe logo"
          />
        </a>
      </div>
      <div className="flex-col w-full sm:max-w-md md:max-w-lg lg:max-w-xl max-h-full overflow-auto bg-primary h-fit p-4 rounded-md mx-4">
        <img
          className="h-10 w-auto mx-auto md:hidden "
          src="/sequra_logo__icon_black.png"
        />
        <KioskFormComponent kioskId={kioskId ?? null} />
      </div>
    </div>
  );
};

export default KioskForm;
