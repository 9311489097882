import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Close, SearchOffOutlined } from "@mui/icons-material";
import StatusBadge from "../general-ui/StatusBadges";
import { formatDate } from "../../utils/FormatFunctions";
import { TaskInstance } from "../../types/TaskInstance";

interface ViewLogProps {
  show: boolean;
  handleClose: () => void;
  log: any;
  handleViewInstance: (logEntry: TaskInstance) => void;
}

const ViewLog:React.FC<ViewLogProps> = ({ show, handleClose, log, handleViewInstance }) => {

  const logEntries = log?.logEntries;
  const content = (
    <div className="min-h-32 max-h-64 flex">
      {logEntries && logEntries.length > 0 ? (
        <table className="w-full">
          <thead>
            <tr className="bg-secondary-1200 border border-secondary-1000 rounded-sm">
              <th
                scope="col"
                className="py-3.5 group pl-4 pr-2 text-left text-sm font-medium text-secondary-100 cursor-pointer"
              >
                Date
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                Status
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                View
              </th>
            </tr>
          </thead>
          <tbody>
            {logEntries.map((logEntry : TaskInstance) => (
              <Fragment key={logEntry._id}>
                <tr key={`${logEntry._id}-gap`} className="h-2"></tr>
                <tr
                  key={`${logEntry._id}-title`}
                  className="border border-secondary-1000 rounded-sm"
                >
                  <td className="primaryspace-nowrap py-1 pl-4 pr-2 text-sm font-medium text-secondary-100 ">
                    {formatDate(logEntry.date, "MMM Do YYYY")}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">{formatDate(logEntry.date)}</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {<StatusBadge status={logEntry.status} />}
                      </dd>
                      <dt className="sr-only sm:hidden">Status</dt>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {<StatusBadge status={logEntry.status} />}
                  </td>
                  <td className=" px-3 py-4 text-sm text-gray-500 table-cell">
                    <button
                      className="text-accent-500 hover:text-accent-400 underline"
                      onClick={() => handleViewInstance(logEntry)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="flex items-center w-full justify-center text-secondary-100 text-center bg-secondary-1100">
          <div className="flex flex-col w-full justify-center items-center bg-gray-100 text-gray-500 py-8">
            <SearchOffOutlined style={{ fontSize: "2rem" }} />
            <span className="text-sm">No logs completed yet</span>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => handleClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-secondary-100 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-sm bg-primary pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl max-h-full">
                <div className="flex h-full flex-col overflow-auto bg-primary ">
                  <div className="px-4 sm:px-6 border-b border-secondary-1000">
                    <div className="flex items-start justify-between ">
                      <Dialog.Title className="text-base font-medium leading-6 text-secondary-100 mb-4">
                        {log?.title}
                      </Dialog.Title>
                      <div className="">
                        <button
                          type="button"
                          className="justify-center items-center relative rounded-sm bg-primary text-secondary-700 hover:text-secondary-500 focus:outline-none "
                          onClick={() => handleClose()}
                        >
                          <span className="sr-only">Close panel</span>
                          <Close
                            style={{ fontSize: "1rem" }}
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-6">{content}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewLog;
