import React, { useState, useEffect, useContext } from "react";
import {
  useForm,
  useFieldArray,
  Controller,
  useWatch,
  set,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { AuthContext } from "../../context/AuthContext";
import { FacilityContext } from "../../context/FacilityContext";
import axios from "axios";
import {
  ErrorOutline,
  CheckCircleOutline,
  Add,
  Remove,
  LabelOutlined,
  ChevronRightOutlined,
  AttachmentOutlined,
} from "@mui/icons-material";
import StatusBadge from "../general-ui/StatusBadges";
import {
  capitalizeString,
  decodedString,
  extractFilenameFromUrl,
  formatDate,
} from "../../utils/FormatFunctions";
import classNames from "classnames";
import Button from "../general-ui/Button";
import FormInputModal from "../modals/FormInputModal";
import { useAuth } from "../../hooks/useAuth";
import ResponseMessage from "../general-ui/ResponseMessage";
import { File as FileType } from "../../types/File";
import { useRecurring } from "../../hooks/useRecurring";

interface LogData {
  attachments: any[];
  date: string;
  details: string;
  hasCycle: boolean;
  hasMultipleInputs: boolean;
  inputs: Input[];
  map: {
    fields: { ID: number }[];
  };
  notifyVendor: boolean;
  parentTask: string;
  readyToComplete: boolean;
  recurrence: {
    frequency: string;
    interval: number;
    _id: string;
  };
  source: string;
  status: string;
  title: string;
  customInput?: Record<
    number,
    Record<string, string | number | boolean | null>
  >;
  customMap: Record<string, any>;
  childId?: string;
}

interface Input {
  dataType: string;
  name: string;
  validator: {
    required: boolean;
    requireNote: boolean;
    lowerLimit?: string;
    upperLimit?: string;
  };
  isDeleted?: boolean;
  _id: string;
}

interface TableFormRHFProps {
  logData: any;
  setLogData: any;
  setShowForm: any;
  refresh: boolean;
  setRefresh: any;
  setResponse: any;
  setCloseOutButton?: any;
}

interface ResponseState {
  show: boolean;
  title: string;
  body: string | JSX.Element;
  icon?: JSX.Element;
}

interface Response {
  show: boolean;
  title: string;
  body: string | JSX.Element;
  icon: JSX.Element;
}

type ExpandedSection = number | null;
type Files = File[];
type SectionChanges = Record<number, boolean>;

interface FormData {
  sections: Section[];
}

interface Section {
  ID: number;
  Notes: string;
  fields: Record<string, string | string[]>;
  timestamp: string | null;
}
const TableFormRHF: React.FC<TableFormRHFProps> = ({
  logData,
  setLogData,
  setShowForm,
  setCloseOutButton,
  setResponse,
}) => {
  const { currentUser } = useAuth();
  const { fetchRecurringTasks } = useRecurring();
  const { selectedFacility } = useContext(FacilityContext);
  const [expandedSection, setExpandedSection] = useState<ExpandedSection>(null);
  const [files, setFiles] = useState<Files>([]);
  const [sectionChanges, setSectionChanges] = useState<SectionChanges>({});
  const [canCloseOut, setCanCloseOut] = useState<boolean>(false);
  const [isCertified, setIsCertified] = useState<boolean>(false);
  /*  const [response, setResponse] = useState<ResponseState>({
    show: false,
    title: "",
    body: "",
    icon: undefined,
  }); */
  const navigate = useNavigate();

  const activeInputs: Input[] = logData.inputs.filter(
    (input: Input) => input.isDeleted !== true
  );

  //console.log("logdata:", logData);

  const generateValidationSchema = () => {
    return yup.object().shape({
      fields: yup.object().shape(
        activeInputs.reduce((acc, input) => {
          let fieldSchema: yup.Schema<any>;

          if (input.dataType === "number") {
            fieldSchema = yup
              .number()
              .transform((value, originalValue) => {
                if (originalValue === "") return undefined;
                const num = Number(originalValue);
                return isNaN(num) ? undefined : num;
              })
              .typeError("Must be a number") as yup.NumberSchema;

            if (input.validator.required) {
              fieldSchema = fieldSchema.required("This field is required");
            }

            if (
              input.validator.lowerLimit !== undefined &&
              input.validator.upperLimit !== undefined
            ) {
              const min = Number(input.validator.lowerLimit);
              const max = Number(input.validator.upperLimit);

              // Use test instead of min/max
              fieldSchema = fieldSchema.test(
                "range",
                `Number must be between ${min} and ${max}`,
                (value) => {
                  if (value === undefined || value === null) return true;
                  return value >= min && value <= max;
                }
              );
            }
          } else if (input.dataType === "file") {
            fieldSchema = yup.mixed().transform((value) => {
              if (!value || value === "") return [];
              return Array.isArray(value) ? value : [value];
            });

            if (input.validator.required) {
              fieldSchema = fieldSchema.test(
                "required",
                "At least one file is required",
                (value) => {
                  return value && value.length > 0;
                }
              );
            }
          } else {
            fieldSchema = yup.string();
            if (input.validator.required) {
              fieldSchema = fieldSchema.required("This field is required");
            }
          }

          acc[input._id] = fieldSchema;
          return acc;
        }, {} as { [key: string]: yup.Schema<any> })
      ),
      Notes: yup
        .string()
        .test(
          "notes-required-if-fail",
          "Notes are required when there is a Fail",
          function (value) {
            const fields = this.parent.fields;
            const hasFailValue = Object.values(fields).some(
              (fieldValue) => fieldValue === "Fail"
            );
            return !hasFailValue || (!!value && value.trim() !== "");
          }
        ),
    });
  };

  const validationSchema = generateValidationSchema();

  const customResolver = async (data: any, context: any) => {
    if (expandedSection === null) {
      console.log(
        "No section expanded, returning all values without validation"
      );
      return { values: data, errors: {} };
    }

    try {
      const sectionData = data.sections[expandedSection];
      await validationSchema.validate(sectionData, { abortEarly: false });
      return { values: data, errors: {} };
    } catch (validationError: any) {
      const errors = {
        sections: {
          [expandedSection]: {
            fields: {},
            Notes: {} as { type: any; message: any } | null,
          },
        },
      };

      validationError.inner.forEach((error: any) => {
        if (error.path.startsWith("fields.")) {
          const fieldName = error.path.split(".")[1];
          (errors.sections[expandedSection].fields as Record<string, any>)[
            fieldName
          ] = {
            type: error.type,
            message: error.message,
          };
        } else if (error.path === "Notes") {
          errors.sections[expandedSection].Notes = {
            type: error.type,
            message: error.message,
          };
        }
      });

      return { values: data, errors };
    }
  };

  const checkCompleteValidation = (
    logData: LogData,
    formData: FormData
  ): boolean => {
    const { inputs, map } = logData;
    const { sections } = formData;

    // Create a map of required field IDs
    const requiredFields = new Set(
      inputs
        .filter((input) => input.validator.required)
        .filter((input) => input.isDeleted !== true)
        .map((input) => input._id)
    );

    // Check each section defined in the map
    for (let i = 0; i < map.fields.length; i++) {
      const section = sections[i];

      // If the section doesn't exist in the form data, it's incomplete
      if (!section || !section.fields) {
        return false;
      }

      // Check each required field
      for (const fieldId of Array.from(requiredFields)) {
        if (
          !section.fields.hasOwnProperty(fieldId) ||
          section.fields[fieldId] === "" ||
          section.fields[fieldId] === null ||
          section.fields[fieldId] === undefined
        ) {
          return false;
        }
      }
    }

    return true;
  };

  const closeOutValidation = (data: FormData): boolean => {
    const { sections } = data;

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (!section || !section.fields) continue;

      for (const [fieldId, value] of Object.entries(section.fields)) {
        if (value === "Fail" || value === "") {
          return false;
        }
      }
    }

    return true;
  };

  const getInitialValues = (): Section[] => {
    const existingData = logData.customInput || {};
    return logData.map.fields.map((field: any, index: number) => ({
      ...field,
      fields: activeInputs.reduce<Record<string, string>>((acc, input) => {
        const sectionData = existingData[index] as
          | Record<string, string>
          | undefined;
        acc[input._id] = sectionData?.[input._id] || "";
        return acc;
      }, {}),
      Notes:
        (existingData[index] as Record<string, string> | undefined)?.Notes ||
        "",
      timestamp:
        (existingData[index] as Record<string, any> | undefined)?.timestamp ||
        null,
    }));
  };

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm({
    resolver: customResolver as any,
    defaultValues: {
      sections: getInitialValues(),
    },
  });

  const { fields } = useFieldArray<FormData, "sections", "id">({
    control,
    name: "sections",
  });
  const formValues = useWatch({ control });

  useEffect(() => {
    const newSectionChanges: Record<number, boolean> = {};
    fields.forEach((field, index) => {
      const sectionDirty =
        Object.keys(
          (dirtyFields.sections?.[index]?.fields as Record<string, boolean>) ||
            {}
        ).length > 0 || !!dirtyFields.sections?.[index]?.Notes;
      newSectionChanges[field.ID] = sectionDirty;
    });
    setSectionChanges(newSectionChanges);
    setIsCertified(false);
  }, [formValues, dirtyFields, fields]);

  useEffect(() => {
    // Run closeout validation when component mounts
    const formData = getValues();
    const canCloseOutInitial = closeOutValidation(formData);
    setCanCloseOut(canCloseOutInitial);
  }, []);

  const onSubmit = async (data: FormData) => {
    try {
      const sectionData = data.sections[expandedSection!];
      const formattedData = {
        ...sectionData.fields,
        Notes: sectionData.Notes,
        timestamp: new Date().toISOString(),
      };

      const isComplete = checkCompleteValidation(logData, data);
      let updatedStatus = isComplete ? "complete" : "in progress";

      const canCloseOut = closeOutValidation(data);
      setCanCloseOut(canCloseOut);

      const uploadForm = new FormData();
      uploadForm.append("facility", selectedFacility);
      uploadForm.append("status", updatedStatus);
      uploadForm.append("customInput", JSON.stringify(formattedData));
      uploadForm.append(
        "customMap",
        JSON.stringify(logData.map.fields[expandedSection!])
      );
      uploadForm.append("parentDate", logData.date);
      uploadForm.append("sectionIndex", expandedSection!.toString());

      files.forEach((file) => {
        uploadForm.append("files", file);
      });

      const response = await axios.post(
        `/api/log/update/${logData.parentTask}/${
          logData.childId || "createId"
        }`,
        uploadForm,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setLogData((prevLogData: LogData) => {
        const newCustomInput = { ...prevLogData.customInput };
        newCustomInput[expandedSection!] = formattedData;
        return {
          ...prevLogData,
          childId: response.data.id,
          customInput: newCustomInput,
          status: updatedStatus,
        };
      });

      setSectionChanges((prev) => ({ ...prev, [expandedSection!]: false }));
      setResponse({
        show: true,
        title: "Success",
        body: `Section ${expandedSection! + 1} saved successfully`,
        icon: (
          <CheckCircleOutline
            className="text-accent-500"
            style={{ fontSize: "1rem" }}
          />
        ),
      });
    } catch (error) {
      console.error("Error submitting updated data: ", error);
      setResponse({
        show: true,
        title: "Error",
        body: "Failed to save. Please check the form for errors and try again.",
        icon: (
          <ErrorOutline
            className="text-reds-500"
            style={{ fontSize: "1rem" }}
          />
        ),
      });
    }
  };

  const handleCloseOut = async () => {
    try {
      const parentId = logData.parentTask;
      const childId = logData.childId;

      const response = await axios.post(
        "/api/log/close-out/task",
        {
          logId: parentId,
          entryId: childId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setResponse({
        show: true,
        title: "Task Closed",
        body: (
          <>
            {"Task closed successfully! "}
            <button
              className="text-xs text-accent-500 hover:text-accent-400"
              onClick={() => navigate("/logs")}
            >
              Navigate to task
            </button>
          </>
        ),
        icon: (
          <CheckCircleOutline
            className="text-accent-500"
            style={{ fontSize: "1rem" }}
          />
        ),
      });
      fetchRecurringTasks(selectedFacility);
      setShowForm(false);
      setCanCloseOut(false);
    } catch (error) {
      console.error("Error closing out task:", error);
      setResponse({
        show: true,
        title: "Error",
        body: "Failed to close out the task. Please try again.",
        icon: (
          <ErrorOutline
            className="text-reds-500"
            style={{ fontSize: "1rem" }}
          />
        ),
      });
    }
  };
  const closeoutButton = [
    "organization-admin",
    "director",
    "regional",
  ].includes(currentUser.access) && (
    <Button
      onClick={handleCloseOut}
      disabled={!canCloseOut}
      children="Close Out"
      styleString="primary"
    />
  );
  useEffect(() => {
    setCloseOutButton(closeoutButton);
  }, [canCloseOut]);

  const resetFormSection = (sectionIndex: number) => {
    const currentValues = getValues();
    const resetData = {
      ...currentValues,
      sections: currentValues.sections.map((section, index) => {
        if (index === expandedSection) {
          return {
            ...section,
            fields: { ...section.fields },
            Notes: section.Notes,
          };
        }
        return section;
      }),
    };

    reset(resetData);
    setSectionChanges((prev) => {
      const newChanges = { ...prev, [expandedSection!]: false };
      return newChanges;
    });
  };

  //This one doesnt save the data. It just resets the form
  const resetFormSectionWithChanges = (sectionIndex: number) => {
    const currentValues = getValues();

    const resetData = {
      ...currentValues,
      sections: currentValues.sections.map((section, index) =>
        index === sectionIndex
          ? {
              ...section,
              fields: { ...getInitialValues()[sectionIndex].fields },
              Notes: getInitialValues()[sectionIndex].Notes,
            }
          : section
      ),
    };

    reset(resetData);

    setSectionChanges((prev) => {
      const newChanges = { ...prev, [sectionIndex]: false };
      return newChanges;
    });
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    resetFormSection(expandedSection!);
    if (!isCertified) {
      setResponse({
        show: true,
        title: "Error",
        body: "Please certify the data before saving",
        icon: (
          <ErrorOutline
            className="text-reds-500"
            style={{ fontSize: "1rem" }}
          />
        ),
      });
      return;
    }
    setIsCertified(false);
    handleSubmit(onSubmit)(e);
  };

  const handleViewFile = async (link: string) => {
    const file = files.find((file) => file.name === link);

    if (file) {
      // If the file is in the files state, create a blob URL and open it
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl, "_blank");
    } else {
      try {
        const encodedLink = encodeURIComponent(link);
        await axios
          .get("/api/log/file", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: { encodedLink },
          })
          .then((response) => {
            window.open(response.data.url, "_blank");
          })
          .catch((error) => {
            console.error("Error fetching file:", error);
          });
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    }
  };

  const toggleSection = (sectionId: number) => {
    setExpandedSection(expandedSection === sectionId ? null : sectionId);
  };

  const [showSpecs, setShowSpecs] = useState(true);

  const specifications = (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-2 text-sm m-4">
      <label className="text-secondary-100">Due Date:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3">
        {logData?.date.slice(0, 10)}
      </span>

      <label className="text-secondary-100">Status:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3">
        <StatusBadge status={logData?.status} />
      </span>

      <label className="text-secondary-100">Source:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3">
        {capitalizeString(logData?.source)}
      </span>

      <label className="text-secondary-100">Details:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3 border border-secondary-1000 mb-4 px-2 rounded-md">
        {logData?.details}
      </span>
      <label className="text-secondary-100">Attachments:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3">
        {logData?.attachments?.length > 0 ? (
          logData.attachments.map((attachment: FileType) => (
            <div key={attachment._id} className="flex gap-2">
              <button
                className="px-2 py-0.5 bg-secondary-1100 rounded-sm font-semibold text-sm cursor-pointer my-1"
                onClick={() => handleViewFile(attachment.url ?? "")}
              >
                <AttachmentOutlined fontSize="small" /> {attachment.name}
              </button>
            </div>
          ))
        ) : (
          <p className="text-sm italic">No attachments for this task</p>
        )}
      </span>
    </div>
  );

  const renderInput = (index: number, input: Input) => {
    const { dataType, _id, name } = input;

    switch (dataType) {
      case "radio":
      case "radio-na":
        return (
          <Controller
            name={`sections.${index}.fields.${_id}`}
            control={control}
            render={({ field }) => (
              <div className="flex w-1/2 justify-end gap-2">
                <label
                  className={classNames(
                    "flex max-w-36 flex-1 items-center gap-4 rounded py-1.5 px-3",
                    {
                      "bg-accent-1100 border-accent-500 border text-secondary-100":
                        field.value === "Pass",
                    }
                  )}
                >
                  <input
                    type="radio"
                    value="Pass"
                    className={classNames({
                      "text-accent-300 h-4 w-4 border-secondary-1000  focus:ring-accent-300":
                        field.value === "Pass",
                    })}
                    checked={field.value === "Pass"}
                    onChange={() => field.onChange("Pass")}
                  />
                  <span>Pass</span>
                </label>
                <label
                  className={classNames(
                    "flex max-w-36 flex-1 items-center gap-4 rounded py-1.5 px-3",
                    {
                      "bg-reds-1100 border-reds-500 border text-secondary-100":
                        field.value === "Fail",
                    }
                  )}
                >
                  <input
                    type="radio"
                    value="Fail"
                    className={classNames({
                      "text-reds-300 h-4 w-4 border-secondary-1000  focus:ring-reds-300":
                        field.value === "Fail",
                    })}
                    checked={field.value === "Fail"}
                    onChange={() => field.onChange("Fail")}
                  />
                  <span>Fail</span>
                </label>
                {dataType === "radio-na" && (
                  <label
                    className={classNames(
                      "flex max-w-36 flex-1 items-center gap-4 rounded py-1.5 px-3",
                      {
                        "bg-secondary-1100 border-secondary-500 border text-secondary-100":
                          field.value === "N/A",
                      }
                    )}
                  >
                    <input
                      type="radio"
                      value="N/A"
                      className={classNames({
                        "text-secondary-300 h-4 w-4 border-secondary-1000  focus:ring-secondary-300":
                          field.value === "N/A",
                      })}
                      checked={field.value === "N/A"}
                      onChange={() => field.onChange("N/A")}
                    />
                    <span>N/A</span>
                  </label>
                )}
              </div>
            )}
          />
        );
      case "file":
        return (
          <div className="flex w-1/2 flex-col gap-2">
            <Controller
              name={`sections.${index}.fields.${_id}`}
              control={control}
              render={({ field }) => {
                const filesFields = Array.isArray(field.value)
                  ? field.value
                  : field.value
                  ? [field.value]
                  : [];
                return (
                  <>
                    <div className="flex flex-col max-h-48 gap-y-2 overflow-y-auto thin-scrollbar">
                      {filesFields.map((file: string, fileIndex: number) => (
                        <div
                          key={fileIndex}
                          className="flex items-center justify-between gap-2 bg-secondary-1100 rounded-sm w-full divide-x divide-secondary-800"
                        >
                          <span
                            onClick={() => handleViewFile(file)}
                            className="cursor-pointer px-2 text-xs text-secondary-100 truncate "
                          >
                            {extractFilenameFromUrl(file)}
                          </span>
                          <Button
                            icon="Close"
                            onClick={
                              () => {
                                const newFiles = filesFields.filter(
                                  (_, i) => i !== fileIndex
                                );
                                field.onChange(newFiles);
                                setFiles(files.filter((f) => f.name !== file));
                              }
                              //handleRemoveFile(file, fileIndex, index, _id)
                            }
                            styleString="minimal"
                            children=""
                            overrideStyles="text-reds-400"
                          />
                        </div>
                      ))}
                    </div>
                    <input
                      type="file"
                      //onChange={(e) => handleFileChange(e, index, _id)}
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          const newFile = e.target.files[0];
                          setFiles((prevFiles) => [...prevFiles, newFile]);

                          const currentFiles = field.value || [];
                          const newValue = [
                            ...(Array.isArray(currentFiles)
                              ? currentFiles
                              : [currentFiles]),
                            newFile.name,
                          ];
                          field.onChange(newValue);
                        }
                        e.target.value = "";
                      }}
                      className="hidden"
                      id={`file-${index}-${_id}`}
                    />
                    <label
                      htmlFor={`file-${index}-${_id}`}
                      className="cursor-pointer flex w-full justify-center"
                    >
                      <Button
                        onClick={() => {
                          const fileInput = document.getElementById(
                            `file-${index}-${_id}`
                          );
                          if (fileInput) {
                            fileInput.click();
                          }
                        }}
                        styleString="bold"
                        children="Upload File"
                        icon="CloudUpload"
                        overrideStyles="w-full"
                      />
                    </label>
                  </>
                );
              }}
            />
          </div>
        );
      case "number":
        return (
          <Controller
            name={`sections.${index}.fields.${_id}`}
            control={control}
            render={({ field }) => {
              const currentValue = Number(field.value || 0);

              return (
                <div className="flex justify-between w-1/2 items-center rounded border border-secondary-800">
                  <button
                    type="button"
                    onClick={() => {
                      const newValue = currentValue - 1;
                      field.onChange(newValue);
                    }}
                    className="px-2"
                  >
                    <Remove fontSize="small" />
                  </button>
                  <input
                    type="number"
                    {...field}
                    onChange={(e) => {
                      const value = e.target.value;
                      field.onChange(value === "" ? "" : Number(value));
                    }}
                    className="max-w-24 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none p-2 border-none focus:ring-0 text-center"
                    value={field.value ?? ""}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const newValue = currentValue + 1;
                      field.onChange(newValue);
                    }}
                    className="px-2"
                  >
                    <Add fontSize="small" />
                  </button>
                </div>
              );
            }}
          />
        );
      default:
        return (
          <Controller
            name={`sections.${index}.fields.${_id}`}
            control={control}
            render={({ field }) => (
              <input
                type="text"
                {...field}
                className={`py-1.5 ring-1 w-1/2 ring-inset focus:ring-inset focus:ring-1 border-0 mt-2 block rounded pl-3 pr-2 text-secondary-100  ${
                  errors.sections?.[index]?.fields
                    ? "ring-reds-500 focus:ring-reds-500"
                    : "ring-secondary-800  focus:ring-secondary-500 focus:outline-none"
                } sm:text-sm sm:leading-6 disabled:bg-opacity-5 disabled:cursor-not-allowed`}
                // value={
                //   typeof field.value === "object"
                //     ? JSON.stringify(field.value)
              />
            )}
          />
        );
    }
  };

  const handleSectionChange = (newIndex: number) => {
    if (expandedSection !== null && sectionChanges[expandedSection + 1]) {
      const confirm = window.confirm(
        "You have unsaved changes. If you leave, your unsaved changes will be gone. Are you sure you want to leave?"
      );
      if (confirm) {
        resetFormSectionWithChanges(expandedSection);
        toggleSection(newIndex);
      }
    } else {
      toggleSection(newIndex);
    }
  };

  const formContent = (index: number, field: any) => {
    const isFirstSection = index === 0;
    const isLastSection = index === fields.length - 1;

    const goToNextSection = () => {
      if (!isLastSection) {
        handleSectionChange(index + 1);
      }
    };

    const goToPreviousSection = () => {
      if (!isFirstSection) {
        handleSectionChange(index - 1);
      }
    };

    return (
      <div className="flex flex-col h-full">
        <div className="flex bg-secondary-1200 w-full mx-auto overflow-x-auto p-2 gap-x-2 rounded-sm ">
          {fields.map((field, fieldIndex) => (
            <span
              key={field.id}
              className={`text-sm px-2 py-1.5 items-center inline-flex text-nowrap rounded-sm ${
                fieldIndex === index ? "bg-primary shadow" : "bg-transparent"
              } cursor-pointer`}
              onClick={() => {
                if (fieldIndex !== index) {
                  handleSectionChange(fieldIndex);
                }
              }}
            >
              {String(field[Object.keys(field)[1] as keyof typeof field])}
            </span>
          ))}
        </div>
        <div className="flex w-full justify-between my-2">
          <StatusBadge status={logData.status} />
          {logData.customInput &&
            logData.customInput[index] &&
            logData.customInput[index].timestamp && (
              <div className="text-xs text-secondary-500">
                Last updated:{" "}
                {formatDate(
                  String(logData.customInput[index].timestamp),
                  "MMM DD, yyyy hh:mm a"
                )}
              </div>
            )}
        </div>

        <div className="flex flex-col bg-secondary-1100 rounded border p-4 gap-y-2 flex-grow overflow-y-auto thin-scrollbar">
          {activeInputs.map((input) => (
            <div
              key={input._id}
              className="flex flex-col bg-primary shadow rounded-sm p-2 gap-y-2"
            >
              <label className="flex items-center gap-x-2">
                <LabelOutlined fontSize="small" />
                {capitalizeString(decodedString(input.name))}
              </label>
              <div className="flex flex-col items-end">
                {renderInput(index, input)}
                {errors.sections?.[index]?.fields?.[input._id] &&
                  (console.log(
                    "Error for field:",
                    input._id,
                    errors.sections[index]?.fields?.[input._id]
                  ),
                  (
                    <span className="text-reds-500 text-sm">
                      {errors.sections[index]?.fields?.[input._id]?.message}
                    </span>
                  ))}
              </div>
            </div>
          ))}
          <div className="flex flex-col bg-primary shadow rounded-sm p-2">
            <label className="flex items-center gap-x-2">
              <LabelOutlined fontSize="small" />
              Notes
            </label>
            <Controller
              name={`sections.${index}.Notes`}
              control={control}
              render={({ field }) => (
                <textarea {...field} className="w-full p-2 border rounded" />
              )}
            />
            {errors.sections?.[index]?.Notes && (
              <span className="text-reds-500 text-sm">
                {errors.sections[index]?.Notes?.message}
              </span>
            )}
          </div>
        </div>
        {sectionChanges[field.ID] ? (
          <>
            <label className="flex items-center gap-2 mt-4">
              <input
                type="checkbox"
                className="h-4 w-4 text-accent-500 rounded focus:ring-accent-500"
                required
                checked={isCertified}
                onChange={(e) => {
                  setIsCertified(e.target.checked);
                }}
              />
              <span className="text-sm">
                I hereby certify that the data recorded above is accurate to the
                best of my ability.
              </span>
            </label>
            <div className="flex W-full justify-between items-center my-4">
              <Button
                styleString="danger"
                type="button"
                children="Cancel"
                onClick={() => resetFormSectionWithChanges(index)}
              />
              <Button
                styleString={
                  sectionChanges[field.ID] && isCertified
                    ? "primary"
                    : "secondary"
                }
                type="submit"
                children="Save Section"
                disabled={!sectionChanges[field.ID] || !isCertified}
                onClick={(e) => handleFormSubmit(e)}
              />
            </div>
          </>
        ) : (
          <div className="flex W-full justify-between items-center my-4">
            <Button
              styleString={"bold"}
              type="button"
              children="Back"
              icon="ChevronLeftOutlined"
              onClick={goToPreviousSection}
              disabled={isFirstSection}
              overrideStyles={`${
                Object.keys(fields).length <= 1 ? "bg-transparent text-transparent hover:text-transparent hover:cursor-default" : ""
              }`}
            />
            <Button
              styleString={!isLastSection ? "bold" : "primary"}
              type="button"
              children={!isLastSection ? "Next" : "Close Out"}
              {...(!isLastSection && { icon: "ChevronRightOutlined" })}
              onClick={!isLastSection ? goToNextSection : handleCloseOut}
              disabled={isLastSection && !canCloseOut}
              reverse={true}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="flex justify-between my-2 px-4 py-2 text-secondary-100 border-b border-secondary-1000">
        <span className=" font-medium py-2">Details</span>
        <button
          className="px-12 py-2 font-medium text-sm bg-secondary-1100 rounded-sm"
          onClick={() => setShowSpecs(!showSpecs)}
        >
          {showSpecs ? "Hide" : "Show"} Details
        </button>
      </div>
      {showSpecs && specifications}

      <form onSubmit={handleFormSubmit} className="px-4 space-y-2">
        {fields.map((field, index) => (
          <div key={field.id} className="border rounded p-4">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection(index)}
            >
              <div className="flex flex-col">
                <h3 className="text-sm">
                  {Object.keys(field)[1] as keyof typeof field}:{" "}
                  {String(field[Object.keys(field)[1] as keyof typeof field])}
                </h3>
                <h4 className="text-sm">
                  {Object.keys(field)[0] as keyof typeof field}:{" "}
                  {String(field[Object.keys(field)[0] as keyof typeof field])}
                </h4>
              </div>
              <ChevronRightOutlined fontSize="medium" />
            </div>
            {expandedSection === index && (
              <FormInputModal
                show={expandedSection === index}
                handleClose={() => {
                  resetFormSectionWithChanges(index);
                  toggleSection(index);
                }}
                title={
                  logData.title +
                  " - " +
                  formatDate(logData.date, "MMM DD, yyyy", "utc")
                }
                content={formContent(index, field)}
              />
            )}
          </div>
        ))}
      </form>
    </>
  );
};

export default TableFormRHF;
