import React, { Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { FacilityContext } from "../../context/FacilityContext";
import * as XLSX from "xlsx";
import { Add, Close, UploadFile } from "@mui/icons-material";

interface CreateMapButtonProps {
  showMapCreate: boolean;
  setShowMapCreate: React.Dispatch<React.SetStateAction<boolean>>;
  refreshMap: boolean;
  setRefreshMap: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMap: any;
}

interface RowData {
  [key: string]: string;
}

const CreateMapButton: React.FC<CreateMapButtonProps> = ({
  showMapCreate,
  setShowMapCreate,
  refreshMap,
  setRefreshMap,
  selectedMap,
}) => {
  const { selectedFacility } = useContext(FacilityContext);
  const [collectionTitle, setCollectionTitle] = useState("");
  const [headerData, setHeaderData] = useState<string[]>(["ID", ""]);
  const [rowData, setRowData] = useState<RowData[]>([{ ID: "1", "": "" }]);

  const [selectedSheet, setSelectedSheet] = useState<string>("");
  const [sheetOptions, setSheetOptions] = useState<string[]>([]);
  const [workbook, setWorkbook] = useState<XLSX.WorkBook | null>(null);

  useEffect(() => {
    if (typeof selectedMap === "undefined" || !selectedMap) {
      return;
    }
    const { fields, name } = selectedMap;

    setCollectionTitle(name);
    if (fields && fields.length > 0) {
      const headers = Object.keys(fields[0]);
      const rows = fields;
      setHeaderData(headers);
      setRowData(rows);
    }
  }, [selectedMap]);

  const handleHeaderChange = (index: number, newValue: string) => {
    const oldHeader = headerData[index];
    const newHeaders = [...headerData];
    newHeaders[index] = newValue;
    setHeaderData(newHeaders);

    const newRows = rowData.map((row) => {
      if (oldHeader in row) {
        const value = row[oldHeader];
        delete row[oldHeader];
        return { ...row, [newValue]: value };
      }
      return row;
    });
    setRowData(newRows);
  };

  const handleCellChange = (
    rowIndex: number,
    header: string,
    value: string
  ) => {
    const newRowData = [...rowData];
    newRowData[rowIndex][header] = value;
    setRowData(newRowData);
  };

  const addRow = (rowIndex: number) => {
    const newRow = headerData.reduce((acc, header) => {
      acc[header] = header === "ID" ? String(rowIndex + 2) : "";
      return acc;
    }, {} as RowData);
    const newRows = [...rowData];
    newRows.splice(rowIndex + 1, 0, newRow);

    // Increment the "ID" value of all subsequent lines
    for (let i = rowIndex + 2; i < newRows.length; i++) {
      newRows[i]["ID"] = String(i + 1);
    }

    setRowData(newRows);
  };

  const removeRow = (rowIndex: number) => {
    const updatedRows = [...rowData];
    updatedRows.splice(rowIndex, 1);

    // Decrement the "ID" value of all subsequent lines
    for (let i = rowIndex; i < updatedRows.length; i++) {
      updatedRows[i]["ID"] = String(i + 1);
    }

    setRowData(updatedRows);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      readExcel(file);
    }
  };

  const readExcel = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target?.result as ArrayBuffer;
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      setWorkbook(workbook);

      const sheetNames = workbook.SheetNames;
      setSheetOptions(sheetNames);

      if (selectedSheet == "" || !sheetNames.includes(selectedSheet)) {
        setHeaderData(["ID", ""]);
        setRowData([{ ID: "1", "": "" }]);
        console.log("Invalid sheet selection or canceled.");
        return;
      }

      const sheet = workbook.Sheets[selectedSheet];
      processSheetData(sheet);
    };

    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (workbook) {
      const sheet = workbook.Sheets[selectedSheet];
      processSheetData(sheet);
    }
  }, [selectedSheet]);

  const processSheetData = (sheet: XLSX.WorkSheet | undefined) => {
    let headers: string[];
    let rows: RowData[];

    if (sheet) {
      const excelRows = XLSX.utils.sheet_to_json<string[]>(sheet, {
        header: 1,
      });

      // Filter out empty rows
      const nonEmptyRows = excelRows.filter((row) =>
        row.some((cell) => cell !== null && cell !== "")
      );

      headers = nonEmptyRows[0] as string[];
      rows = nonEmptyRows.slice(1).map((row) => {
        const rowObj: RowData = {};
        headers.forEach((header, index) => {
          rowObj[header] = String(row[index]);
        });
        return rowObj;
      });
    } else {
      headers = ["ID", ""];
      rows = [{ ID: "1", "": "" }];
    }
    setHeaderData(headers);
    setRowData(rows);

    // Set the title to the selected sheet's name
    setCollectionTitle(selectedSheet);
  };

  const handleCreateMap = async () => {
    if (!collectionTitle) {
      console.log("Collection title is required");
      return;
    }

    const rows = rowData;

    const createdMap = {
      name: collectionTitle,
      fields: rows,
    };

    try {
      if (typeof selectedMap === "undefined") {
        const response = await axios.post(
          `/api/building/create-map/${selectedFacility}`,
          {
            mapData: createdMap,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log("Map successfully sent to the backend", response.data);
        setShowMapCreate(false);
        setRefreshMap(!refreshMap);
      } else {
        const response = await axios.post(
          `/api/building/update-map/${selectedMap._id}`,
          {
            mapData: createdMap,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log("Map successfully sent to the backend", response.data);
        setShowMapCreate(false);
        setRefreshMap(!refreshMap);
      }
    } catch (error) {
      console.error("Error sending map data to the backend", error);
    }
  };

  const content = (
    <div className="mx-3">
      <div>
        <label className="text-secondary-100 text-xs" htmlFor="title">
          Map Title
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="title"
            id="title"
            placeholder="Enter a map title"
            className="w-full rounded-sm border-0 py-0.5 pl-3 pr-10 text-secondary-100 ring-1 ring-inset ring-secondary-1000 focus:ring-2 focus:ring-accent-500 sm:text-sm sm:leading-6"
            value={collectionTitle}
            onChange={(e) => setCollectionTitle(e.target.value)}
          />
        </div>
        <div className="mt-4 flex justify-center items-start align-middle max-h-96 overflow-auto thin-scrollbar">
          <table className="table-auto w-full">
            <thead className="bg-secondary-1100">
              <tr>
                {headerData.map((headerCell, colIndex) => (
                  <th
                    key={colIndex}
                    className={`w-1/2 border-secondary-1000  py-2 ${
                      colIndex > 0 ? "border-y" : "border"
                    }`}
                  >
                    <input
                      type="text"
                      className="bg-inherit border-none focus:ring-0 p-1 w-full font-medium text-xs"
                      value={headerCell}
                      onChange={(e) =>
                        handleHeaderChange(colIndex, e.target.value)
                      }
                    />
                  </th>
                ))}
                <th className=" border-secondary-1000 border-t border-r border-b" />
              </tr>
            </thead>
            <tbody>
              {rowData.map((row, rowIndex) => (
                <Fragment key={row._id + rowIndex}>
                  <tr key={`${row._id}-gap`} className="h-2"></tr>
                  <tr
                    key={rowIndex}
                    className="border border-secondary-1000 rounded-sm"
                  >
                    {headerData.map((headerCell) => (
                      <td key={headerCell} className="w-1/2 border">
                        <input
                          className="border-none focus:border-secondary-1000  w-full focus:ring-accent-500 ring-0 text-xs"
                          type="text"
                          value={row[headerCell]}
                          onChange={(e) =>
                            handleCellChange(
                              rowIndex,
                              headerCell,
                              e.target.value
                            )
                          }
                        />
                      </td>
                    ))}
                    <td className="flex items-center">
                      <button
                        onClick={() => addRow(rowIndex)}
                        className="p-1 border-none outline-none focus:outline-none focus:border-none focus:ring-accent-400 text-accent-500"
                      >
                        <Add style={{ fontSize: "1rem" }} />
                      </button>
                      <button
                        onClick={() => removeRow(rowIndex)}
                        className="p-1 border-none outline-none focus:outline-none focus:border-none focus:ring-reds-600 text-reds-500"
                      >
                        <Close style={{ fontSize: "1rem" }} />
                      </button>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <button
          onClick={() => addRow(rowData.length - 1)}
          className="my-4  w-full bg-primary px-2 py-1 text-sm font-semibold text-secondary-100 shadow-sm ring-1 ring-inset ring-secondary-500 hover:bg-secondary-1200"
        >
          + Add Row
        </button>
        <div className="relative flex items-center justify-center w-full">
          <div className="absolute left-0 right-0 h-0.5 bg-secondary-1000" />
          <span className="relative text-secondary-700 px-2 bg-primary text-sm">
            Or
          </span>
        </div>
        <label
          htmlFor="file-input"
          className="bg-secondary-1100 mx-auto flex align-middle items-center text-sm gap-2 text-center justify-center w-full text-secondary-100 cursor-pointer px-2 py-0.5 m-2 border border-secondary-1000"
        >
          <UploadFile style={{ fontSize: "1rem" }} />
          Import from excel file
          <input
            type="file"
            id="file-input"
            accept=".xls, .xlsx"
            onChange={handleFileChange}
            className="hidden"
          />
        </label>
        {sheetOptions.length > 0 && (
          <div>
            <label
              htmlFor="location"
              className="block text-sm font-medium leading-6 text-secondary-100"
            >
              Sheet
            </label>
            <select
              id="sheetSelector"
              value={selectedSheet}
              className="mt-2 block w-full rounded-sm border-0 py-1.5 pl-3 pr-10 text-secondary-100 ring-1 ring-inset ring-secondary-500 focus:ring-2 focus:ring-accent-500 sm:text-sm sm:leading-6"
              defaultValue=""
              onChange={(e) => {
                setSelectedSheet(e.target.value);
              }}
            >
              <option value={""}>None</option>
              {sheetOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="flex gap-2 mb-2">
          <button
            className="flex mt-4 w-full justify-center rounded-sm bg-secondary-1000 px-3 py-2 text-sm font-semibold text-secondary-100 shadow-sm hover:text-secondary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            onClick={() => setShowMapCreate(false)}
          >
            Cancel
          </button>
          <button
            className="flex mt-4 w-full justify-center rounded-sm bg-secondary-100 px-3 py-2 text-sm font-semibold text-primary shadow-sm hover:bg-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500"
            onClick={handleCreateMap}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <Transition.Root show={showMapCreate} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setShowMapCreate(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-secondary-100 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-sm bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md max-h-full">
                <div className="flex h-full flex-col overflow-auto bg-white ">
                  <div className="px-4 sm:px-6 border-b border-secondary-1000">
                    <div className="flex items-start justify-between ">
                      <Dialog.Title className="text-base font-semibold leading-6 text-secondary-100 mb-4">
                        Map Editor
                      </Dialog.Title>
                      <div className="">
                        <button
                          type="button"
                          className="justify-center items-center relative rounded-sm bg-white text-secondary-700 hover:text-secondary-500 focus:outline-none "
                          onClick={() => setShowMapCreate(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <Close
                            style={{ fontSize: "1rem" }}
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-2">{content}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateMapButton;
