import { useContext } from "react";
import { RecurringTaskContext } from "../context/RecurringTaskContext";

export const useRecurring = () => {
  const context = useContext(RecurringTaskContext);
  if (!context) {
    throw new Error("useRecurring must be used within a RecurringProvider");
  }
  return context;
};
