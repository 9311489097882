import React, { useState, useEffect, useContext } from "react";
import TeamList from "../../updatedcomponents/tables/TeamList";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import NewUserForm from "../../updatedcomponents/forms/NewUserForm";
import UserInfo from "./UserView";
import { Add, ArrowBack } from "@mui/icons-material";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import useFetchData from "../../hooks/useFetchData";
import { useFacility } from "../../hooks/useFacility";
import { useAuth } from "../../hooks/useAuth";
import Button from "../../updatedcomponents/general/Button";

const Team = () => {
  const { selectedFacility } = useFacility();
  const { currentUser } = useAuth();
  const [showPopup, setShowPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const permissions = currentUser.access;

  const [page, setPage] = useState("1");
  const [pageSize, setPageSize] = useState("10");
  const { data: userData } = useFetchData(
    selectedFacility ? `/api/user/list/${selectedFacility}` : null,
    { currentPage: page, limit: pageSize },
    [selectedFacility, refresh, page, pageSize],
    { users: [], totalPages: 1 }
  );

  const users = userData.users;
  const totalPages = userData.totalPages;

  const handleUser = () => {
    setShowPopup(true);
  };

  const updatePage = (newPage: number) => {
    setPage(newPage.toString());
  };

  const handlePageSizeChange = ({
    pageSize,
    page,
  }: {
    pageSize: string;
    page: number;
  }) => {
    setPageSize(pageSize);
    setPage(page.toString());
  };

  return (
    <>
      <PageHeader
        title={"Team"}
        subtitle={"All users in the current facility"}
        buttons={
          (permissions === "regional" ||
            permissions === "organization-admin") && (
            <Button
              icon="Add"
              children="Add User"
              onClick={handleUser}
              styleString="primary"
            />
          )
        }
      />
      <div className="mt-4 px-4 sm:px-6 sm:overflow-y-auto sm:pb-2 thin-scrollbar">
        <TeamList users={users} />
        <Pagination
          itemsToShow={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          currentPage={page}
          setCurrentPage={updatePage}
          totalPages={totalPages}
        />
      </div>

      {selectedFacility && (
        <NewUserForm
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          refresh={refresh}
          setRefresh={setRefresh}
          facilities={[]}
          type={"new"}
          user={null}
        />
      )}
    </>
  );
};

export default Team;
