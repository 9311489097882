import React from "react";
import { Link } from "react-router-dom";
import { ErrorOutline } from "@mui/icons-material";

const NotFound: React.FC = () => {
  return (
    <div className="h-full bg-secondary-1200  flex justify-center items-center text-center w-full">
      <div className="rounded-sm border bg-primary border-secondary-1000 w-fit m-auto px-6 py-2 max-w-sm">
        <ErrorOutline className="text-accent-500" fontSize="large" />
        <h1 className=" text-secondary py-4">
          Oops, we couldn't find the page you are looking for.
        </h1>

        <Link to="/home">
          <span className="text-sm text-accent-500 hover:cursor-pointer hover:text-accent-400">
            Return to Home
          </span>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
