import { useState, useEffect, FC } from "react";
import ToDoTable from "../../updatedcomponents/tables/ToDoTable";
import ToDoCalendar from "../../updatedcomponents/calendars/ToDoCalendar";
import {
  ArrowBack,
  CalendarTodayOutlined,
  FilterAltOutlined,
  FormatListBulleted,
} from "@mui/icons-material";
import TableFormRHF from "../../updatedcomponents/forms/TableFormRHF";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import ActionResponse from "../../updatedcomponents/modals/ActionResponse";
import useFetchData from "../../hooks/useFetchData";
import { useRecurring } from "../../hooks/useRecurring";
import { useFacility } from "../../hooks/useFacility";
import { RecurringTask } from "../../types/RecurringTask";
import FilterButton from "../../updatedcomponents/table-components/FilterButton";
import { classNames } from "../../utils/FormatFunctions";
import { TaskInstance } from "../../types/TaskInstance";

interface FilterOptions {
  title: string;
  status: string;
  taskType: string;
  frequency: string;
  dueDateFrom: string;
  dueDateTo: string;
  readyToComplete: string;
}

interface FilterOption {
  label: string;
  type: string;
  name: string;
  options?: { label: string; value: string }[];
}

interface Response {
  show: boolean;
  title: string;
  body: string;
  icon: React.ReactNode | null;
}

const Tasks: FC = () => {
  const { selectedFacility } = useFacility();
  const {
    recurringTasks,
    setRecurringTasks,
    totalPages,
    isLoading,
    searchParams,
    updateSearchParams,
    fetchRecurringTasks,
  } = useRecurring();

  const [filters, setFilters] = useState<string>("");
  const [view, setView] = useState<string>(searchParams.get("view") || "list");
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const [refresh, setRefresh] = useState<boolean>(false);
  const [instanceToEdit, setInstanceToEdit] = useState<TaskInstance | null>(
    null
  );
  const [showLog, setShowLog] = useState<boolean>(false);

  const currentPage = searchParams.get("page") || "1";
  const tasksPerPage = searchParams.get("pageSize") || "10";

  const filterOptions: FilterOption[] = [
    {
      label: "Title",
      type: "text",
      name: "title",
    },
    {
      label: "Start Date",
      type: "date",
      name: "dueDateFrom",
    },
    {
      label: "End Date",
      type: "date",
      name: "dueDateTo",
    },
    {
      label: "Status",
      type: "selector",
      options: [
        { label: "All", value: "" },
        { label: "Incomplete", value: "incomplete" },
        { label: "In Progress", value: "in progress" },
        { label: "Overdue", value: "overdue" },
        { label: "Complete", value: "complete" },
      ],
      name: "status",
    },
    {
      label: "Task Type",
      type: "selector",
      options: [
        { label: "All", value: "" },
        { label: "Preventative Maintenance", value: "prev-maint" },
        { label: "Life Safety", value: "life-safety" },
      ],
      name: "taskType",
    },
    {
      label: "Task Frequency",
      type: "selector",
      options: [
        { label: "All", value: "" },
        { label: "Daily", value: "daily" },
        { label: "Weekly", value: "weekly" },
        { label: "Bi-weekly", value: "bi-weekly" },
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Semi-annually", value: "semi-annually" },
        { label: "Annually", value: "annually" },
        { label: "2 Year", value: "biennially" },
        { label: "3 Year", value: "3-year" },
        { label: "4 Year", value: "4-year" },
        { label: "5 Year", value: "5-year" },
      ],
      name: "frequency",
    },
    {
      label: "Ready to Complete",
      type: "checkbox",
      name: "readyToComplete",
    },
  ];

  //Endpoint for fetching maintenace workers
  const { data: usersData, error: usersError } = useFetchData(
    selectedFacility ? `/api/user/list/${selectedFacility}` : null,
    {},
    [selectedFacility],
    { users: [] }
  );
  const users = usersData?.users || [];

  if (usersError) {
    console.error("Error fetching users:", usersError);
  }

  useEffect(() => {
    if (!selectedFacility) return;
    fetchRecurringTasks(selectedFacility);
  }, [searchParams, selectedFacility]);

  const handleResetFilters = () => {
    updateSearchParams({
      filters: JSON.stringify(""),
      page: "1",
      view,
      readyToComplete: "true",
    });
    setFilters("");
    setShowFilter(false);
  };

  const applyFilters = (filters: Record<string, string>) => {
    updateSearchParams({
      filters: JSON.stringify(filters),
      page: "1",
    });
  };
  const handlePageSizeChange = ({
    pageSize,
    page,
  }: {
    pageSize: string;
    page: number;
  }) => {
    updateSearchParams({ pageSize, page: page.toString() });
  };

  const handlePageChange = (newPage: number) => {
    updateSearchParams({ page: newPage.toString() });
  };

  const handleLogInstanceView = (logInstance: TaskInstance) => {
    setInstanceToEdit(logInstance);
    setShowLog(true);
  };

  /*   const viewButtons = () => {
    return (
      <div className="isolate inline-flex rounded-sm shadow-sm my-2 bg-secondary-1100 text-secondary-100 text-xs font-medium cursor-pointer">
        <button
          type="button"
          className={classNames(
            view === "list" ? "bg-primary my-0.5 mx-0.5 shadow" : "",
            "relative inline-flex items-center rounded-l-sm px-2 py-2"
          )}
          onClick={() => {
            setView("list");
          }}
        >
          <FormatListBulleted style={{ fontSize: "1rem" }} aria-hidden="true" />
          <label className="mx-2 cursor-pointer">List View</label>
        </button>
        <button
          type="button"
          className={classNames(
            view === "calendar" ? "bg-primary my-0.5 mx-0.5 shadow" : "",
            "relative inline-flex items-center rounded-r-sm px-2 py-2"
          )}
          onClick={() => {
            setView("calendar");
          }}
        >
          <CalendarTodayOutlined
            style={{ fontSize: "1rem" }}
            aria-hidden="true"
          />
          <label className="mx-2 cursor-pointer">Calendar View</label>
        </button>
      </div>
    );
  };
 */
  const [closeoutButton, setCloseoutButton] = useState<React.ReactNode | null>(
    null
  );

  const [response, setResponse] = useState<Response>({
    show: false,
    title: "",
    body: "",
    icon: null,
  });

  return (
    <>
      <ActionResponse response={response} setResponse={setResponse} />
      {!showLog ? (
        <>
          <PageHeader
            title={"Recurring Tasks"}
            subtitle={"All tasks waiting to be closed out"}
          />
          {view === "list" ? (
            <div className=" px-4 sm:px-6 sm:pb-2 ">
              <FilterButton setShowFilterModal={setShowFilter} />

              <FilterModal
                filters={filters}
                filterOptions={filterOptions}
                show={showFilter}
                setShow={setShowFilter}
                applyFilters={applyFilters}
                resetFilters={handleResetFilters}
              />
              {/* {viewButtons()} */}

              <ToDoTable
                data={recurringTasks}
                setRecurringTasks={setRecurringTasks}
                users={users}
                setShowForm={setShowLog}
                setInstanceToEdit={setInstanceToEdit}
                handleLogInstanceView={handleLogInstanceView}
              />
              <Pagination
                setCurrentPage={handlePageChange}
                totalPages={totalPages}
                currentPage={currentPage}
                itemsToShow={tasksPerPage}
                handlePageSizeChange={handlePageSizeChange}
              />
            </div>
          ) : (
            <></>
            /* <ToDoCalendar
            data={recurringTasks}
            dateParams={dateParams}
            setDateParams={setDateParams}
            /> */
          )}
        </>
      ) : (
        <>
          <PageHeader
            title={
              <button
                type="button"
                className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
                onClick={() => setShowLog(false)}
              >
                <ArrowBack style={{ fontSize: "1rem" }} />
                {instanceToEdit ? instanceToEdit.title : "Back to Tasks"}
              </button>
            }
            buttons={closeoutButton}
          />

          <TableFormRHF
            logData={instanceToEdit}
            setLogData={setInstanceToEdit}
            setShowForm={setShowLog}
            refresh={refresh}
            setRefresh={setRefresh}
            setResponse={setResponse}
            setCloseOutButton={setCloseoutButton}
          />
        </>
      )}
    </>
  );
};

export default Tasks;
