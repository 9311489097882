import React, { ReactNode } from "react";

interface ContainerProps {
  style?: string;
  children: ReactNode;
}

const Container: React.FC<ContainerProps> = ({ style, children }) => {
  return (
    <div className="p-4 border border-secondary-1000 rounded-sm bg-primary">
      {children}
    </div>
  );
};

export default Container;
