import React, { useState, useEffect, useContext, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Close } from "@mui/icons-material";
import { decodedString } from "../../utils/FormatFunctions";
import { TaskTemplate } from "../../types/TaskTemplate";

interface CreateTemplateProps {
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  existingLog: any;
  setExistingLog: React.Dispatch<any>;
}

interface DataFields {
  taskType: string;
  title: string;
  frequency: string;
  category: string;
  source: string;
  details: string;
  inputs: {
    name: string;
    dataType: string;
    validator: { lowerLimit: number; upperLimit: number };
  }[];
  hasMultipleInputs: boolean;
  hasCycle: boolean;
  cycleTime: number | null;
  cycleUnit: string | null;
}

const CreateTemplate: React.FC<CreateTemplateProps> = ({
  showPopup,
  setShowPopup,
  existingLog,
  setExistingLog,
}) => {
  const [data, setData] = useState<DataFields>({
    taskType: "",
    title: "",
    frequency: "",
    category: "",
    source: "",
    details: "",
    inputs: [],
    hasMultipleInputs: false,
    hasCycle: false,
    cycleTime: null,
    cycleUnit: null,
  });

  useEffect(() => {
    setData({
      taskType: existingLog?.taskType || "",
      title: existingLog?.title || "",
      frequency: existingLog?.recurrence.frequency || "",
      category: existingLog?.category || "",
      source: existingLog?.source || "",
      details: existingLog?.details || "",
      inputs: existingLog?.inputs || [],
      hasMultipleInputs: existingLog?.hasMultipleInputs || false,
      hasCycle: existingLog?.hasCycle || false,
      cycleTime: existingLog?.cycleTime || null,
      cycleUnit: existingLog?.cycleUnit || null,
    });
  }, [existingLog]);

  //Custom inputs
  const [inputItem, setInputItem] = useState("");
  const [selectedDataType, setSelectedDataType] = useState("text");
  const [validationCriteria, setValidationCriteria] = useState({
    lowerLimit: "",
    upperLimit: "",
  });

  const handleLowerLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidationCriteria({
      ...validationCriteria,
      lowerLimit: e.target.value,
    });
  };

  const handleUpperLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidationCriteria({ ...validationCriteria, upperLimit: e.target.value });
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { id, type, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    const checked = (e.target as HTMLInputElement).checked;

    setData({
      ...data,
      [id]: type === "checkbox" ? checked : value,
    });
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, field:string) => {
    setData((prevData) => ({
      ...prevData,
      [field]: event.target.value,
    }));
  };
  const handleAddItem = () => {
      if (inputItem.trim() !== "") {
        const encodedInputName = encodeURIComponent(inputItem);
  
        const newItem = {
          name: encodedInputName,
          dataType: selectedDataType,
          validator: {
            lowerLimit: Number(validationCriteria.lowerLimit),
            upperLimit: Number(validationCriteria.upperLimit),
          },
        };
  
        setData((prevData) => ({
          ...prevData,
          inputs: [...prevData.inputs, newItem],
        }));
  
        setInputItem("");
        setValidationCriteria({ upperLimit: "", lowerLimit: "" });
      }
    };

  const handleRemoveItem = (index:number) => {
    const updatedList = data.inputs.filter((_, i) => i !== index);
    setData((prevData) => ({
      ...prevData,
      inputs: updatedList,
    }));
  };

  const handleAdd = async (e: React.FormEvent<SubmitEvent>) => {
    e.preventDefault();

    try {
      if (!existingLog) {
        const response = await axios.post(`/api/admin/template/create`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        console.log("Successfully created template: ", response.data);
        // TODO: setData by spreading in new item
        setShowPopup(false);
      } else {
        const response = await axios.post(
          `/api/admin/template/update/${existingLog._id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log("Task updated successfully:", response.data);
        setExistingLog(response.data);
        setShowPopup(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let content;
  const cycleFields = (
    <div>
      <span>Every: </span>
      <div>
        <input
          className="block w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
          type="number"
          id="cycleTime"
          value={data.cycleTime ?? ""}
          onChange={handleInput}
        />
      </div>
      <select
        className="block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
        id="cycleUnit"
        value={data.cycleUnit ??""}
        onChange={handleInput}
      >
        <option value={"week"}>Week</option>
        <option value={"month"}>Month</option>
        <option value={"quarter"}>Quarter</option>
        <option value={"annual"}>Year</option>
      </select>
    </div>
  );

  {
    content = (
      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-100 sm:py-0">
        {/* Task Type */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="project-name"
              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
            >
              Task Type
            </label>
          </div>
          <div className="sm:col-span-2">
            <select
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
              id="taskType"
              value={data.taskType}
              onChange={handleInput}
              required={true}
            >
              <option value={""} hidden>
                Please select a value
              </option>
              <option value={"life-safety"}>Life Safety</option>
              <option value={"prev-maint"}>Preventative Maintenance</option>
            </select>
          </div>
        </div>
        {/* Title */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="project-name"
              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
            >
              Task Title
            </label>
          </div>
          <div className="sm:col-span-2">
            <input
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
              id={"title"}
              type={"text"}
              placeholder={"Task Title"}
              value={data.title}
              onChange={handleInput}
              required={true}
            />
          </div>
        </div>
     

        {/* Frequency */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="project-name"
              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
            >
              Frequency
            </label>
          </div>
          <div className="sm:col-span-2">
            <select
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
              id="frequency"
              value={data.frequency}
              onChange={handleInput}
              required={true}
            >
              <option value={""} hidden>
                Please select a value
              </option>
              <option value={"daily"}>Daily</option>
              <option value={"weekly"}>Weekly</option>
              <option value={"bi-weekly"}>Biweekly</option>
              <option value={"monthly"}>Monthly</option>
              <option value={"quarterly"}>Quarterly</option>
              <option value={"semi-annually"}>Semi-Annually</option>
              <option value={"annually"}>Annually</option>
              <option value={"biennially"}>2-Year</option>
              <option value={"3-year"}>3-Year</option>
              <option value={"4-year"}>4-Year</option>
              <option value={"5-year"}>5-Year</option>
            </select>
          </div>
        </div>
        {/* Frequency */}
        {data.taskType == "life-safety" && (
          <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <div>
              <label
                htmlFor="category"
                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
              >
                Regulation Category
              </label>
            </div>
            <div className="sm:col-span-2">
              <select
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                id="category"
                value={data.category}
                onChange={handleInput}
                required={true}
              >
                <option value={""} hidden>
                  Please select a value
                </option>
                <option value={"fire safety"}>Fire Safety</option>
                <option value={"electrical safety"}>Electrical Safety</option>
                <option value={"operational safety"}>Operational Safety</option>
                <option value={"gas safety"}>Gas/Air Safety</option>
              </select>
            </div>
          </div>
        )}

        {/* Source */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="source"
              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
            >
              Data Source
            </label>
          </div>
          <div className="sm:col-span-2">
            <select
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
              id="source"
              value={data.source}
              onChange={handleInput}
              required={true}
            >
              <option value={""} hidden>
                Please select a value
              </option>
              <option value={"internal"}>Internal</option>
              <option value={"external"}>External</option>
            </select>
          </div>
        </div>

        {/* task description */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="project-description"
              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
            >
              Description
            </label>
          </div>
          <div className="sm:col-span-2">
            <textarea
              id="details"
              name="details"
              value={data.details}
              rows={3}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
              placeholder="Enter task details here"
              onChange={handleInput}
              required
            />
          </div>
        </div>
        {/* Inputs */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="source"
              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
            >
              Log Input Fields
            </label>
          </div>
          <div className="sm:col-span-2">
            <div className="flex flex-wrap ">
              {data.inputs.map((item, index) => (
                <div className="flex m-1" key={index}>
                  {item.dataType === "number" ? (
                    <span
                      className={`inline-flex items-center gap-x-0.5 rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset ring-gray-500/10`}
                    >
                      {item.name} ( {item.validator.lowerLimit} -{" "}
                      {item.validator.upperLimit})
                      <button
                        type="button"
                        className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
                        onClick={() => handleRemoveItem(index)}
                      >
                        <span className="sr-only">Remove</span>
                        <svg
                          viewBox="0 0 14 14"
                          className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75"
                        >
                          <path d="M4 4l6 6m0-6l-6 6" />
                        </svg>
                        <span className="absolute -inset-1" />
                      </button>
                    </span>
                  ) : (
                    <span
                      className={` inline-flex items-center gap-x-0.5 rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset ring-gray-500/10`}
                    >
                      {decodedString(item.name)} ({item.dataType})
                      <button
                        type="button"
                        className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
                        onClick={() => handleRemoveItem(index)}
                      >
                        <span className="sr-only">Remove</span>
                        <svg
                          viewBox="0 0 14 14"
                          className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75"
                        >
                          <path d="M4 4l6 6m0-6l-6 6" />
                        </svg>
                        <span className="absolute -inset-1" />
                      </button>
                    </span>
                  )}
                </div>
              ))}
            </div>
            <div className="flex-col">
              <div className="flex">
                <input
                  className="block w-full rounded-l-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-sm  focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                  type="text"
                  placeholder="Log field"
                  value={inputItem}
                  onChange={(e) => setInputItem(e.target.value)}
                />
                <select
                  className={`flex w-fit max-w-sm ${
                    selectedDataType !== "number" && "rounded-r-md"
                  } border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6`}
                  id="source"
                  value={selectedDataType}
                  onChange={(e) => setSelectedDataType(e.target.value)}
                >
                  <option value={""} hidden>
                    Please select a value
                  </option>

                  <option value="text">Text</option>
                  <option value="number">Number</option>
                  <option value="radio">Pass/Fail</option>
                  <option value="radio-na">Pass/Fail/NA</option>
                  <option value="file">File</option>
                </select>
                {selectedDataType === "number" ? (
                  <div className="flex">
                    <input
                      className="flex w-14  border-0 py-1 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                      type="number"
                      placeholder="Lower Limit"
                      onChange={handleLowerLimitChange}
                      value={validationCriteria.lowerLimit}
                    />
                    <input
                      className="flex w-14 rounded-r-lg border-0 py-1.5 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                      type="number"
                      placeholder="Upper Limit"
                      onChange={handleUpperLimitChange}
                      value={validationCriteria.upperLimit}
                    />
                  </div>
                ) : null}
                <button
                  className="text-accent-100 text-2xl p-1 m-2"
                  type="button"
                  onClick={handleAddItem}
                >
                  +
                </button>
              </div>
              <span className="text-sm text-gray-400">
                *Logs are automatically generated with a date field
              </span>
            </div>
          </div>
        </div>
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="project-name"
              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
            >
              Contains Map
            </label>
          </div>
          <div className="sm:col-span-2">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  className="h-4 w-4 rounded border-gray-300 text-accent-100 focus:ring-accent-200"
                  id={"hasMultipleInputs"}
                  type={"checkbox"}
                  value={String(data.hasMultipleInputs ?? false)}
                  onChange={handleInput}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Contains map
                </label>
                <p id="comments-description" className="text-gray-500">
                  Facilities will be expected to input the items in their
                  building.
                </p>
              </div>
            </div>
          </div>
        </div>

        {data.hasMultipleInputs && (
          <fieldset className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <legend className="sr-only">cycle inputs</legend>
            <div
              className="text-sm font-medium leading-6 text-gray-900"
              aria-hidden="true"
            >
              Cycle Inputs
            </div>
            <div className="space-y-5 sm:col-span-2">
              <div className="space-y-5 sm:mt-0">
                <div className="relative flex items-start">
                  <div className="absolute flex h-6 items-center">
                    <input
                      id="hasCycleTrue"
                      name="hasCycle"
                      type="radio"
                      value="true"
                      onChange={(e) => handleCheckChange(e, "hasCycle")}
                      className="h-4 w-4 border-gray-300 text-accent-100 focus:ring-accent-100"
                    />
                  </div>
                  <div className="pl-7 text-sm leading-6">
                    <label
                      htmlFor="public-access"
                      className="font-medium text-gray-900"
                    >
                      Cycle
                    </label>
                    <p id="public-access-description" className="text-gray-500">
                      Map items will be cycled through to complete within a
                      specified time frame
                    </p>
                    {data.hasCycle && cycleFields}
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="absolute flex h-6 items-center">
                    <input
                      id="hasCycleFalse"
                      name="hasCycle"
                      type="radio"
                      defaultChecked
                      value="false"
                      onChange={(e) => handleCheckChange(e, "hasCycle")}
                      className="h-4 w-4 border-gray-300 text-accent-100 focus:ring-accent-100"
                    />
                  </div>
                  <div className="pl-7 text-sm leading-6">
                    <label
                      htmlFor="restricted-access"
                      className="font-medium text-gray-900"
                    >
                      Do not cycle
                    </label>
                    <p
                      id="restricted-access-description"
                      className="text-gray-500"
                    >
                      Map items will not be cycled through
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        )}
      </div>
    );
  }

  return (
    <Transition.Root show={showPopup} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setShowPopup}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form
                    className="flex  h-full flex-col overflow-y-scroll bg-white shadow-xl"
                    onSubmit={(e)=>handleAdd}
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-secondary-100 px-4 py-6 sm:px-6 sticky top-0 z-50">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-200">
                              {existingLog ? "View" : "New"} Template
                            </Dialog.Title>
                            <p className="text-sm text-gray-400">
                              Information related to the selected template.
                            </p>
                          </div>

                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-primary hover:text-gray-100"
                              onClick={() => setShowPopup(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <Close className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {content}
                    </div>
                    {/* Action buttons */}
                    {
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <button
                            type="button"
                            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={() => setShowPopup(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md bg-accent-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-100"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    }
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateTemplate;
