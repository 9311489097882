import { useEffect, useState } from "react";
import axios from "axios";
import { formatDate } from "../utils/FormatFunctions";

interface FetchParams {
  [key: string]: any;
  startDate?: Date | null;
  endDate?: Date| null;
  filters?: object;
}

function useFetchData<T>(
  url: string | null,
  params: FetchParams = {},
  dependencies: any[] = [],
  defaultValue: T
) {
  const [data, setData] = useState<T>(defaultValue);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!url) return;
      try {
        const token = localStorage.getItem("token");
        
        const response = await axios.get<T>(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            ...params,
            startDate: params.startDate ? formatDate(params.startDate) : undefined,
            endDate: params.endDate ? formatDate(params.endDate) : undefined,
            filters: params.filter ? JSON.stringify(params.filters) : undefined,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
        setError(error);
      }
    };
    fetchData();
  }, dependencies);

  return { data, error };
}

export default useFetchData;
