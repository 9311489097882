import { useContext } from "react";
import { FacilityContext } from "../context/FacilityContext";

export const useFacility = () => {
  const context = useContext(FacilityContext);
  if (!context) {
    throw new Error("useFacility must be used within a facilityProvider");
  }
  return context;
};
