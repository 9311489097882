import React from "react";
import { PhotoCameraBackOutlined } from "@mui/icons-material";
import { WorkOrder } from "../../types/WorkOrder";
import { retrieveFileName } from "../../utils/FormatFunctions";

interface AttachmentsProps {
  workOrder: WorkOrder;
  images: string[];
  setSelectedImage: React.Dispatch<React.SetStateAction<number>>;
  setShowLightbox: React.Dispatch<React.SetStateAction<boolean>>;
}

const Attachments: React.FC<AttachmentsProps> = ({
  workOrder,
  images,
  setSelectedImage,
  setShowLightbox,
}) => {
  return (
    <div className="px-4 py-4 sm:gap-4 sm:px-0 flex justify-center w-full">
      {images.length > 0 ? (
        <ul className="list-none space-y-4 w-full">
          {images.map((image, index) => (
            <li
              key={index}
              onClick={() => {
                setSelectedImage(index);
                setShowLightbox(true);
              }}
            >
              <div className="flex w-full justify-between mx-auto">
                <div className="flex gap-2 items-center">
                  <img
                    src={image}
                    alt="work order image"
                    className="w-12 h-12 object-contain rounded-md border border-secondary-1100"
                  />
                  <p className="text-xs truncate max-w-32 text-secondary-100 font-semibold">
                    {retrieveFileName(workOrder.attachments[index])}
                  </p>
                </div>
                <button
                  className="text-secondary-100 text-sm underline"
                  onClick={() => {
                    setSelectedImage(index);
                    setShowLightbox(true);
                  }}
                >
                  View
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="bg-gray-50 rounded-md w-full max-w-md px-1.5 py-2 flex flex-col text-gray-500  justify-center items-center border border-gray-200 border-dashed">
          <PhotoCameraBackOutlined />
          <span className="text-xs  text-center">No images</span>
        </div>
      )}
    </div>
  );
};

export default Attachments;
