import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Check } from "@mui/icons-material";

interface WorkOrderModalProps {
  handleReset: () => void;
  showPopUp: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkOrderModal: React.FC<WorkOrderModalProps> = ({
  handleReset,
  showPopUp,
  setShowPopup,
}) => {
  return (
    <Transition.Root show={showPopUp} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setShowPopup}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-sm bg-primary px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-accent-900">
                    <Check
                      className="h-6 w-6 text-accent-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-secondary-100"
                    >
                      Creation successful
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-secondary-500">
                        Your work order has been succesfully created.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-secondary-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-300"
                    onClick={() => handleReset()}
                  >
                    Go back to form
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default WorkOrderModal;
