import { useContext } from "react";
import { WorkOrderContext } from "../context/WorkOrderContext";

export const useWorkOrder = () => {
  const context = useContext(WorkOrderContext);
  if (!context) {
    throw new Error("useWorkOrder must be used within a WorkOrderProvider");
  }
  return context;
};
