import React, { Fragment, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  AreaChart,
  CartesianGrid,
} from "recharts";
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  CalendarTodayOutlined,
  Check,
  CheckOutlined,
  Close,
  DatasetOutlined,
  DoneAllOutlined,
  ExpandLess,
  ExpandMore,
  LaunchOutlined,
  PendingActionsOutlined,
  Refresh,
  TimerOutlined,
} from "@mui/icons-material";
import axios from "axios";
import { FacilityContext } from "../../context/FacilityContext";
import moment from "moment-timezone";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import DateFilter from "../../updatedcomponents/general-ui/DateFilter";
import { classNames } from "../../utils/FormatFunctions";
import Button from "../../updatedcomponents/general/Button";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const Analytics = () => {
  const { selectedFacility } = useContext(FacilityContext);
  const navigate = useNavigate();

  interface dateParams {
    displayText: string;
    startDate: Date | null;
    endDate: Date | null;
    selectedRange: string;
  }
  const [dateParams, setDateParams] = useState<dateParams>({
    displayText: moment().format("MMMM YYYY"),
    startDate: moment().subtract(3, "month").toDate(),
    endDate: new Date(),
    selectedRange: "3month",
  });

  const [workOrderStats, setWorkOrderStats] = useState([
    {
      name: "Created",
      icon: <Add className="rounded-sm text-secondary-100 bg-secondary-1000" />,
      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
    },
    {
      name: "Completed",
      icon: (
        <CheckOutlined className="rounded-sm text-secondary-100 bg-secondary-1000" />
      ),
      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
    },
    {
      name: "Closed",
      stat: 0,
      icon: (
        <DoneAllOutlined className="rounded-sm text-secondary-100 bg-secondary-1000" />
      ),
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
    },
    {
      name: "Avg. Time to Complete",
      icon: (
        <TimerOutlined className="rounded-sm text-secondary-100 bg-secondary-1000" />
      ),
      stat: "0 days",
      previousStat: "0 days",
      change: "0.00%",
      changeType: "increase",
    },
  ]);

  const [recurringStats, setRecurringStats] = useState([
    {
      name: "Completed",
      icon: (
        <CheckOutlined className="rounded-sm text-secondary-100 bg-secondary-1000" />
      ),
      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
    },
    {
      name: "Closed",
      icon: (
        <DoneAllOutlined className="rounded-sm text-secondary-100 bg-secondary-1000" />
      ),
      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
    },
    {
      name: "Overdue Tasks",
      icon: (
        <TimerOutlined className="rounded-sm text-secondary-100 bg-secondary-1000" />
      ),
      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
    },
  ]);

  //Populates data for data bars
  useEffect(() => {
    const fetchData = async () => {
      try {
        const startDate = moment(dateParams.startDate).format("YYYY-MM-DD");
        const endDate = moment(dateParams.endDate).format("YYYY-MM-DD");

        if (startDate === "Invalid date" || endDate === "Invalid date") return;
        const dateQuery = dateParams.selectedRange;

        const token = localStorage.getItem("token");
        const response = await axios.get(
          `/api/analytics/table/${selectedFacility}`,
          {
            params: { startDate, endDate, dateQuery },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { workOrders, recurringTasks } = response.data;

        const {
          createdWorkOrders,
          completeWorkOrders,
          closedWorkOrders,
          timeWorkOrders,
        } = workOrders;

        const { completeRecurring, closedRecurring, overdueRecurring } =
          recurringTasks;

        setWorkOrderStats((prevStats) => [
          {
            ...prevStats[0],
            stat: createdWorkOrders.current,
            previousStat: createdWorkOrders.previous,
            change: createdWorkOrders.change,
            changeType: determineChangeType(createdWorkOrders.change),
          },
          {
            ...prevStats[1],
            stat: completeWorkOrders.current,
            previousStat: completeWorkOrders.previous,
            change: completeWorkOrders.change,
            changeType: determineChangeType(completeWorkOrders.change),
          },
          {
            ...prevStats[2],
            stat: closedWorkOrders.current,
            previousStat: closedWorkOrders.previous,
            change: closedWorkOrders.change,
            changeType: determineChangeType(closedWorkOrders.change),
          },
          {
            ...prevStats[3],
            stat: `${timeWorkOrders.current} Days`,
            previousStat: `${timeWorkOrders.previous} Days`,
            change: timeWorkOrders.change,
            changeType: determineChangeType(timeWorkOrders.change),
          },
        ]);
        setRecurringStats((prevStats) => [
          {
            ...prevStats[0],
            stat: completeRecurring.current,
            previousStat: completeRecurring.previous,
            change: completeRecurring.change,
            changeType: determineChangeType(completeRecurring.change),
          },
          {
            ...prevStats[1],
            stat: closedRecurring.current,
            previousStat: closedRecurring.previous,
            change: closedRecurring.change,
            changeType: determineChangeType(closedRecurring.change),
          },
          {
            ...prevStats[2],
            stat: overdueRecurring.current,
            previousStat: overdueRecurring.previous,
            change: overdueRecurring.change,
            changeType: determineChangeType(overdueRecurring.change),
          },
        ]);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    // Helper function to determine the change type
    const determineChangeType = (change: string) => {
      const numericChange = parseFloat(change.replace("%", ""));
      return numericChange >= 0 ? "increase" : "decrease";
    };
    if (selectedFacility) {
      fetchData();
    }
  }, [dateParams, selectedFacility]);

  interface TimeFrameOption {
    value: number;
    unit: moment.unitOfTime.DurationConstructor;
    label: string;
    timeString: string;
    timeFrame: string;
  }

  const timeFrameOptions: TimeFrameOption[] = [
    {
      value: 0,
      unit: "days",
      label: "Today",
      timeString: " yesterday",
      timeFrame: "day",
    },
    {
      value: 1,
      unit: "days",
      label: "Yesterday",
      timeString: " two days ago",
      timeFrame: "2day",
    },
    {
      value: 3,
      unit: "days",
      label: "3 Day",
      timeString: " three days ago",
      timeFrame: "3day",
    },
    {
      value: 1,
      unit: "weeks",
      label: "Week",
      timeString: " last week",
      timeFrame: "week",
    },
    {
      value: 1,
      unit: "months",
      label: "1 Month",
      timeString: " a month ago",
      timeFrame: "month",
    },
    {
      value: 3,
      unit: "months",
      label: "3 Months",
      timeString: " three months ago",
      timeFrame: "3month",
    },
    {
      value: 6,
      unit: "months",
      label: "6 Months",
      timeString: " six months ago",
      timeFrame: "6month",
    },
    {
      value: 1,
      unit: "years",
      label: "1 Year",
      timeString: " a year ago",
      timeFrame: "year",
    },
    {
      value: 1,
      unit: "years",
      label: "All Time",
      timeString: " ",
      timeFrame: "all",
    },
  ];

  const timebar = (
    <div className="flex flex-col sm:flex-row justify-start bg-secondary-1100 w-full sm:w-fit p-1 rounded-sm">
      {timeFrameOptions.map((option, index) => (
        <button
          key={option.timeFrame}
          type="button"
          className={`relative inline-flex items-center rounded-sm sm:rounded-none ${
            index === 0 ? "sm:rounded-l-sm" : "-ml-px"
          } ${index === timeFrameOptions.length - 1 ? "sm:rounded-r-sm" : ""} 
      ${
        dateParams.selectedRange === option.timeFrame
          ? "bg-primary shadow  text-secondary-100 border-b border-accent-500"
          : "bg-inherit hover:bg-gray-50 text-gray-900"
      } px-3 py-0.5 text-sm   focus:z-10  transition-all ease-in-out duration-200`}
          onClick={() => {
            setDateParams((prevParams) => ({
              ...prevParams,
              displayText: moment().format("MMMM YYYY"),
              startDate: moment().subtract(option.value, option.unit).toDate(),
              selectedRange: option.timeFrame,
            }));
          }}
        >
          {option.label}
        </button>
      ))}
    </div>
  );

  interface button {
    text: string;
    link: string;
  }
  interface stat {
    name: string;
    icon: JSX.Element;
    stat: string | number;
    previousStat: string | number;
    change: string;
    changeType: string;
  }

  const StatsComponent = ({
    title,
    stats,
    button,
  }: {
    title: string;
    stats: stat[];
    button: button;
  }) => (
    <div className="border border-secondary-1000 pt-2 px-2 sm:px-8 ">
      <div className="flex w-full justify-between py-6 border-b border-secondary-1000">
        <div>
          <h3 className="text-sm">{title}</h3>
          <p className="text-xs text-secondary-500 pt-2">
            View stats related {button.text}
          </p>
        </div>
        <Button
          icon="LaunchOutlined"
          styleString="primary"
          reverse
          onClick={() => navigate(button.link)}
          overrideStyles="flex-none"
        >
          Go to {button.text}
        </Button>
      </div>
      <div className="mt-4 flex flex-col sm:flex-row gap-4 w-full justify-between">
        {stats.map((item) => (
          <div
            key={item.name}
            className="flex w-full gap-2 bg-secondary-1200 p-4"
          >
            <>{item.icon}</>
            <div className="flex flex-col">
              <h2 className="text-base text-secondary-100">{item.name}</h2>
              <span className="text-secondary-100 font-semibold text-xl">
                {item.stat}
              </span>

              {dateParams.selectedRange !== "custom" &&
                dateParams.selectedRange !== "all" && (
                  <>
                    {item.change !== "invalid" && (
                      <div
                        className={classNames(
                          item.changeType === "increase"
                            ? "text-accent-200"
                            : "text-reds-500",
                          "inline-flex items-baseline rounded-full py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                        )}
                      >
                        {item.changeType === "increase" ? (
                          <ArrowUpward
                            className="mr-0.5 flex-shrink-0 self-center text-green-500"
                            aria-hidden="true"
                            style={{ fontSize: "1rem" }}
                          />
                        ) : (
                          <ArrowDownward
                            className="mr-0.5 flex-shrink-0 self-center text-reds-500"
                            aria-hidden="true"
                            style={{ fontSize: "1rem" }}
                          />
                        )}

                        <span className="sr-only">
                          {" "}
                          {item.changeType === "increase"
                            ? "Increased"
                            : "Decreased"}{" "}
                          by{" "}
                        </span>
                        {item.change}
                      </div>
                    )}
                    <span className="text-sm text-secondary-100">
                      Compared to {item.previousStat}{" "}
                      {
                        timeFrameOptions.find(
                          (option) =>
                            option.timeFrame === dateParams.selectedRange
                        )?.timeString
                      }
                    </span>
                  </>
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const [showCompleted, setShowCompleted] = useState(true);
  const [showClosed, setShowClosed] = useState(true);
  const [showCreated, setShowCreated] = useState(true);

  const [showWorkOrders, setShowWorkOrders] = useState(true);
  const [showRecurring, setShowRecurring] = useState(true);

  interface WorkOrderCount {
    date: string;
    completed: number;
    closed: number;
    created: number;
  }
  const [workOrders, setWorkOrders] = useState<WorkOrderCount[]>([
    { date: new Date().toDateString(), completed: 0, closed: 0, created: 0 },
  ]);
  interface RecurringTaskCount {
    date: string;
    completed: number;
    closed: number;
  }
  const [recurringTasks, setRecurringTasks] = useState<RecurringTaskCount[]>([
    { date: new Date().toDateString(), completed: 0, closed: 0 },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const startDate = moment(dateParams.startDate).format("YYYY-MM-DD");
      const endDate = moment(dateParams.endDate).format("YYYY-MM-DD");

      if (startDate === "Invalid date" || endDate === "Invalid date") return;

      try {
        const response = await axios.get(
          `/api/analytics/chart/${selectedFacility}`,
          {
            params: { startDate, endDate },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const { workOrders, recurringTasks } = response.data;
        setWorkOrders(workOrders);
        setRecurringTasks(recurringTasks);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (selectedFacility) {
      fetchData();
    }
  }, [selectedFacility, dateParams]);

  // Function to aggregate data
  const aggregateData = () => {
    if (showWorkOrders && showRecurring) {
      // If both are true, combine and aggregate data
      const combined: {
        [key: string]: {
          date: string;
          created: number;
          completed: number;
          closed: number;
        };
      } = {};

      workOrders.forEach((order) => {
        console.log(order);
        const orderDateKey = order.date;
        combined[orderDateKey] = combined[orderDateKey] || {
          date: order.date,
          created: 0,
          completed: 0,
          closed: 0,
        };
        combined[orderDateKey].completed += order.completed;
        combined[orderDateKey].closed += order.closed;
        combined[orderDateKey].created += order.created;
      });
      recurringTasks.forEach((task) => {
        const taskDateKey = task.date;
        combined[taskDateKey] = combined[taskDateKey] || {
          date: task.date,
          created: 0,
          completed: 0,
          closed: 0,
        };
        combined[taskDateKey].completed += task.completed;
        combined[taskDateKey].closed += task.closed;
      });

      return Object.values(combined);
    } else if (showWorkOrders) {
      // If only showWorkOrders is true
      return workOrders;
    } else if (showRecurring) {
      // If only showRecurring is true
      return recurringTasks;
    } else {
      const allDates = [
        ...workOrders.map((order) => order.date),
        ...recurringTasks.map((task) => task.date),
      ];
      return allDates.map((date) => ({ date, completed: 0, closed: 0 }));
    }
  };

  const dataToDisplay = aggregateData();

  const widgetBars = (
    <div className="flex flex-col gap-y-6">
      <StatsComponent
        title="Work Order Data"
        stats={workOrderStats}
        button={{ text: "Work orders", link: "/work-orders" }}
      />
      <StatsComponent
        title="Recurring Task Data"
        stats={recurringStats}
        button={{ text: "Recurring tasks", link: "/tasks" }}
      />
    </div>
  );

  const Chart = ({ data }: { data: any }) => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={data}
          margin={{ top: 30, right: 50, left: 0, bottom: 50 }}
        >
          <XAxis dataKey="date" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />

          {showCompleted && (
            <Area
              key="completed"
              type="monotone"
              dataKey="completed"
              name="Completed Tasks"
              stroke="#111010"
              fillOpacity={0}
            />
          )}
          {showClosed && (
            <Area
              key="closed"
              type="monotone"
              dataKey="closed"
              name="Closed Tasks"
              stroke="#00B0A8"
              fillOpacity={0}
            />
          )}
          {showCreated && (
            <Area
              key="created"
              type="monotone"
              dataKey="created"
              name="Created Tasks"
              stroke="#004643"
              fillOpacity={0}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  const [currentFilter, setCurrentFilter] = useState("");

  return (
    <>
      <PageHeader
        title={"Analytics"}
        subtitle={""}
        buttons={
          <div className="hidden sm:flex w-full h-fit justify-evenly mx-4 gap-4">
            <DateFilter dateParams={dateParams} setDateParams={setDateParams} />

            <div className="flex flex-col">
              <Button
                styleString="secondary"
                icon="DatasetOutlined"
                children={
                  <div className="flex items-center gap-2">
                    <span>Dataset</span>
                    {
                      <ChevronDownIcon
                        className={`h-5 w-5 ui-open:transition  ${
                          currentFilter === "dataSets" ? "" : "rotate-180"
                        }`}
                        aria-hidden="true"
                      />
                    }
                  </div>
                }
                onClick={() => {
                  currentFilter !== "dataSets"
                    ? setCurrentFilter("dataSets")
                    : setCurrentFilter("");
                }}
              />
              {currentFilter === "dataSets" && (
                <div className="shadow-md gap-y-2 flex flex-col absolute w-52 z-30 bg-primary top-14 px-4 pb-4">
                  <div className="flex justify-between ">
                    <label className="text-sm">Work Orders</label>
                    <input
                      type="checkbox"
                      checked={showWorkOrders}
                      onChange={() => setShowWorkOrders(!showWorkOrders)}
                      className="text-accent-500 focus:ring-accent-500 rounded-sm cursor-pointer"
                    />
                  </div>
                  <div className="flex justify-between">
                    <label className="text-sm">Recurring Tasks</label>
                    <input
                      type="checkbox"
                      checked={showRecurring}
                      onChange={() => setShowRecurring(!showRecurring)}
                      className="text-accent-500 focus:ring-accent-500 rounded-sm cursor-pointer"
                    />
                  </div>
                  <button
                    onClick={() => {
                      setShowWorkOrders(true);
                      setShowRecurring(true);
                      setCurrentFilter("");
                    }}
                    className="mt-2 flex-1 flex items-center justify-center rounded-sm bg-secondary-100 px-2.5 py-0.5 h-fit text-center text-sm text-primary border-transparent border hover:bg-secondary-200"
                  >
                    Select All
                  </button>
                </div>
              )}
            </div>
            <div className="flex flex-col">
              <Button
                styleString="secondary"
                icon="PendingActionsOutlined"
                children={
                  <div className="flex items-center gap-2">
                    <span>Status</span>
                    {
                      <ChevronDownIcon
                        className={`h-5 w-5 ui-open:transition  ${
                          currentFilter === "status" ? "" : "rotate-180"
                        }`}
                        aria-hidden="true"
                      />
                    }
                  </div>
                }
                onClick={() => {
                  currentFilter !== "status"
                    ? setCurrentFilter("status")
                    : setCurrentFilter("");
                }}
              />

              {currentFilter === "status" && (
                <div className="shadow-md  absolute z-30 bg-primary w-52 top-14 px-4 pb-4">
                  <ul className="flex flex-col gap-2 pt-2">
                    <li className="flex justify-between w-full ">
                      <label className="mr-2 text-sm">Created</label>
                      <input
                        type="checkbox"
                        checked={showCreated}
                        onChange={() => setShowCreated(!showCreated)}
                        className="text-accent-500 focus:ring-accent-500 rounded-sm cursor-pointer"
                      />
                    </li>
                    <li className="flex justify-between w-full ">
                      <label className="mr-2 text-sm">Completed</label>
                      <input
                        type="checkbox"
                        checked={showCompleted}
                        onChange={() => setShowCompleted(!showCompleted)}
                        className="text-accent-500 focus:ring-accent-500 rounded-sm cursor-pointer"
                      />
                    </li>
                    <li className="flex justify-between w-full ">
                      <label className="mr-2 text-sm">Closed</label>
                      <input
                        type="checkbox"
                        checked={showClosed}
                        onChange={() => setShowClosed(!showClosed)}
                        className="text-accent-500 focus:ring-accent-500 rounded-sm cursor-pointer"
                      />
                    </li>
                  </ul>
                  <Button
                    onClick={() => {
                      setShowCreated(true);
                      setShowCompleted(true);
                      setShowClosed(true);
                      setCurrentFilter("");
                    }}
                    styleString="secondary"
                    overrideStyles="w-full"
                    children="Select All"
                  />
                </div>
              )}
            </div>
            <Button
              icon="Refresh"
              onClick={() => {
                setCurrentFilter("");
                setDateParams((prevParams) => ({
                  ...prevParams,
                  displayText: moment().format("MMMM YYYY"),
                  startDate: moment().subtract(1, "month").toDate(),
                  selectedRange: "month",
                }));
                setShowWorkOrders(true);
                setShowRecurring(true);
                setShowCreated(true);
                setShowCompleted(true);
                setShowClosed(true);
              }}
              styleString="primary"
            >
              Reset to Default
            </Button>
          </div>
        }
      />
      <div className="py-8 px-2 sm:px-8 flex flex-col gap-y-8">
        {timebar}
        {widgetBars}

        {/* Chart */}
        <div className="h-[350px] border border-secondary-1000">
          <div className="flex w-full py-2 px-8">
            <ul className="flex w-full justify-end gap-x-4">
              <li className="flex items-center gap-x-2">
                <div className="w-2 h-2 rounded-full bg-accent-300"></div>
                <div className="text-sm">Created</div>
              </li>
              <li className="flex items-center gap-x-2">
                <div className="w-2 h-2 rounded-full bg-secondary-100"></div>
                <div className="text-sm">Completed</div>
              </li>
              <li className="flex items-center gap-x-2">
                <div className="w-2 h-2 rounded-full bg-accent-500"></div>
                <div className="text-sm">Closed</div>
              </li>
            </ul>
          </div>
          <Chart data={dataToDisplay} />
        </div>
      </div>
    </>
  );
};

export default Analytics;
