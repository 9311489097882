import { User } from "../types/User";

export interface AuthState {
  currentUser: User;
  token?: string | null;
}

export interface AuthAction {
  type: "LOGIN" | "LOGOUT" | "UPDATE_USER";
  payload?: User;
}

export const defaultUser: User = {
  _id: "",
  firstname: "",
  lastname: "",
  email: "",
  avatar: "",
  access: "worker",
  phone: "",
  facility: [],
  joinDate: new Date(),
  smsNotify: false,
  emailNotify: false,
  urgentNotify: false,
  allNotify: false,
  emailNotifications: {
    taskAssignment: false,
    workOrderCreation: false,
    urgentWorkOrderCreation: false,
  },
  smsNotifications: {
    taskAssignment: false,
    workOrderCreation: false,
    urgentWorkOrderCreation: false,
  },
  pushNotifications: {
    taskAssignment: false,
    workOrderCreation: false,
    urgentWorkOrderCreation: false,
  },
  pushSubscriptions: [],
};

const AuthReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "LOGIN": {
      return {
        currentUser: action.payload || defaultUser,
      };
    }
    case "LOGOUT": {
      return {
        currentUser: defaultUser,
      };
    }
    case "UPDATE_USER": {
      return {
        currentUser: action.payload || defaultUser,
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;
